//
// SchemaInspector
//
// Actions: commit/revert/save_as_new

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../stores'

import { View, Spacer, Divider } from '../../appview'
import { Text, Header, Property, Icon, Menu, MenuItem } from '../../components'

const SchemaInspectorDebugInfo = observer(function SchemaInspectorDebugInfo({
    worksheet,
    item,
}) {
    const { app } = useStore()

    if (!item) return null

    return (
        <div className="DEBUG">
            <Header>{app.text('DEBUG ONLY info')}</Header>
            <Property label={app.text('gid')}>
                <Text>{item.gid}</Text>
            </Property>
            <Property label={app.text('original gid')}>
                <Text>{item.original}</Text>
            </Property>
            <Property label={app.text('layout gid')}>
                <Text>{item.layout}</Text>
            </Property>
        </div>
    )
})

export const SchemaInspector = observer(function SchemaInspector({ tree, worksheet }) {
    const { app, view, DEBUG } = useStore()

    const afterAction = result => {
        if (!worksheet.item || !tree) return
        const itemtype = worksheet.datatype
        tree.expandItem({ id: itemtype }).then(() => {
            const newitem = tree.findItemById(result[itemtype])
            tree.selectItem(newitem)
            view.schemaquery.refetch()
        })
    }
    const afterDeleteAction = result => {
        if (!worksheet.item || !tree) return
        const itemtype = worksheet.datatype
        tree.expandItem({ id: itemtype }).then(() => {
            const newitem = tree.findItemById(itemtype)
            tree.selectItem(newitem)
            view.schemaquery.refetch()
        })
    }

    let Inspector
    if (!worksheet.item) {
        Inspector = <Text>{app.text('Select a definition, class, or field.')}</Text>
    } else if (worksheet.item.is_new) {
        Inspector = (
            <>
                <Header className="cc-ok">
                    {app.text('New {itemtype}', { itemtype: worksheet.datatype })}
                </Header>
                <Text>
                    {app.text(
                        'This {itemtype} must be committed before it is available elsewhere.',
                        { itemtype: worksheet.datatype }
                    )}
                </Text>
                <Menu>
                    <MenuItem onClick={() => worksheet.item.commit().then(afterAction)}>
                        <Icon name="navigate" size={'text'} style={{ top: 0 }} />
                        {app.text('Commit')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => worksheet.item.delete().then(afterDeleteAction)}
                    >
                        <Icon name="navigate" size={'text'} style={{ top: 0 }} />
                        {app.text('Delete')}
                    </MenuItem>
                </Menu>
            </>
        )
    } else if (worksheet.item.is_working_copy) {
        Inspector = (
            <>
                <Header className="cc-info">
                    {app.text('Modified {itemtype}', { itemtype: worksheet.datatype })}
                </Header>
                <Text>
                    {app.text(
                        'This {itemtype} must be committed before the changes are available elsewhere.',
                        { itemtype: worksheet.datatype }
                    )}
                </Text>
                <Menu>
                    <MenuItem onClick={() => worksheet.item.commit().then(afterAction)}>
                        <Icon name="navigate" size={'text'} style={{ top: 0 }} />
                        {app.text('Commit')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            worksheet.item.revert().then(afterAction)
                        }}
                    >
                        <Icon name="navigate" size={'text'} style={{ top: 0 }} />
                        {app.text('Revert changes')}
                    </MenuItem>
                    <MenuItem
                        onClick={() => worksheet.item.saveAsNew().then(afterAction)}
                    >
                        <Icon name="navigate" size={'text'} style={{ top: 0 }} />
                        {app.text('Save as new')}
                    </MenuItem>
                </Menu>{' '}
            </>
        )
    }

    const DebugInfo = DEBUG ? (
        <>
            <Spacer size={5} />
            <Divider />
            <SchemaInspectorDebugInfo worksheet={worksheet} item={worksheet.item} />
        </>
    ) : undefined

    return (
        <View className="panel properties">
            {Inspector}
            {DebugInfo}
        </View>
    )
})
