import { isObservableMap, isObservableArray, isObservableObject, toJS } from 'mobx'
import { gid } from './gid'
import { hashString } from './text'

export const test_true = data => {
    return ['1', 'yes', 'y', 'on', 'true', 'checked', 'selected'].includes(
        data.toString().toLowerCase().trim()
    )
}

export const test_false = data => {
    return !test_true(data)
}

export const test_empty = data => {
    return (
        typeof data == 'undefined' ||
        data === null ||
        data === '' ||
        (Array.isArray(data) && data.length === 0)
    )
}

export const debounce = (func, delay) => {
    let timeoutId
    return function (...args) {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}

function _decycle(obj, stack = []) {
    if (!obj || typeof obj !== 'object') return obj

    if (stack.includes(obj)) return '[recursion]'

    let s = stack.concat([obj])

    return Array.isArray(obj) || isObservableArray(obj)
        ? obj.map(x => _decycle(x, s))
        : isObservableMap(obj)
        ? Object.fromEntries(
              Object.entries(toJS(obj))
                  .filter(([k, v]) => !k.startsWith('_'))
                  .map(([k, v]) => [k, _decycle(v, s)])
          )
        : Object.fromEntries(
              Object.entries(obj)
                  .filter(([k, v]) => !k.startsWith('_'))
                  .map(([k, v]) => [k, _decycle(v, s)])
          )
}

export const prettyprint = data => {
    return JSON.stringify(_decycle(data), null, 2)
}

export const prettydata = data => {
    return JSON.parse(JSON.stringify(_decycle(data), null, 2))
}

export const download_dataurl = (dataurl, filename) => {
    const link = document.createElement('a')
    link.href = dataurl
    link.download = filename
    link.click()
}

export const download_url = (url, filename) => {
    fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = filename
            link.click()
            URL.revokeObjectURL(url)
        })
    // .catch(console.error)
}

const _objectFromMapper = (key, value) => {
    if (value instanceof Map || isObservableMap(value)) {
        return Object.fromEntries(value)
    } else {
        return value
    }
}

// not for crypto, but useful for transforming an object into a unique(-ish) short string
export const hashData = data => {
    return hashString(JSON.stringify(data, _objectFromMapper))
}

export function isPlainObject(value) {
    if (typeof value !== 'object' || value === null) return false

    let proto = value
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto)
    }

    return Object.getPrototypeOf(value) === proto
}

export const objectFrom = data => {
    return _deepObjectFrom(data)
}

const _deepObjectFrom = data_ => {
    let data = data_
    if (data instanceof Map) {
        data = Object.fromEntries(data)
    } else if (isObservableMap(data)) {
        data = Object.fromEntries(toJS(data))
    }
    if (isPlainObject(data)) {
        return _deepObjectFromObject(data)
    }
    if (Array.isArray(data)) {
        return _deepObjectFromArray(data)
    }
    return data
}

const _deepObjectFromArray = data => {
    let deepArray = []
    data.forEach((_, index) => {
        deepArray.push(_deepObjectFrom(data[index]))
    })
    return deepArray
}

const _deepObjectFromObject = data => {
    let deepObject = {}
    for (const key in data) {
        deepObject[key] = _deepObjectFrom(data[key])
    }
    return deepObject
}

export const mapFrom = data => {
    return _deepMapFrom(data)
}

const _deepMapFrom = data_ => {
    let data = data_
    if (isPlainObject(data) || isObservableObject(data)) {
        data = new Map(Object.entries(data))
    }
    if (data instanceof Map) {
        return _deepMapFromMap(data)
    }
    if (Array.isArray(data)) {
        return _deepMapFromArray(data)
    }
    return data
}

const _deepMapFromArray = data => {
    let deepArray = []
    data.forEach((_, index) => {
        deepArray.push(_deepMapFrom(data[index]))
    })
    return deepArray
}

const _deepMapFromMap = data => {
    let deepMap = new Map()
    for (let [key, value] of data) {
        deepMap.set(key, _deepMapFrom(value))
    }
    return deepMap
}

export const flattenData = data => {
    return _deepFlatten(data)
}

const _deepFlatten = data => {
    if (data instanceof Map || isObservableMap(data)) {
        return _deepFlattenMap(data)
    }
    if (isPlainObject(data) || isObservableObject(data)) {
        return _deepFlattenObject(data)
    }
    if (Array.isArray(data) || isObservableArray(data)) {
        return _deepFlattenArray(data)
    }
    return data
}

const _deepFlattenMap = data => {
    let flat = new Map()
    for (let [key, value] of data) {
        flat.set(key, _deepFlatten(value))
    }
    return flat
}

const _deepFlattenObject = data => {
    let flat = {}
    for (const key in data) {
        flat[key] = _deepFlatten(data[key])
    }
    return flat
}

const _deepFlattenArray = data => {
    let flat = []
    data.forEach((_, index) => {
        flat.push(_deepFlatten(data[index]))
    })
    return flat
}

export const objectid = obj => {
    if (typeof obj.__uniqueid != 'undefined') {
        return obj.__uniqueid
    }
    Object.defineProperty(obj, '__uniqueid', {
        value: gid(),
        enumerable: false,
        writable: false,
    })
    return obj.__uniqueid
}
