//
// DefinitionData
//
// A plain DefinitionData object to hold properties

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'
import { test_empty } from '../../utils/utils'

export class DefinitionData {
    gid = null
    original = null
    name = ''
    label = new Map()
    classes = []
    fields = []
    titlefield = ''
    fieldlists = new Map()
    childdefinitions = []
    layout = ''
    is_base = ''
    is_new = false
    is_working_copy = false
    is_extended = false

    _modifications = new Set()

    constructor(definitiondata, rootstore) {
        makeObservable(this, {
            original: observable,
            name: observable,
            label: observable,
            classes: observable,
            fields: observable,
            titlefield: observable,
            fieldlists: observable,
            childdefinitions: observable,
            layout: observable,
            is_base: observable,
            renamed_from: computed,
            is_working_copy: observable,
            is_new: observable,
            is_extended: observable,
            is_committed: computed,
            has_modified: computed,
            update: action.bound,
            setName: action.bound,
            setLabel: action.bound,
            setLayout: action.bound,
            setTitlefield: action.bound,
            commitIfModified: action.bound,
            commit: action.bound,
            revert: action.bound,
            saveAsNew: action.bound,
            delete: action.bound,
            addClass: action.bound,
            moveClass: action.bound,
            removeClass: action.bound,
            addField: action.bound,
            moveField: action.bound,
            removeField: action.bound,
            addChilddefinition: action.bound,
            moveChilddefinition: action.bound,
            removeChilddefinition: action.bound,
            createLayoutComponent: action.bound,
            modifyLayoutComponent: action.bound,
            moveLayoutComponent: action.bound,
            deleteLayoutComponent: action.bound,
        })

        this._rootstore = rootstore
        this.update(definitiondata)
    }

    update = definitiondata => {
        updateInstanceWithData(this, definitiondata, [
            'gid',
            'original',
            'name',
            'label',
            'classes',
            'fields',
            'titlefield',
            'fieldlists',
            'layout',
            'childdefinitions',
            'is_base',
            'is_new',
            'is_working_copy',
            'is_extended',
        ])
    }

    get renamed_from() {
        if (!this.is_working_copy) return null
        if (this.is_new) return null
        const original = this._rootstore.data.definitions.get(this.original)
        if (!original || original.name === this.name) return null
        return original.name
    }

    get is_committed() {
        return !this.is_working_copy && !this.is_extended
    }

    get has_modified() {
        for (const fieldgid of this.fields) {
            const field = this._rootstore.data.fields.get(fieldgid)
            if (field && field.is_working_copy) {
                return true
            }
        }
        for (const classgid of this.classes) {
            const class_ = this._rootstore.data.classes.get(classgid)
            if (class_ && (class_.is_working_copy || class_.has_modified)) {
                return true
            }
        }
        return false
    }

    setName = value => {
        const previous_value = this.name
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        this.name = value
        this._modifications.add('name')
        return true
    }

    setLabel = (value, language) => {
        const previous_value = this.label.get(language)
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        let definitiondata = { label: Object.fromEntries(this.label) }
        definitiondata['label'][language] = value
        this.update(definitiondata)
        this._modifications.add('label')
        return true
    }

    setTitlefield = value => {
        const previous_value = this.titlefield
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        this.titlefield = value
        this._modifications.add('titlefield')
        return true
    }

    setLayout = gid => {
        const previous_gid = this.layout
        if (previous_gid === gid) return false
        if (test_empty(previous_gid) && test_empty(gid)) return false

        this.layout = gid
        this._modifications.add('layout')
        return true
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (!this._modifications.size) {
            return Promise.resolve({ definition: this.gid })
        }

        let updateparams = { definition: this.gid }
        for (const fieldname of this._modifications.keys()) {
            updateparams[fieldname] = this[fieldname]
        }
        // basically fire & forget updates
        return this._rootstore
            ._fetch('/definitions/modify', updateparams)
            .then(result => {
                this._modifications = new Set()
                return result
            })
    }

    commit = () => {
        const is_new = this.is_new
        return this._rootstore
            ._fetch('/definitions/commit', { definition: this.gid })
            .then(result => {
                if (!is_new) {
                    // delete the 'modified' version
                    this._rootstore.data.definitions.delete(this.gid)
                    this._rootstore.data.layouts.delete(this.layout)
                }
                return result
            })
    }

    revert = () => {
        return this._rootstore
            ._fetch('/definitions/revert', { definition: this.gid })
            .then(result => {
                // delete the 'modified' version
                this._rootstore.data.definitions.delete(this.gid)
                this._rootstore.data.layouts.delete(this.layout)
                return result
            })
    }

    saveAsNew = () => {
        return this._rootstore._fetch('/definitions/save_as_new', {
            definition: this.gid,
        })
    }

    copy = () => {
        return this._rootstore._fetch('/definitions/copy', {
            definition: this.gid,
        })
    }

    delete = () => {
        return this._rootstore
            ._fetch('/definitions/delete', { definition: this.gid })
            .then(result => {
                this._rootstore.data.definitions.delete(this.gid)
                this._rootstore.data.layouts.delete(this.layout)
                return result
            })
            .catch(error => {
                // in use - report to user?
                return false
            })
    }

    addClass = (class_gid, before_class_gid) => {
        let data = { definition: this.gid, class: class_gid }
        if (before_class_gid) {
            data['before_class'] = before_class_gid
        }
        return this._rootstore._fetch('/definitions/classes/add', data)
    }

    moveClass = (class_gid, before_class_gid) => {
        let data = { definition: this.gid, class: class_gid }
        if (before_class_gid) {
            data['before_class'] = before_class_gid
        }
        return this._rootstore._fetch('/definitions/classes/move', data)
    }

    removeClass = class_gid => {
        let data = { definition: this.gid, class: class_gid }
        return this._rootstore._fetch('/definitions/classes/remove', data)
    }

    addField = (field_gid, before_field_gid) => {
        let data = { definition: this.gid, field: field_gid }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch('/definitions/fields/add', data)
    }

    moveField = (field_gid, before_field_gid) => {
        let data = { definition: this.gid, field: field_gid }
        if (before_field_gid) {
            data['before_field'] = before_field_gid
        }
        return this._rootstore._fetch('/definitions/fields/move', data)
    }

    removeField = field_gid => {
        let data = { definition: this.gid, field: field_gid }
        return this._rootstore._fetch('/definitions/fields/remove', data)
    }

    addChilddefinition = (childdefinition_gid, before_childdefinition_gid) => {
        let data = { definition: this.gid, childdefinition: childdefinition_gid }
        if (before_childdefinition_gid) {
            data['before_childdefinition'] = before_childdefinition_gid
        }
        return this._rootstore._fetch('/definitions/childdefinitions/add', data)
    }

    moveChilddefinition = (childdefinition_gid, before_childdefinition_gid) => {
        let data = { definition: this.gid, childdefinition: childdefinition_gid }
        if (before_childdefinition_gid) {
            data['before_childdefinition'] = before_childdefinition_gid
        }
        return this._rootstore._fetch('/definitions/childdefinitions/move', data)
    }

    removeChilddefinition = childdefinition_gid => {
        let data = { definition: this.gid, childdefinition: childdefinition_gid }
        return this._rootstore._fetch('/definitions/childdefinitions/remove', data)
    }

    createLayoutComponent = (type, parent_gid, before_component_gid, name, options) => {
        let data = { definition: this.gid, parent: parent_gid, type: type }
        if (name) {
            data['name'] = name
        }
        if (options) {
            data['options'] = options
        }
        if (before_component_gid) {
            data['before_component'] = before_component_gid
        }
        return this._rootstore._fetch('/definitions/layouts/components/create', data)
    }

    modifyLayoutComponent = (component_gid, updated_component) => {
        let data = { definition: this.gid, component: component_gid }
        if (updated_component.type) {
            data['type'] = updated_component.type
        }
        if (updated_component.name) {
            data['name'] = updated_component.name
        }
        let options = {}
        for (let property_name in updated_component) {
            if (
                [
                    'type',
                    'name',
                    'gid',
                    'isContainer',
                    'isComponentContainer',
                    'parent',
                    'parentgid',
                ].includes(property_name)
            ) {
                continue
            }
            options[property_name] = updated_component[property_name]
        }
        if (options) {
            data['options'] = options
        }
        return this._rootstore._fetch('/definitions/layouts/components/modify', data)
    }

    moveLayoutComponent = (component_gid, parent_gid, before_component_gid) => {
        let data = {
            definition: this.gid,
            component: component_gid,
            parent: parent_gid,
        }
        if (before_component_gid) {
            data['before_component'] = before_component_gid
        }
        return this._rootstore._fetch('/definitions/layouts/components/move', data)
    }

    deleteLayoutComponent = component_gid => {
        let data = { definition: this.gid, component: component_gid }
        return this._rootstore._fetch('/definitions/layouts/components/delete', data)
    }
}
