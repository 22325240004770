//
// DefinitionLayoutBuilder
//
// Based on view info from a layout linked to a definition

import React from 'react'
import { observer } from 'mobx-react-lite'

import { RecursionMessage } from '.'
import { LayoutBuilder } from '..'

export const DefinitionLayoutBuilder = observer(function DefinitionLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const definition = worksheet.definitions.get(component['definition'])
    if (!definition) return null
    const definitionlayout = worksheet.layouts.get(definition.layout)
    if (!definitionlayout) return null

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component['gid'] === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (
        definitionlayout &&
        (visited_gids.includes(definitionlayout.gid) ||
            visited_gids.includes(definitionlayout.original))
    ) {
        return <RecursionMessage component={definitionlayout} />
    }

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        definitionlayout ? definitionlayout.gid : undefined,
        definitionlayout ? definitionlayout.original : undefined,
        definition ? definition.gid : undefined,
        definition ? definition.original : undefined,
    ]

    const DefinitionLayout = definitionlayout ? (
        <LayoutBuilder
            component={definitionlayout.components.get(definitionlayout.root)}
            components={definitionlayout.components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={active_class}
            visited_gids={[
                ...visited_gids,
                ...update_visited_gids.filter(gid => !!gid),
            ]}
            force_readonly={force_readonly}
        />
    ) : undefined

    return <div {...attributes}>{DefinitionLayout}</div>
})
