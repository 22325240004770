//
// LayoutTreeMenu
//
// Context menu for the LayoutTree

import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useMousePopover } from '../hooks/useMousePopover'

import { VView, HView, View, Spacer } from '../appview'
import {
    Header,
    Icon,
    Text,
    Property,
    Menu,
    MenuDivider,
    MenuHeader,
    MenuItem,
    TextInput,
    Select,
    Toggle,
} from '../components'
import { AssetThumb, IconTile, AssetSearchPanel } from '../panels'
import { Transform } from '../stores/imageservers/utils'

import {
    LAYOUTCOMPONENTTYPECONFIG,
    sizetypeconverter,
} from '../stores/data/LAYOUTCOMPONENTTYPECONFIG'

const LayoutTreeMenuCreateSubmenu = observer(function LayoutTreeMenuCreateSubmenu({
    onAction,
    worksheetstore,
}) {
    const { app } = useStore()
    const is_class_layout = worksheetstore.datatype === 'class'
    const is_definition_layout = worksheetstore.datatype === 'definition'
    const is_extended_layout = worksheetstore.datatype === 'extended'

    const _onAction = (action, type) => {
        onAction && onAction(action, type)
    }

    return (
        <Menu>
            <MenuHeader>{app.text('Layout')}</MenuHeader>
            <MenuItem onClick={() => _onAction('create_child', 'vertical')}>
                <Icon name="vertical" size={'text'} />
                {app.text('Vertical')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'horizontal')}>
                <Icon name="horizontal" size={'text'} />
                {app.text('Horizontal')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'spacer')}>
                <Icon name="spacer" size={'text'} />
                {app.text('Spacer')}
            </MenuItem>
            <MenuDivider />
            <MenuHeader>{app.text('Data')}</MenuHeader>
            <MenuItem onClick={() => _onAction('create_child', 'field')}>
                <Icon name="field" size={'text'} />
                {app.text('Field')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'fieldlist')}>
                <Icon name="fieldlist" size={'text'} />
                {app.text('Fieldlist')}
            </MenuItem>
            {is_class_layout ? undefined : (
                <MenuItem onClick={() => _onAction('create_child', 'class')}>
                    <Icon name="layout" size={'text'} />
                    {app.text('Class layout')}
                </MenuItem>
            )}
            {is_definition_layout || is_class_layout ? undefined : (
                <MenuItem onClick={() => _onAction('create_child', 'definition')}>
                    <Icon name="layout" size={'text'} />
                    {app.text('Definition layout')}
                </MenuItem>
            )}
            {is_extended_layout ? (
                <MenuItem onClick={() => _onAction('create_child', 'autoextend')}>
                    <Icon name="fieldlist" size={'text'} />
                    {app.text('Auto extend')}
                </MenuItem>
            ) : undefined}
            <MenuItem onClick={() => _onAction('create_child', 'autogenerate')}>
                <Icon name="fieldlist" size={'text'} />
                {app.text('Auto generate')}
            </MenuItem>
            <MenuDivider />
            <MenuHeader>{app.text('Children')}</MenuHeader>
            <MenuItem onClick={() => _onAction('create_child', 'table')}>
                <Icon name="table" size={'text'} />
                {app.text('Table')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'rows')}>
                <Icon name="rows" size={'text'} />
                {app.text('Rows')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'tiles')}>
                <Icon name="tiles" size={'text'} />
                {app.text('Tiles')}
            </MenuItem>
            <MenuDivider />
            <MenuHeader>{app.text('Static')}</MenuHeader>
            <MenuItem onClick={() => _onAction('create_child', 'text')}>
                <Icon name="text" size={'text'} />
                {app.text('Text')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'image')}>
                <Icon name="image" size={'text'} />
                {app.text('Image')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('create_child', 'divider')}>
                <Icon name="divider" size={'text'} />
                {app.text('Divider')}
            </MenuItem>
        </Menu>
    )
})

const LayoutTreeMenuAddFieldSubmenu = observer(function LayoutTreeMenuAddFieldSubmenu({
    onAction,
    worksheetstore,
}) {
    const { app } = useStore()

    const _onAction = (action, type) => {
        onAction && onAction(action, type)
    }

    return (
        <Menu>
            <MenuItem onClick={() => _onAction('table_create_child', 'field')}>
                <Icon name="field" size={'text'} />
                {app.text('Field')}
            </MenuItem>
            <MenuItem onClick={() => _onAction('table_create_child', 'fieldlist')}>
                <Icon name="fieldlist" size={'text'} />
                {app.text('Fieldlist')}
            </MenuItem>
        </Menu>
    )
})

const LayoutTreeMenuSizeProperty = observer(function LayoutTreeMenuSizeProperty({
    onChange,
    value,
}) {
    const { app } = useStore()

    const size = sizetypeconverter(value) // old & new value for 'size' prop

    const [grow, setGrow] = useState(size.grow)
    const [val, setVal] = useState(size.value)

    const onGrowChange = newgrow => {
        setGrow(newgrow)
        onChange && onChange({ grow: newgrow, value: val })
    }
    const onValueChange = newvalue => {
        setVal(newvalue)
        onChange && onChange({ grow: grow, value: newvalue })
    }
    const onTextValueChange = event => {
        const newvalue = event.target.value
        onValueChange(newvalue)
    }

    let Value
    if (grow === 'grow') {
        Value = (
            <HView>
                <Text style={{ padding: '2px 5px' }}>{app.text('ratio')}</Text>
                <View grow>
                    <Select
                        value={val}
                        options={{
                            1: '1',
                            2: '2',
                            3: '3',
                            4: '4',
                            5: '5',
                            6: '6',
                            7: '7',
                            8: '8',
                            9: '9',
                            10: '10',
                        }}
                        onChange={onValueChange}
                    />
                </View>
            </HView>
        )
    } else if (grow === 'fixed') {
        Value = (
            <HView>
                <View grow>
                    <TextInput
                        value={val}
                        onChange={onTextValueChange}
                        onBlur={onTextValueChange}
                        renderkey={grow}
                    />
                </View>
                <Text style={{ padding: '2px 5px' }}>{app.text('pixels')}</Text>
            </HView>
        )
    }

    return (
        <VView>
            <Select
                value={grow}
                options={{ auto: 'auto', grow: 'grow', fixed: 'fixed' }}
                onChange={onGrowChange}
            />
            {Value}
        </VView>
    )
})

const LayoutTreeMenuProperty = observer(function LayoutTreeMenuProperty({
    renderkey,
    config,
    propertyname,
    layoutcomponent,
    worksheetstore,
    onAction,
    inChildContainer,
}) {
    const { app, view, data, appLanguage } = useStore()

    const layoutoptions = { '': app.text('empty') }
    const language = worksheetstore.environment.get('language')
    let value = null
    if ('default' in config) value = config.default
    if (propertyname in layoutcomponent) value = layoutcomponent[propertyname]

    const AssetSearchPopover = useMousePopover(AssetSearchPanel)

    const _onAction = (action, name, value) => {
        AssetSearchPopover.hide()
        onAction && onAction(action, name, value)
    }

    const [asset, setAsset] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setAsset(null)
            if (config.type === 'image' && value) {
                if (!data.assets.has(value)) {
                    await data.fetchAssetByGid(value, { signal: controller.signal })
                }
                const asyncasset = data.assets.get(value)
                if (isMounted) {
                    setAsset(asyncasset)
                }
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [value, data, config.type])

    let Value
    let options
    switch (config.type) {
        case 'root':
            if (value === null) value = ''
            Value = (
                <Select
                    value={value.toString()}
                    options={layoutoptions}
                    onChange={newValue =>
                        _onAction('update_property', propertyname, newValue)
                    }
                />
            )
            break
        case 'field':
            if (value === null) value = ''
            let fields = []
            options = {}
            if (inChildContainer) {
                fields = worksheetstore.getAllChildFields()
            } else {
                fields = worksheetstore.getAllFields()
            }
            fields.map(field => (options[field.gid] = field.name))
            if (value && !(value in options)) {
                if (data.fields.has(value)) {
                    options[value] = data.fields.get(value).name
                } else {
                    value = ''
                }
            }
            Value = (
                <Select
                    value={value.toString()}
                    options={options}
                    onChange={newValue =>
                        _onAction('update_property', propertyname, newValue)
                    }
                />
            )
            break
        case 'class':
            if (value === null) value = ''
            const classes = worksheetstore.getAllClasses()
            options = {}
            classes.map(class_ => (options[class_.gid] = class_.name))
            if (value && !(value in options) && data.classes.has(value)) {
                options[value] = data.classes.get(value).name
            }
            Value = (
                <Select
                    value={value.toString()}
                    options={options}
                    onChange={newValue =>
                        _onAction('update_property', propertyname, newValue)
                    }
                />
            )
            break
        case 'number':
        case 'decimal':
            if (value === null) value = 0
            if (value === 0) {
                value = 'auto'
            }
            Value = (
                <TextInput
                    value={value.toString()}
                    onChange={event =>
                        _onAction(
                            'update_property_in_memory',
                            propertyname,
                            event.target.value
                        )
                    }
                    onBlur={event =>
                        _onAction(
                            'update_property',
                            propertyname,
                            event.target.value.trim()
                        )
                    }
                    renderkey={renderkey}
                />
            )
            break
        case 'boolean':
            value = !!value
            Value = (
                <Toggle
                    value={value}
                    onChange={newValue =>
                        _onAction('update_property', propertyname, newValue)
                    }
                />
            )
            break
        case 'select':
            if (value === null) value = ''
            Value = (
                <Select
                    value={value.toString()}
                    options={config.options}
                    onChange={newValue =>
                        _onAction('update_property', propertyname, newValue)
                    }
                />
            )
            break
        case 'localtext':
            if (value === null) value = ''
            if (Object.prototype.toString.call(value) === '[object Object]') {
                if (language in value) {
                    value = value[language]
                } else {
                    value = ''
                }
            }
            Value = (
                <TextInput
                    value={value.toString()}
                    onChange={event => {
                        let propvalue = {}
                        propvalue[language] = event.target.value
                        _onAction('update_property_in_memory', propertyname, propvalue)
                    }}
                    onBlur={event => {
                        let propvalue = {}
                        propvalue[language] = event.target.value.trim()
                        _onAction('update_property', propertyname, propvalue)
                    }}
                    renderkey={renderkey}
                />
            )
            break
        case 'applocaltext':
            if (value === null) value = ''
            if (Object.prototype.toString.call(value) === '[object Object]') {
                if (appLanguage in value) {
                    value = value[appLanguage]
                } else {
                    value = ''
                }
            }
            Value = (
                <TextInput
                    value={value.toString()}
                    onChange={event => {
                        let propvalue = {}
                        propvalue[appLanguage] = event.target.value
                        _onAction('update_property_in_memory', propertyname, propvalue)
                    }}
                    onBlur={event => {
                        let propvalue = {}
                        propvalue[appLanguage] = event.target.value.trim()
                        _onAction('update_property', propertyname, propvalue)
                    }}
                    renderkey={renderkey}
                />
            )
            break
        case 'image':
            const searchpanelstyle = { display: 'flex', width: 240, minHeight: 500 }
            const assetTransform = new Transform('fit', 155, 100)
            Value = (
                <>
                    <AssetSearchPopover.Panel
                        querystore={view.assetquery}
                        preview={false}
                        className="inspector panel asset-search-panel"
                        style={searchpanelstyle}
                        onSelect={assetgid => {
                            _onAction('update_property', propertyname, assetgid)
                        }}
                    />
                    {!asset ? (
                        <IconTile
                            width={32}
                            className="ws-tile cc-dimmed"
                            icon="plus"
                            anchor="left"
                            onClick={e => AssetSearchPopover.onShow(e)}
                        />
                    ) : (
                        <AssetThumb
                            asset={asset}
                            transform={assetTransform}
                            iconplaceholder="plus"
                            onClick={e => AssetSearchPopover.onShow(e)}
                            onContextMenu={e => AssetSearchPopover.onShow(e)}
                        />
                    )}
                </>
            )
            break
        case 'size':
            Value = (
                <LayoutTreeMenuSizeProperty
                    value={value}
                    onChange={newvalue =>
                        _onAction('update_property', propertyname, newvalue)
                    }
                />
            )
            break
        default:
            if (value === null) value = ''
            Value = (
                <TextInput
                    value={value.toString()}
                    onChange={event =>
                        _onAction(
                            'update_property_in_memory',
                            propertyname,
                            event.target.value
                        )
                    }
                    onBlur={event =>
                        _onAction(
                            'update_property',
                            propertyname,
                            event.target.value.trim()
                        )
                    }
                    renderkey={renderkey}
                />
            )
    }

    return <Property label={propertyname}>{Value}</Property>
})

export const LayoutTreeMenu = observer(function LayoutTreeMenu({
    layoutcomponent,
    isRoot,
    worksheetstore,
    onAction,
}) {
    const { app } = useStore()
    const layoutstore = worksheetstore.layoutstore

    const [activeMenuitem, setActiveMenuitem] = useState(null)

    if (!layoutcomponent) return null

    const _onAction = (action, name, value) => {
        onAction && onAction(action, name, value)
    }

    const config = LAYOUTCOMPONENTTYPECONFIG[layoutcomponent.type]
    let inChildContainer = false
    let component = layoutcomponent
    while (component && component.type !== 'root' && !inChildContainer) {
        if (LAYOUTCOMPONENTTYPECONFIG[component.type].isChildContainer) {
            inChildContainer = true
            break
        }
        component = layoutstore.getComponentParent(component.gid)
    }

    let Properties = []
    let Actions = []

    let propertynames = [
        'layout',
        'name',
        'group',
        'field',
        'class',
        'typeconfig',
        'fieldlist',
        'text',
        'image',
        'columns',
        'size',
        'height',
        'itemheight',
        'rowheight',
        'style',
        'editable',
        'navigation',
        'placeholder',
    ]
    let fieldtypepropertynames = [
        'name',
        'text',
        'image',
        'size',
        'style',
        'thumbformat',
        'columns',
        'editable',
        'navigation',
        'placeholder',
    ]

    propertynames.forEach(propertyname => {
        if (layoutcomponent.isRecursive) return
        if (!(propertyname in config)) return
        if (propertyname === 'typeconfig' && 'field' in layoutcomponent) {
            // add multiple properties based on type of field
            const fields = inChildContainer
                ? worksheetstore.getAllChildFields()
                : worksheetstore.getAllFields()
            const maybe_fields = fields.filter(
                maybe_field => maybe_field.gid === layoutcomponent.field
            )
            const field = maybe_fields ? maybe_fields[0] : null
            const fieldtype = field ? field.type : null
            const fieldtypeconfig = fieldtype ? config[propertyname][fieldtype] : {}
            fieldtypepropertynames.forEach(fieldtypepropertyname => {
                if (!(fieldtypepropertyname in fieldtypeconfig)) return
                const fieldtypepropertyconfig = fieldtypeconfig[fieldtypepropertyname]
                Properties.push(
                    <LayoutTreeMenuProperty
                        key={
                            layoutcomponent.gid +
                            '.' +
                            propertyname +
                            '.' +
                            fieldtypepropertyname
                        }
                        renderkey={
                            layoutcomponent.gid +
                            '.' +
                            propertyname +
                            '.' +
                            fieldtypepropertyname
                        }
                        config={fieldtypepropertyconfig}
                        propertyname={fieldtypepropertyname}
                        layoutcomponent={layoutcomponent}
                        worksheetstore={worksheetstore}
                        onAction={_onAction}
                        inChildContainer={inChildContainer}
                    />
                )
            })
            return
        }
        const propertyconfig = config[propertyname]
        Properties.push(
            <LayoutTreeMenuProperty
                key={layoutcomponent.gid + '.' + propertyname}
                renderkey={layoutcomponent.gid + '.' + propertyname}
                config={propertyconfig}
                propertyname={propertyname}
                layoutcomponent={layoutcomponent}
                worksheetstore={worksheetstore}
                onAction={_onAction}
                inChildContainer={inChildContainer}
            />
        )
    })

    const isContainer = 'isContainer' in config && config.isContainer
    const isFieldContainer = 'isFieldContainer' in config && config.isFieldContainer

    let last_divider = false

    if (layoutcomponent.type === 'root') {
        if (!layoutcomponent.root) {
            Actions.push(
                <MenuItem
                    key="create"
                    submenu={
                        <LayoutTreeMenuCreateSubmenu
                            worksheetstore={worksheetstore}
                            onAction={_onAction}
                        />
                    }
                >
                    {app.text('New child')}
                </MenuItem>
            )
            last_divider = false
        }
        if (!isRoot) {
            Actions.push(<MenuDivider key="divider-modify-layout" />)
            last_divider = true
        }
    }

    if (isFieldContainer) {
        Actions.push(
            <MenuItem
                key="addfield"
                submenu={
                    <LayoutTreeMenuAddFieldSubmenu
                        worksheetstore={worksheetstore}
                        onAction={_onAction}
                    />
                }
            >
                {app.text('Add fields')}
            </MenuItem>
        )
        last_divider = false
    } else if (isContainer) {
        Actions.push(
            <MenuItem
                key="create"
                submenu={
                    <LayoutTreeMenuCreateSubmenu
                        worksheetstore={worksheetstore}
                        onAction={_onAction}
                    />
                }
            >
                {app.text('New child')}
            </MenuItem>
        )
        last_divider = false
    }

    if (!isRoot) {
        if (Actions.length && !last_divider) {
            Actions.push(<MenuDivider key="divider-remove" />)
        }
        Actions.push(
            <MenuItem
                key="remove"
                className="cc-danger"
                onClick={() => _onAction('remove')}
            >
                {app.text('Remove from layout')}
            </MenuItem>
        )
    }

    let ActionsSection
    if (Actions.length) {
        ActionsSection = (
            <>
                <Header>{app.text('Actions')}</Header>
                <Menu
                    activeMenuitem={activeMenuitem}
                    setActiveMenuitem={setActiveMenuitem}
                >
                    {Actions}
                </Menu>
            </>
        )
    }

    return (
        <VView className="layouttreemenu-panel" onClick={() => setActiveMenuitem(null)}>
            <div className="inspector">
                <Header>{app.text('Properties')}</Header>
                <Property label={app.text('type')}>
                    <Text>{layoutcomponent.type}</Text>
                </Property>
                {Properties}
                {layoutcomponent.isRecursive ? (
                    <Text>
                        {app.text(
                            'This is a circular sublayout inclusion. Please remove it from this layout.'
                        )}
                    </Text>
                ) : undefined}
                <Spacer size={5} />
            </div>
            {ActionsSection}
        </VView>
    )
})
