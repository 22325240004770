//
// TextInput
//
// Input field for plain text, both single and multiline.
//
// Note: rendering is memoized so we don't rerender when we're typing. The memoization
// specifically excludes the value, but also the onChange and onBlur event handlers.
// This means that if you switch the input to some other field that has the same value,
// but obviously has changed the onBlur and onChange to point to some other functions,
// then we actually SHOULD rerender but we don't... so in those case we need a unique
// key that can be updated at the same time, hence the 'renderkey' prop. In Unicat
// context, that usually means providing the record gid + field gid

import React, { useState, useRef, useMemo, useEffect, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { keyboard } from '../utils/keyboard'
import { useDebounce } from '../hooks/useDebounce'
import { useSelectPopover } from '../hooks/useSelectPopover'

import { VALIDATION } from '../utils/validation'
import { ValidationMessage } from './ValidationMessage'

import { breakingspaces, regexSafe } from '../utils/text'
// import { visualizeWhitespace } from '../utils/text'

// TODO: CC-203 Esc: restore original value and blur
const Input = React.forwardRef(
    (
        {
            multiline,
            enabled,
            language,
            value,
            autoConvert,
            onFocus,
            onChange,
            onBlur,
            onKeyDown,
            onClick,
            renderkey,
            className,
            style,
        },
        ref
    ) => {
        // if not specified, default to 'enabled'
        if (enabled === undefined) enabled = true

        const convert = useMemo(() => {
            return autoConvert ? autoConvert : text => text
        }, [autoConvert])

        // consecutive spaces are replaced with space-nonbreakingspace so typing and
        // alignment stays ok and browsers don't mess with consecutive spaces/whitespace
        // we also need to make sure spaces at the start or end of any line are
        // retained, so we change them to nonbreakingspace too
        // we don't change -all- spaces to nonbreakingspaces, then word-wrap won't work
        const spacy = useMemo(() => {
            return text =>
                breakingspaces(text)
                    .replace(/ {2}/g, '  ')
                    .replace(/^ /gm, ' ')
                    .replace(/ $/gm, ' ')
        }, [])

        // innerValue is set here initially, then updated in the onInput event, which
        // also calls the onChange handler
        // When the prop is changed (due to onChange handler), the new htmlValue should
        // be the same as the current innerValue when we're typing, meaning we won't have
        // to re-render. When the values are different, that means we weren't typing in
        // this input, so we do need a re-render.
        const htmlValue = convert(value)
        const [innerValue, setInnerValue] = useState(htmlValue)
        const [isActive, setIsActive] = useState(false)

        // debugging Too Many Rerenders
        // if (htmlValue.startsWith('Mooie serie')) {
        //     console.log(
        //         convert,
        //         visualizeWhitespace(value),
        //         visualizeWhitespace(htmlValue),
        //         visualizeWhitespace(innerValue),
        //         visualizeWhitespace(spacy(htmlValue)),
        //         ref.current
        //             ? visualizeWhitespace(ref.current.innerText)
        //             : 'no ref.current'
        //     )
        // }

        if (!isActive) {
            if (
                ref.current
                    ? breakingspaces(htmlValue).trim() !==
                      convert(breakingspaces(ref.current.innerText)).trim()
                    : breakingspaces(htmlValue).trim() !==
                      breakingspaces(innerValue).trim()
            ) {
                // externally set new value, so make sure we reflect that
                setInnerValue(htmlValue)
                if (ref.current) {
                    // not inside useLayoutEffect because we don't rerender
                    ref.current.innerText = spacy(htmlValue)
                }
            }
        }

        // memoizedRender will keep the caret in place
        // NOTE: react says you can't count on this, but it is better than what I had before
        const memoizedRender = useMemo(() => {
            const onMultilineKeyDown = event => {
                if (!enabled) return
                if (keyboard.test(event, keyboard.ENTER)) {
                    if (!multiline || keyboard.testCommand(event)) {
                        event.returnValue = false
                        if (event.preventDefault) event.preventDefault()
                        if (ref.current && !onKeyDown) {
                            ref.current.blur()
                            return
                        }
                    }
                }
                let textValue = ''
                if (ref.current) {
                    textValue = convert(ref.current.innerText)
                }
                // update target of event with actual value
                const updatedEvent = Object.assign({}, event, {
                    target: {
                        value: textValue,
                        element: ref.current,
                    },
                    preventDefault: event.preventDefault,
                    stopPropagation: event.stopPropagation,
                })
                onKeyDown && onKeyDown(updatedEvent)
            }

            const pasteAsPlainText = event => {
                if (!enabled) return
                event.preventDefault()

                const text = (event.clipboardData || window.clipboardData).getData(
                    'text/plain'
                )
                const convertedtext = convert(text)

                const selection = window.getSelection()
                if (!selection.rangeCount) return
                selection.deleteFromDocument()
                selection
                    .getRangeAt(0)
                    .insertNode(document.createTextNode(convertedtext))
                selection.collapseToEnd()

                let textValue
                if (ref.current) {
                    textValue = convert(ref.current.innerText)
                    setInnerValue(textValue)
                }
                // update target of event with actual value
                const updatedEvent = Object.assign({}, event, {
                    target: {
                        value: textValue,
                    },
                    preventDefault: event.preventDefault,
                    stopPropagation: event.stopPropagation,
                })
                onChange && onChange(updatedEvent)

                // const text = event.clipboardData.getData('text/plain')
                // document.execCommand('insertText', false, convert(text))
            }
            // chrome triggers onInput multiple times when there's newlines in
            // pasted text (via pasteAsPlainText, which is triggered once)
            // each time there's one line of data (without newline, then null,
            // a data line, null, etc.) => update: it seems it still is triggered
            // many times, but each time with the full text
            // to mitigate this, we use a debounced onChange handler with a short delay,
            // but inside the actual handler (which actually handles onInput)
            // if we put the debounce on the actual handler, it messes up the timing
            // especially when combined with onKeyDown
            const innerOnInput = event => {
                if (!enabled) return
                let textValue
                if (ref.current) {
                    textValue = convert(ref.current.innerText)
                    setInnerValue(textValue)
                }
                // update target of event with actual value
                const updatedEvent = Object.assign({}, event, {
                    target: {
                        value: textValue,
                    },
                    preventDefault: event.preventDefault,
                    stopPropagation: event.stopPropagation,
                })
                onChange && onChange(updatedEvent)
            }
            const innerOnBlur = event => {
                if (!enabled) return
                setIsActive(false)
                let textValue
                if (ref.current) {
                    // Firefox fix for cursor in input inside draggable
                    // Remove on focus, add on blur
                    // Do querySelectorAll because draggables can be nested
                    const draggables = document.querySelectorAll(
                        '[data-onblur-draggable]'
                    )
                    draggables.forEach(element => {
                        element.setAttribute('draggable', true)
                        element.removeAttribute('data-onblur-draggable')
                    })

                    textValue = convert(ref.current.innerText)
                    setInnerValue('') // by setting it to "" it will be updated by htmlValue prop
                }
                // update target of event with actual value
                const updatedEvent = Object.assign({}, event, {
                    target: {
                        value: breakingspaces(textValue),
                    },
                    preventDefault: event.preventDefault,
                    stopPropagation: event.stopPropagation,
                })
                onBlur && onBlur(updatedEvent)
            }

            const innerOnFocus = event => {
                if (!enabled) return
                setIsActive(true)
                if (ref.current) {
                    // Firefox fix for cursor in input inside draggable
                    // Remove on focus, add on blur
                    // Do querySelectorAll because draggables can be nested
                    const draggables = document.querySelectorAll('[draggable]')
                    draggables.forEach(element => {
                        element.setAttribute('draggable', false)
                        element.setAttribute('data-onblur-draggable', true)
                    })
                }
                onFocus && onFocus(event)
            }

            const lang = language ? { lang: language } : {}

            return (
                <div
                    ref={ref}
                    className={className}
                    contentEditable={enabled}
                    translate="no"
                    {...lang}
                    dangerouslySetInnerHTML={{ __html: spacy(htmlValue) }}
                    onKeyDown={onMultilineKeyDown}
                    onPaste={pasteAsPlainText}
                    onInput={innerOnInput}
                    onFocus={innerOnFocus}
                    onBlur={innerOnBlur}
                    onClick={event => {
                        if (enabled) {
                            event.stopPropagation()
                            event.nativeEvent.stopImmediatePropagation()
                        }
                        onClick && onClick(event)
                    }}
                    style={style}
                />
            )
            // WHEN CHANGING THIS, THE ONLY MISSING DEPENDENCY SHOULD BE htmlValue, onChange, and onBlur !!
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [renderkey, multiline, enabled, ref, className, style, convert])
        return memoizedRender
    }
)

const highlightMatchingText = (text, substring) => {
    const escaped_substring = regexSafe(substring)
    const textparts = text.split(RegExp(escaped_substring, 'ig'))
    const match = text.match(RegExp(escaped_substring, 'ig'))

    return (
        <>
            {textparts.map((item, index) => (
                <span key={item + '.' + index}>
                    {item}
                    {index !== textparts.length - 1 && match && <b>{match[index]}</b>}
                </span>
            ))}
        </>
    )
}

const AutocompleteOptionsPanel = observer(function AutocompleteOptionsPanel({
    value,
    completions,
    onSelect,
}) {
    const Options = completions.map((completion, index) => {
        const gid = Array.isArray(completion) ? completion[0] : completion
        const option = Array.isArray(completion) ? completion[1] : completion
        return (
            <div
                key={index.toString() + '.' + gid}
                className="cc-Input-Option"
                onMouseDown={e => {
                    // this prevents the blur!
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                }}
                onClick={e => {
                    onSelect && onSelect(e, gid)
                }}
            >
                {highlightMatchingText(option, value)}
            </div>
        )
    })

    let style = {}
    if (completions.length > 10) {
        style = { maxHeight: 180, overflowY: 'scroll' }
    }

    return (
        <div className="cc-Input-Options" style={style}>
            {Options}
        </div>
    )
})

export const TextInput = ({
    value,
    language,
    enabled,
    multiline,
    placeholder,
    autoComplete,
    validate,
    setFocus,
    onFocus,
    onChange,
    onBlur,
    onKeyDown,
    style,
    ...other
}) => {
    const Placeholder = placeholder ? (
        <div className="cc-Placeholder">{placeholder}</div>
    ) : undefined

    const strvalue = value ? value.toString() : ''

    if (enabled === undefined) enabled = true

    const debouncedValue = useDebounce(strvalue, 200)
    const [completions, setCompletions] = useState([])
    const [validation, setValidation] = useState(null)

    const AutocompleteOptionsPopover = useSelectPopover(AutocompleteOptionsPanel, {
        onClickOutside: e => {},
    })

    const setShowOptions = state => {
        if (!enabled) return
        if (state) AutocompleteOptionsPopover.show()
        else AutocompleteOptionsPopover.hide()
    }

    const inputRef = useRef(null)

    useLayoutEffect(() => {
        if (inputRef.current && setFocus) {
            inputRef.current.focus()
            if (setFocus === 'end') {
                window.setTimeout(() => {
                    if (inputRef.current) {
                        let range = document.createRange()
                        range.selectNodeContents(inputRef.current)
                        range.collapse(false)
                        let selection = window.getSelection()
                        selection.removeAllRanges()
                        selection.addRange(range)
                    }
                }, 10)
            }
        }
    }, [inputRef, setFocus])

    useEffect(() => {
        if (!autoComplete) return
        let isMounted = true
        autoComplete(debouncedValue)
            .then(autocompletions => {
                if (isMounted) {
                    setCompletions(autocompletions)
                }
            })
            .catch(error => {})
        return () => (isMounted = false)
    }, [autoComplete, debouncedValue])

    useEffect(() => {
        if (!validate) return
        let isMounted = true
        // this works for returned Promises and for returned regular values
        new Promise(resolve => resolve(validate(debouncedValue))).then(
            validationresult => {
                if (isMounted) {
                    setValidation(validationresult)
                }
            }
        )
        // .catch(error => {})
        return () => (isMounted = false)
    }, [validate, debouncedValue])

    let classes = 'cc-Input'
    if (!enabled) {
        classes += ' cc-disabled'
    }
    if (multiline) classes += ' cc-Input-multiline'
    if (!value || !strvalue.toString().trim().length) {
        classes += ' cc-placeholder-visible'
    }

    if (validation) {
        if (validation.result === VALIDATION.ERROR) {
            classes += ' validation-error'
        } else if (validation.result === VALIDATION.REPORT) {
            classes += ' validation-report'
        }
    }

    const selectOption = (e, selectedOption) => {
        if (inputRef.current) {
            // hmmmmmmmmmmmmmmm, but works
            inputRef.current.innerText = selectedOption
        }

        e.target.value = selectedOption
        _onChange(e)
        setShowOptions(false)
    }

    const _onFocus = e => {
        setShowOptions(true)
        onFocus && onFocus(e)
    }

    const _onChange = e => {
        setShowOptions(true)
        onChange && onChange(e)
    }

    const _onBlur = e => {
        setShowOptions(false)
        onBlur && onBlur(e)
    }

    const _onKeyDown = e => {
        setShowOptions(true)
        onKeyDown && onKeyDown(e)
    }

    const _onClick = e => {
        setShowOptions(true)
    }

    return (
        <>
            <div
                className={classes}
                style={style}
                ref={AutocompleteOptionsPopover.anchorRef}
            >
                <Input
                    ref={inputRef}
                    value={strvalue}
                    language={language ? language : ''}
                    enabled={enabled}
                    setFocus={setFocus}
                    multiline={multiline}
                    onFocus={_onFocus}
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onClick={_onClick}
                    onKeyDown={onKeyDown ? _onKeyDown : undefined}
                    {...other}
                />
                {Placeholder}
                <ValidationMessage validation={validation} />
            </div>
            <AutocompleteOptionsPopover.Panel
                hidePopover={completions.length === 0}
                completions={completions}
                value={debouncedValue}
                onSelect={selectOption}
            />
        </>
    )
}
