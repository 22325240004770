//
// KeyValueField
//
// A text-input for a field where you can select from options.

import React from 'react'
import { observer } from 'mobx-react-lite'

import { KeyValueInput } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'

export const KeyValueField = observer(function KeyValueField(props) {
    const {
        className,
        record,
        field,
        fromClass,
        component,
        enabled,
        force_readonly,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    let classes = 'cc-Field cc-Textfield cc-KeyValueField ws-text'
    if (!enabled) classes += ' cc-disabled'

    if (className) classes += ' ' + className
    let fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name + '/key')
    if (fieldvalue === undefined) {
        fieldvalue = record.localized_fields && record.localized_fields.get(field.name)
    }

    const validator = fieldvalue => {
        return validateField(fieldvalue, field, record.language)
    }

    const onChangeKey = newKey => {
        record.setFieldKey(field, newKey)
    }

    const onBlurKey = newKey => {
        if (validateField(newKey, field, record.language).result === VALIDATION.ERROR) {
            record.resetField(field)
        } else {
            record.setFieldKey(field, newKey)
            record.commitIfModified()
        }
    }

    return (
        <div className={classes} {...other}>
            <KeyValueInput
                enabled={enabled}
                value={fieldvalue}
                values={field.options.get('values')}
                hasLabels={field.options.get('value_labels')}
                restrictToValues={field.options.get('restrict_to_values')}
                language={worksheet.language}
                onChangeKey={onChangeKey}
                onBlurKey={onBlurKey}
                validate={validator}
            />
        </div>
    )
})
