//
// RecursionMessage
//
// don't end up in an infinite loop

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

export const RecursionMessage = observer(function RecursionMessage({ component }) {
    const { app } = useStore()
    return (
        <pre className="layout-component lc-recursion">
            {app.text('Circularity detected: {name}', {
                name: component.name,
            })}
        </pre>
    )
})
