//
// TableLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { LazyVScrollTable, LazyScrollRow } from '../../../../appview'
import { Icon, Text } from '../../../../components'
import { LabeledField } from '../fields/LabeledField'
import { FieldLabel } from '../fields'
import { TypedField } from '.'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { useLazyScrollingRecordStore } from '../../../../hooks/useLazyScrollingRecordStore'

export const TableLayoutBuilder = observer(function TableLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const store = useStore()
    const { view } = useStore()

    const { children, totalsize, firstindex, onUpdate } = useLazyScrollingRecordStore(
        worksheet.record.gid,
        worksheet.children,
        worksheet.environment,
        0,
        10
    )

    const config = LAYOUTCOMPONENTTYPECONFIG['table']

    const name = component.name ? component.name : component.type
    const placeholder = component.placeholder
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]
    const editable = force_readonly
        ? false
        : 'editable' in component
        ? component.editable
        : config.editable.default
    const navigation =
        'navigation' in component ? component.navigation : config.navigation.default
    const height = component.height ? parseInt(component.height) : config.height.default
    const rowheight = component.rowheight
        ? parseInt(component.rowheight)
        : config.rowheight.default
    const headerheight = component.headerheight
        ? parseInt(component.headerheight)
        : config.headerheight.default

    let Table
    if (children && children.length) {
        const firstchild = children[0]
        const childdefinition = worksheet.definitions.get(firstchild.definition)
        const contentcomponents =
            component.content && component.content.length
                ? component.content.map(subelement_gid =>
                      components.get(subelement_gid)
                  )
                : []
        const fieldlikecomponents = contentcomponents.filter(
            cc => cc && ['field', 'fieldlist'].includes(cc.type)
        )
        let fieldnames = []
        let field_duckcomponents = {}
        fieldlikecomponents.map(fieldlikecomponent => {
            if (fieldlikecomponent.type === 'field') {
                fieldnames.push(fieldlikecomponent.field)
                field_duckcomponents[fieldlikecomponent.field] = {
                    editable:
                        editable &&
                        ('editable' in fieldlikecomponent
                            ? fieldlikecomponent.editable
                            : LAYOUTCOMPONENTTYPECONFIG['field'].editable.default),
                    style: fieldlikecomponent.style,
                    columns: fieldlikecomponent.columns,
                }
            } else if (fieldlikecomponent.type === 'fieldlist') {
                const fieldlistnames =
                    childdefinition &&
                    childdefinition.fieldlists.has(view.environment.get('fieldlist'))
                        ? childdefinition.fieldlists.get(
                              view.environment.get('fieldlist')
                          )
                        : []
                if (fieldlistnames) {
                    fieldnames.push(
                        ...fieldlistnames.map(fieldgid => {
                            field_duckcomponents[fieldgid] = {
                                editable:
                                    editable &&
                                    ('editable' in fieldlikecomponent
                                        ? fieldlikecomponent.editable
                                        : LAYOUTCOMPONENTTYPECONFIG['fieldlist']
                                              .editable.default),
                                style: fieldlikecomponent.style,
                                columns: fieldlikecomponent.columns,
                            }
                            return fieldgid
                        })
                    )
                }
            }
            return true
        })

        const fields = worksheet.findFields(fieldnames)

        const firstHeadercell = navigation ? (
            <td className="ws-table-cell ws-table-cell-navigator"></td>
        ) : undefined
        const Headercells = fields.map(field => {
            let classes = 'ws-table-cell ws-table-cell-' + field.name
            if (field.gid === worksheet.selected.property.fieldgid) {
                classes = classes + ' ws-selected'
            }
            return (
                <td
                    key={'field' + field.gid}
                    className={classes}
                    onClick={e => {
                        worksheet.setSelected(firstchild, null, field.gid, null, null)
                        e.preventDefault()
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation()
                    }}
                >
                    <Text>
                        <FieldLabel field={field} worksheet={worksheet} />
                    </Text>
                </td>
            )
        })
        const Header = (
            <LazyScrollRow className="ws-table-header">
                {firstHeadercell}
                {Headercells}
            </LazyScrollRow>
        )
        const Rows = children.map((child, index) => {
            const firstCell = navigation ? (
                <td
                    className="ws-table-cell ws-table-cell-navigator"
                    onClick={() => worksheet.setRecord(child)}
                >
                    <Icon name={'collapsed'} size={'text'} />
                </td>
            ) : undefined
            const Cells = fields.map(field => {
                const value =
                    child.localized_fields &&
                    child.localized_fields.get('' + field.name)
                let classes =
                    'ws-table-cell ws-table-cell-' + field.name + ' layout-component'
                if (field.gid in field_duckcomponents) {
                    classes += ' lc-' + field_duckcomponents[field.gid]['style']
                }
                const editable =
                    field.gid in field_duckcomponents
                        ? field_duckcomponents[field.gid]['editable']
                        : false
                const Field =
                    value === undefined ? (
                        <Text>
                            {store.app.text("Undefined field '{fieldname}'", {
                                fieldname: field.name,
                            })}
                        </Text>
                    ) : (
                        <TypedField
                            field={field}
                            fromClass={null}
                            component={field_duckcomponents[field.gid]}
                            editable={editable}
                            force_readonly={force_readonly}
                            record={child}
                            active_class={active_class}
                            worksheet={worksheet}
                            visited_gids={visited_gids}
                        />
                    )
                return (
                    <td
                        key={'field' + field.gid}
                        className={classes}
                        onClick={e => {
                            worksheet.setSelected(child, null, field.gid, null, null)
                            e.preventDefault()
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                minHeight: rowheight,
                            }}
                        >
                            {Field}
                        </div>
                    </td>
                )
            })
            let rowclasses = 'ws-table-row status-' + child.status
            if (
                child.gid === worksheet.selected.record &&
                worksheet.selected.record.gid
            ) {
                rowclasses = rowclasses + ' ws-selected'
            }
            return (
                <LazyScrollRow
                    className={rowclasses}
                    key={child.gid + '/' + view.environment.get('fieldlist')}
                >
                    {firstCell}
                    {Cells}
                </LazyScrollRow>
            )
        })
        Table = (
            <LazyVScrollTable
                className="ws-record-navigator ws-rows"
                firstindex={firstindex}
                headerrow={Header}
                headerheight={headerheight}
                tableheight={height}
                rowheight={rowheight}
                totalitems={totalsize}
                onUpdate={onUpdate}
                renderkey={
                    worksheet.record.gid + '/' + view.environment.get('fieldlist')
                }
            >
                {Rows}
            </LazyVScrollTable>
        )
    }

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = {
        className: 'layout-component ws-record-navigator ws-table',
    }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (!Table) {
        attributes['className'] += ' ws-placeholder-visible'
        Table = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <LabeledField
            fieldgid={name}
            editable={editable}
            hide_editable_icon={force_readonly}
            label={name}
            worksheet={worksheet}
            {...attributes}
        >
            {Table}
            {Placeholder}
        </LabeledField>
    )
})
