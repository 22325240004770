//
// LabWorkspace
//
// Feel free to work on anything here, but please keep the basic structure with the
// navbar and itemviews. Just add your own entries for both to start experimenting.
// I suggest you do the actual experimenting in lab/Test, and if it's something we
// can use for demo/test purposes later, we can create a proper Lab entry, like Icons
// or Popovers

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { usePersistentState } from '../hooks/usePersistentState'
import { useOnResize } from '../hooks/useOnResize'

import { VView, HView, View, Divider, VScrollView, SimpleSplitter } from '../appview'
import { Item } from '../components'
import { Assets } from './lab/Assets'
import { Icons } from './lab/Icons'
import { Colors } from './lab/Colors'
import { ContextMenu } from './lab/ContextMenu'
import { LazyScroll } from './lab/LazyScroll'
import { TextInput } from './lab/TextInput'
import { TextValues } from './lab/TextValues'
import { Test } from './lab/Test'

export const LabWorkspace = observer(function LabWorkspace(props) {
    const store = useStore()
    const app = store.app

    const { grow } = props

    const windowsize = useOnResize()
    const windowWidth = windowsize[0]
    const minNavigatorWidth = 150
    const absmaxNavigatorWidth = 400
    const minWorksheetWidth = 600 // not set in css

    const [navigatorWidth, setNavigatorWidth] = usePersistentState(
        'Lab.NavigatorWidth',
        minNavigatorWidth
    )
    const navigatorRef = React.useRef(null)

    // make sure everything fits ater resizing to a smaller window
    // only set max value if windowWidth is known
    // no min worksheet size, but use 600 anyway
    const maxNavigatorWidth = windowWidth
        ? Math.max(
              minNavigatorWidth,
              Math.min(absmaxNavigatorWidth, windowWidth - (minWorksheetWidth + 1))
          )
        : navigatorWidth

    const [navitem, setNavitem] = usePersistentState('Lab.NavigatorSelection', 'Icons')
    const navitems = [
        'Test',
        'Assets',
        'Icons',
        'Colors',
        'ContextMenu',
        'LazyScroll',
        'TextInput',
        'TextValues',
    ]

    const NavItems = navitems.map(thisitem => {
        return (
            <Item
                key={thisitem}
                selected={navitem === thisitem}
                onClick={() => setNavitem(thisitem)}
            >
                {thisitem}
            </Item>
        )
    })
    let ItemView = undefined
    switch (navitem) {
        case 'Assets':
            ItemView = <Assets />
            break
        case 'Icons':
            ItemView = <Icons />
            break
        case 'Colors':
            ItemView = <Colors />
            break
        case 'ContextMenu':
            ItemView = <ContextMenu />
            break
        case 'LazyScroll':
            ItemView = <LazyScroll />
            break
        case 'TextInput':
            ItemView = <TextInput />
            break
        case 'TextValues':
            ItemView = <TextValues />
            break
        case 'Test':
        default:
            ItemView = <Test />
            break
    }

    return (
        <HView grow={grow} className="workspace-lab">
            <VView className="navigator" ref={navigatorRef}>
                <div className="toolbar">{app.text('Sections')}</div>
                <Divider />
                <VScrollView>{NavItems}</VScrollView>
            </VView>

            <SimpleSplitter
                target={navigatorRef}
                direction="end"
                initial={navigatorWidth}
                min={minNavigatorWidth}
                max={maxNavigatorWidth}
                afterResize={newValue => setNavigatorWidth(newValue)}
            />

            <VView grow>
                <div className="toolbar">{navitem}</div>
                <Divider />
                <VScrollView>
                    <View fill>{ItemView}</View>
                </VScrollView>
            </VView>
        </HView>
    )
})
