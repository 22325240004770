//
// RecordListField
//
// A record-input for multiple records.

import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { Transform } from '../../../../stores/imageservers/utils'
import { DragDropLinkedRecord } from '../../../../dragdrop/DragDropLinkedRecord'

import { TilesView } from '../../../../appview'
import { RecordItem, RecordCard, RecordThumb, IconTile } from '../../../../panels'
import { RecordFieldMenu } from '../../../../menus'
import {
    addListValue,
    replaceListValue,
    moveListValue,
    removeListValue,
    deduplicateList,
} from '../../../../utils/list'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { NavigationTileContext } from '../layoutbuilders/TilesLayoutBuilder'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

const MAGIC_WIDTH = { card: 200, item: 160, thumb: 40 }
const MAGIC_GAP = 15

export const RecordListField = observer(function RecordListField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    const inNavigationTile = useContext(NavigationTileContext)
    const { data, view } = useStore()
    const [containerRef, containerWidth] = useElementWidth(250)

    const RecordFieldContextMenu = useMousePopover(RecordFieldMenu)

    let classes = 'cc-Field cc-RecordListField ws-tiles'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const componentstyle = component
        ? component.style || config.style.default
        : config.style.default

    const maxtilesperrow = Math.max(
        1,
        Math.floor(
            (containerWidth + MAGIC_GAP) / (MAGIC_WIDTH[componentstyle] + MAGIC_GAP)
        )
    )
    const tilesperrow = Math.min(
        maxtilesperrow,
        component
            ? parseInt(component.columns, 10) || config.columns.default
            : config.columns.default
    )

    const tileWidth = Math.max(
        20,
        Math.floor((containerWidth - MAGIC_GAP * (tilesperrow - 1)) / tilesperrow)
    )

    const [linkedrecordgids, setLinkedRecordgids] = useState([])
    useEffect(() => {
        if (Array.isArray(fieldvalue)) {
            setLinkedRecordgids(fieldvalue)
        } else {
            setLinkedRecordgids([])
        }
    }, [fieldvalue])

    const [linkedrecords, setLinkedRecords] = useState([])
    const [selectedRecord, setSelectedRecord] = useState(null)
    useClickOutside(containerRef, () => setSelectedRecord(null))
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            if (!linkedrecordgids || !linkedrecordgids.length) {
                setLinkedRecords([])
                return
            }
            setLinkedRecords(new Array(linkedrecordgids.length).fill(null))
            const missingGids = linkedrecordgids.filter(gid => !data.records.has(gid))
            if (missingGids.length) {
                await data.fetchRecordsByGid(missingGids, { signal: controller.signal })
            }
            const asyncrecords = linkedrecordgids
                .map(gid => data.records.get(gid))
                .filter(record => record !== null)
            if (isMounted) {
                setLinkedRecords(asyncrecords)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [linkedrecordgids, data])

    const setAndCommitField = updatedids => {
        const uniqueids = deduplicateList(updatedids)
        record.setField(
            field,
            uniqueids.map(id => id.toString())
        )
        record.commitIfModified()
    }

    const onAddRecord = (before_record, recordgid) => {
        if (linkedrecordgids.includes(recordgid)) return
        const before_recordgid = before_record ? before_record.gid : null
        const updatedgids = addListValue(linkedrecordgids, recordgid, before_recordgid)
        setAndCommitField(updatedgids)
    }

    const onReplaceRecord = (record_to_replace, recordgid) => {
        if (linkedrecordgids.includes(recordgid)) return
        const recordgid_to_replace = record_to_replace ? record_to_replace.gid : null
        const updatedgids = replaceListValue(
            linkedrecordgids,
            recordgid,
            recordgid_to_replace
        )
        setAndCommitField(updatedgids)
    }

    const onRemoveRecord = record_to_remove => {
        const recordgid_to_remove = record_to_remove ? record_to_remove.gid : null
        const updatedgids = removeListValue(linkedrecordgids, recordgid_to_remove)
        setAndCommitField(updatedgids)
    }

    const onRemoveAllRecords = () => {
        setAndCommitField([])
    }

    const onRevealInPim = linkedrecord => {
        view.pimworksheet.setRecord(linkedrecord)
        view.pimtree.datamanager.setRecord(linkedrecord)
        view.setWorkspace('pim')
    }

    const onRecordFieldAction = (action, linkedrecord, otherrecordgid) => {
        const actions = {
            add_record: onAddRecord,
            replace_record: onReplaceRecord,
            remove_record: onRemoveRecord,
            remove_all: onRemoveAllRecords,
            reveal_in_pim: onRevealInPim,
        }
        if (!(action in actions)) {
            console.log(`onRecordFieldAction: unhandled action '${action}'`)
            return
        }
        RecordFieldContextMenu.hide()
        actions[action](linkedrecord, otherrecordgid)
    }

    const onDrop = (dragitem, linkedrecord, isoverzone) => {
        const otherrecordgid = dragitem.id
        const otherfield = dragitem.fieldgid ? data.fields.get(dragitem.fieldgid) : null
        const linkedrecordgid = linkedrecord ? linkedrecord.gid : null
        let before_recordgid = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_recordgid = linkedrecordgid
        } else {
            // before_recordid is record -after- linkedrecordid
            let next = false
            for (let gid of linkedrecordgids) {
                if (gid === otherrecordgid) {
                    continue
                }
                if (next) {
                    before_recordgid = gid
                    break
                }
                if (gid === linkedrecordgid) next = true
            }
        }
        const before_record_proxy = before_recordgid ? { gid: before_recordgid } : null

        if (!otherfield) {
            // dragged from -somewhere-, not a field, so just add
            onAddRecord(before_record_proxy, otherrecordgid)
        } else if (otherfield.gid !== field.gid) {
            // moved from other field, copy here, don't remove from other field
            onAddRecord(before_record_proxy, otherrecordgid)
        } else {
            // moved inside field
            const updatedgids = moveListValue(
                linkedrecordgids,
                otherrecordgid,
                before_recordgid
            )
            setAndCommitField(updatedgids)
        }
    }

    const onShowRecordFieldMenu = (e, linkedrecord) => {
        setSelectedRecord(linkedrecord)
        RecordFieldContextMenu.onShow(e)
    }

    const onClickShowRecordFieldMenu = (e, linkedrecord) => {
        if (enabled || !inNavigationTile) {
            onShowRecordFieldMenu(e, linkedrecord)
        }
    }

    const icontileanchor = componentstyle === 'item' ? 'start' : 'center'
    const iconplaceholder = enabled ? 'plus' : 'product'

    const pad = componentstyle === 'card' ? 16 : 6
    const transform = new Transform('fit', tileWidth - pad, tileWidth - pad)

    let Tiles
    const dragdropdirection = tilesperrow === 1 ? 'vertical' : 'horizontal'
    if (linkedrecords.length && linkedrecordgids.length) {
        Tiles = linkedrecordgids
            .filter(gid => gid.trim().length > 0)
            .map((gid, index) => {
                const linkedrecord = linkedrecords[index]
                if (!linkedrecord) return null
                const selected =
                    selectedRecord && selectedRecord.gid === linkedrecord.gid
                const classes = selected ? ' cc-selected' : ''
                const RecordView =
                    componentstyle === 'card' ? (
                        <RecordCard
                            record={linkedrecord}
                            className={classes}
                            transform={transform}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowRecordFieldMenu(e, linkedrecord)}
                            onContextMenu={e => onShowRecordFieldMenu(e, linkedrecord)}
                        />
                    ) : componentstyle === 'thumb' ? (
                        <RecordThumb
                            key={index}
                            record={linkedrecord}
                            transform={transform}
                            className={classes}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowRecordFieldMenu(e, linkedrecord)}
                            onContextMenu={e => onShowRecordFieldMenu(e, linkedrecord)}
                        />
                    ) : (
                        <RecordItem
                            key={index}
                            record={linkedrecord}
                            className={classes}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowRecordFieldMenu(e, linkedrecord)}
                            onContextMenu={e => onShowRecordFieldMenu(e, linkedrecord)}
                        />
                    )
                const tileclasses = 'ws-tile status-' + linkedrecord.status
                return (
                    <DragDropLinkedRecord
                        key={index}
                        field={field}
                        linkedrecordgids={linkedrecordgids}
                        linkedrecord={linkedrecord}
                        direction={dragdropdirection}
                        disabled={!enabled}
                        onDrop={onDrop}
                    >
                        <div className={tileclasses} style={{ width: tileWidth }}>
                            {RecordView}
                        </div>
                    </DragDropLinkedRecord>
                )
            })
    }

    if (enabled) {
        if (!Tiles) {
            Tiles = (
                <DragDropLinkedRecord
                    field={field}
                    linkedrecordgids={linkedrecordgids}
                    onDrop={onDrop}
                >
                    <div className="ws-tile" style={{ width: tileWidth }}>
                        <IconTile
                            width={32}
                            className="cc-dimmed"
                            anchor={icontileanchor}
                            icon={iconplaceholder}
                            onClick={e => onShowRecordFieldMenu(e, null)}
                        />
                    </div>
                </DragDropLinkedRecord>
            )
        } else {
            Tiles.push(
                <DragDropLinkedRecord
                    key="add"
                    field={field}
                    linkedrecordgids={linkedrecordgids}
                    onDrop={onDrop}
                >
                    <div className="ws-tile" style={{ width: tileWidth }}>
                        <IconTile
                            width={32}
                            className="cc-dimmed"
                            anchor={icontileanchor}
                            icon={iconplaceholder}
                            onClick={e => onShowRecordFieldMenu(e, null)}
                        />
                    </div>
                </DragDropLinkedRecord>
            )
        }
    }

    return (
        <div>
            <TilesView
                ref={containerRef}
                columns={tilesperrow}
                className={classes}
                {...other}
            >
                <RecordFieldContextMenu.Panel
                    record={selectedRecord}
                    enabled={enabled}
                    list={true}
                    onAction={onRecordFieldAction}
                />
                {Tiles}
            </TilesView>
            <ValidationMessage validation={validation} />
        </div>
    )
})
