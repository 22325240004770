//
// LayoutData
//
// A plain LayoutData object to hold properties

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'
import { test_empty } from '../../utils/utils'

export class LayoutData {
    gid = null
    original = null
    name = ''
    root = ''
    components = new Map()
    gid_map = new Map()
    is_new = false
    is_working_copy = false

    _modifications = new Set()

    constructor(layoutdata, rootstore) {
        makeObservable(this, {
            original: observable,
            name: observable,
            root: observable,
            components: observable,
            gid_map: observable,
            renamed_from: computed,
            // has_modified: computed,
            is_working_copy: observable,
            is_new: observable,
            update: action.bound,
            setName: action.bound,
            setRoot: action.bound,
            updateComponent: action.bound,
            commitIfModified: action.bound,
        })

        this._rootstore = rootstore
        this.update(layoutdata)
    }

    update = layoutdata => {
        updateInstanceWithData(this, layoutdata, [
            'gid',
            'original',
            'name',
            'root',
            'components',
            'gid_map',
            'is_new',
            'is_working_copy',
        ])
    }

    get renamed_from() {
        if (!this.is_working_copy) return null
        if (this.is_new) return null
        const original = this._rootstore.data.layouts.get(this.original)
        if (!original || original.name === this.name) return null
        return original.name
    }

    has_modified = visited_gids => {
        if (visited_gids === undefined) visited_gids = []
        visited_gids.push(this.gid)
        for (const component of this.components.values()) {
            if (component.type === 'layout' && component.layout) {
                const sublayout = this._rootstore.data.layouts.get(component.layout)
                if (!sublayout) continue
                if (visited_gids.includes(sublayout.gid)) {
                    continue
                }
                if (sublayout.is_working_copy || sublayout.has_modified(visited_gids)) {
                    return true
                }
            }
        }
        return false
    }

    setName = value => {
        const previous_value = this.name
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        this.name = value
        this._modifications.add('name')
        return true
    }

    setRoot = value => {
        const previous_value = this.root
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        this.root = value
        this._modifications.add('root')
        return true
    }

    updateComponent = component => {
        const previous_value = this.components[component.gid]
        if (previous_value === component) return false
        if (test_empty(previous_value) && test_empty(component)) return false

        this.components[component.gid] = component
        this._modifications.add('components')
        return true
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (!this._modifications.size) {
            return Promise.resolve(true)
        }

        let updateparams = { layout: this.gid }
        for (const fieldname of this._modifications.keys()) {
            updateparams[fieldname] = this[fieldname]
        }
        // basically fire & forget updates
        return this._rootstore._fetch('/layouts/modify', updateparams).then(result => {
            this._modifications = new Set()
            return result
        })
    }
}
