//
// TextlistFieldOptions
//
// Field options for type 'textlist'

import React from 'react'
import { observer } from 'mobx-react-lite'

import { duckField } from '../../../../stores/data/validators'
import { InitialFieldOption } from './InitialFieldOption'
import { ListFieldOptions } from './ListFieldOptions'
import { SelectFieldOptions } from './SelectFieldOptions'

export const TextlistFieldOptions = observer(function TextlistFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    if (!field) return null
    const values = field.options.get('values')
    const value_labels = field.options.get('value_labels')
    const restrict_to_values = field.options.get('restrict_to_values')

    const duckInitialField = duckField('textline', {
        values: values,
        value_labels: value_labels,
        restrict_to_values: restrict_to_values,
    })

    return (
        <>
            <InitialFieldOption
                field={field}
                initialField={duckInitialField}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
            <SelectFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
            <ListFieldOptions
                field={field}
                selectModified={selectModified}
                worksheet={worksheet}
                onAction={onAction}
                setSelected={setSelected}
                renderkeyprefix={renderkeyprefix}
            />
        </>
    )
})
