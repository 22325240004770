//
// BarcodeField
//
// A barcode field with text-input and optional preview.

import React, { useState, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useElementWidth } from '../../../../hooks/useElementWidth'

import { TextInput } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { autoCompleter } from '../../../../stores/data/autocompleters'
import { Transform } from '../../../../stores/imageservers/utils'
import { FieldPreview } from '../../../../panels'

export const BarcodeField = observer(function BarcodeField(props) {
    const {
        className,
        record,
        field,
        fromClass,
        component,
        enabled,
        force_readonly,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    const [containerRef, containerWidth] = useElementWidth(160)
    // useState so it is not a 'new' const for each render, triggering a refetch
    const [transform] = useState(new Transform('width', Math.min(160, containerWidth)))

    let classes = 'cc-Field cc-BarcodeField ws-text'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    const multiline = field.is_multiline

    const validator = fieldvalue => {
        return validateField(fieldvalue, field, record.language)
    }

    const fieldAutoCompleter = autoCompleter(field, record.language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    const componentstyle = component && component.style ? component.style : 'preview' // or "plaintext"

    const onChange = event => {
        record.setField(field, event.target.value)
    }
    const onBlur = event => {
        if (
            validateField(event.target.value, field, record.language).result ===
            VALIDATION.ERROR
        ) {
            record.resetField(field)
        } else {
            record.setField(field, event.target.value.trim())
            record.commitIfModified()
        }
    }

    const Preview =
        componentstyle === 'preview' &&
        validateField(fieldvalue, field, record.language).result ===
            VALIDATION.SUCCESS ? (
            <FieldPreview record={record} field={field} transform={transform} />
        ) : undefined

    return (
        <div ref={containerRef} className={classes} {...other}>
            <TextInput
                multiline={multiline}
                enabled={enabled}
                value={fieldvalue}
                onChange={onChange}
                onBlur={onBlur}
                renderkey={renderkey}
                validate={validator}
                autoComplete={autoComplete}
            />
            {Preview}
        </div>
    )
})
