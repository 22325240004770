//
// TextListField
//
// A list of text-inputs.

import React, { useState, useRef, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { DragDropClasslistFieldRow } from '../../../../dragdrop/DragDropClasslistFieldRow'

import { TextListMenu } from '../../../../menus'

import { VALIDATION } from '../../../../utils/validation'
import { validateField, duckField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'
import { autoCompleter } from '../../../../stores/data/autocompleters'
import {
    addIndexedListValue,
    replaceIndexedListValue,
    moveIndexedListValue,
    removeIndexedListValue,
} from '../../../../utils/list'
import { keyboard } from '../../../../utils/keyboard'

import { Icon, TextInput } from '../../../../components'

export const TextListField = observer(function TextListField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    const { app } = useStore()
    const containerRef = useRef()

    const TextListContextMenu = useMousePopover(TextListMenu)
    const [focusItem, setFocusItem] = useState([null, 'end'])

    let classes = 'cc-Field cc-TextListField ws-table'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
            ? record.localized_fields.get(field.name)
            : []

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const duckLineField = duckField('textlistline', {
        values: field.options.get('values'),
        restrict_to_values: field.options.get('restrict_to_values'),
    })
    const lineValidator = linevalue => {
        return validateField(linevalue, duckLineField, record.language)
    }

    const fieldAutoCompleter = autoCompleter(field, record.language)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    const [selectedItemIndex, setSelectedItemIndex] = useState(null) // an index
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    const onAddItemBefore = before_index => {
        const newValue = addIndexedListValue(
            record.localized_fields.get(field.name),
            '',
            before_index
        )
        record.setField(field, newValue)
        record.commitIfModified()
        if (before_index === null) {
            setFocusItem([newValue.length - 1, 'start'])
        } else {
            setFocusItem([before_index, 'start'])
        }
    }

    const onRemoveItem = index => {
        const newValue = removeIndexedListValue(
            record.localized_fields.get(field.name),
            index
        )
        record.setField(field, newValue)
        record.commitIfModified()
        setFocusItem([index - 1, 'end'])
    }

    const onRemoveAllItems = () => {
        const newValue = []
        record.setField(field, newValue)
        record.commitIfModified()
        setFocusItem([null, 'end'])
    }

    const onTextListAction = action => {
        const actions = {
            add_item: onAddItemBefore,
            remove_item: onRemoveItem,
            remove_all: onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onTextListAction: unhandled action '${action}'`)
            return
        }
        TextListContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }
        const newValue = moveIndexedListValue(
            record.localized_fields.get(field.name),
            other_index,
            before_index
        )
        record.setField(field, newValue)
        record.commitIfModified()
        setFocusItem([null, 'end'])
    }

    const onChange = (index, newItemValue) => {
        const newValue = replaceIndexedListValue(
            record.localized_fields.get(field.name),
            newItemValue,
            index
        )
        record.setField(field, newValue)
    }

    const onBlur = (index, newItemValue) => {
        const newValue = replaceIndexedListValue(
            record.localized_fields.get(field.name),
            newItemValue,
            index
        )
        if (
            validateField(newValue, field, record.language).result === VALIDATION.ERROR
        ) {
            //record.resetField(field)
            console.log('error')
        } else {
            record.setField(field, newValue)
            record.commitIfModified()
        }
    }

    const onUpdateAddAfter = (index, newItemValue) => {
        const newValue = addIndexedListValue(
            replaceIndexedListValue(
                record.localized_fields.get(field.name),
                newItemValue,
                index
            ),
            '',
            index + 1
        )
        record.setField(field, newValue)
        record.commitIfModified()
        setFocusItem([index + 1, 'start'])
    }

    const onShowTextListMenu = (e, index) => {
        if (index === null) {
            onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        TextListContextMenu.onShow(e)
    }

    let TableRows
    if (fieldvalue.length) {
        TableRows = fieldvalue.map((line, index) => {
            let cellclasses = 'ws-table-cell ws-table-cell-text'
            const _onChange = event => {
                onChange && onChange(index, event.target.value)
            }
            const _onBlur = event => {
                onBlur && onBlur(index, event.target.value.trim())
            }
            const _onKeyDown = event => {
                if (keyboard.test(event, keyboard.ENTER)) {
                    onUpdateAddAfter(index, event.target.value.trim())
                } else if (
                    (!event.target.value.length || event.target.value === '\n') &&
                    keyboard.test(event, keyboard.BACKSPACE)
                ) {
                    onRemoveItem(index)
                }
            }
            const inputrenderkey = renderkey + index.toString()

            let Input = (
                <TextInput
                    multiline={false}
                    enabled={enabled}
                    setFocus={index === focusItem[0] ? focusItem[1] : false}
                    value={line}
                    language={record.language}
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onKeyDown={_onKeyDown}
                    renderkey={inputrenderkey}
                    autoComplete={autoComplete}
                    validate={lineValidator}
                    {...other}
                />
            )

            return (
                <DragDropClasslistFieldRow
                    key={
                        'field' +
                        field.gid +
                        '.' +
                        fieldvalue.length +
                        '.' +
                        index.toString()
                    }
                    className={classes}
                    field={field}
                    item_index={index}
                    direction="vertical"
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    {enabled ? (
                        <td
                            key={'action'}
                            className="ws-table-cell ws-table-cell-_action_"
                            onClick={e => onShowTextListMenu(e, index)}
                            onContextMenu={e => onShowTextListMenu(e, index)}
                        >
                            <Icon size="text" name="ellipsis" />
                        </td>
                    ) : undefined}
                    <td className={cellclasses}>{Input}</td>
                    {enabled ? (
                        <td
                            key={'handle'}
                            className="ws-table-cell ws-table-cell-_action_"
                        >
                            <Icon size={1} name="reorder" />
                        </td>
                    ) : undefined}
                </DragDropClasslistFieldRow>
            )
        })
    }

    if (enabled) {
        let classes = 'ws-table-cell cc-dimmed'
        const AddTableRow = (
            <tr key={'field' + field.gid + '.add'}>
                <td className={classes + ' ws-table-cell-_action_'}>
                    <Icon size={1} name="plus" onClick={e => onAddItemBefore(null)} />
                </td>
                <td
                    colSpan={2}
                    className={classes + ' ws-table-cell-_add-text_'}
                    style={{ textAlign: 'left' }}
                >
                    <div onClick={e => onAddItemBefore(null)}>
                        {app.text('Add new item')}
                    </div>
                </td>
            </tr>
        )

        if (!TableRows) {
            TableRows = AddTableRow
        } else {
            TableRows.push(AddTableRow)
        }
    }

    return (
        <div>
            <TextListContextMenu.Panel onAction={onTextListAction} />
            <div className={classes} {...other}>
                <table className="ws-table">
                    <tbody ref={containerRef}>{TableRows}</tbody>
                </table>
            </div>
            <ValidationMessage validation={validation} />
        </div>
    )
})
