//
// KeyValueListField
//
// A textlist-input for a field where you can select from options.

import React from 'react'
import { observer } from 'mobx-react-lite'

import { KeyValueListInput } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField, duckField } from '../../../../stores/data/validators'

export const KeyValueListField = observer(function KeyValueListField(props) {
    const {
        className,
        record,
        field,
        fromClass,
        component,
        enabled,
        force_readonly,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    let classes = 'cc-Field cc-TextListfield cc-KeyValueListField ws-text'
    if (!enabled) classes += ' cc-disabled'

    if (className) classes += ' ' + className
    let fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name + '/key')
    if (fieldvalue === undefined) {
        fieldvalue = record.localized_fields && record.localized_fields.get(field.name)
    }

    const validation = validateField(fieldvalue, field, record.language)

    const duckLineField = duckField('textlistline', {
        values: field.options.get('values'),
        restrict_to_values: field.options.get('restrict_to_values'),
    })
    const lineValidator = linevalue => {
        return validateField(linevalue, duckLineField, record.language)
    }

    const onChangeKeys = newKeys => {
        record.setFieldKey(field, newKeys)
    }

    const onBlurKeys = newKeys => {
        if (
            validateField(newKeys, field, record.language).result === VALIDATION.ERROR
        ) {
            record.resetField(field)
        } else {
            record.setFieldKey(field, newKeys)
            record.commitIfModified()
        }
    }

    return (
        <div className={classes} {...other}>
            <KeyValueListInput
                enabled={enabled}
                listvalue={fieldvalue}
                values={field.options.get('values')}
                hasLabels={field.options.get('value_labels')}
                restrictToValues={field.options.get('restrict_to_values')}
                language={worksheet.language}
                onChangeKeys={onChangeKeys}
                onBlurKeys={onBlurKeys}
                validateLine={lineValidator}
                validation={validation}
            />
        </div>
    )
})
