//
// LabeledField
//
// A wrapper for fields, adds a label, and clicking the LabeledField selects this
// field in the worksheet.

import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { Icon } from '../../../../components'
import { NavigationTileContext } from '../layoutbuilders/TilesLayoutBuilder'

export const LabeledField = observer(function LabeledField(props) {
    var {
        className,
        fieldgid,
        editable,
        hide_editable_icon,
        fromClass,
        label,
        children,
        fieldview,
        worksheet,
        visited_gids,
        ...other
    } = props

    const inNavigationTile = useContext(NavigationTileContext)
    const { view, data } = useStore()
    const field = data.fields.get(fieldgid)
    const readonlyIcon =
        hide_editable_icon || editable ? undefined : (
            <Icon className="cc-inline" name="lock" size={'text'} />
        )

    const languagelabel =
        field && field.is_localized ? (
            <span className="language">{view.environment.get('language')}</span>
        ) : undefined

    var classes = 'ws-field'
    if (inNavigationTile) classes += ' ws-navigation-tile'
    if (className) classes += ' ' + className
    if (fieldgid === worksheet.selected.property.fieldgid) classes += ' ws-selected'
    return (
        <div
            className={classes}
            {...other}
            onClick={() => {
                if (inNavigationTile) return
                worksheet.setSelected(
                    worksheet.record,
                    null,
                    fieldgid,
                    fromClass ? fromClass.gid : null,
                    fieldview
                )
                worksheet.layoutstore.selectComponent(null)
            }}
        >
            <div className="ws-label">
                <span className="label">{label}</span>
                {languagelabel}
                {readonlyIcon}
            </div>
            {children}
        </div>
    )
})
