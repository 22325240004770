//
// DamWorksheetStore
//
// The DamWorksheetStore handles interaction with the Dam Worksheet. This means access
// to the current asset, the path to the root, and the direct children.
//
// SIDE-EFFECT: when the current asset changes, we update the damtree with that same
//              asset, so it is visible and selected

import {
    makeObservable,
    observable,
    computed,
    action,
    reaction,
    runInAction,
} from 'mobx'

export class DamWorksheetStore {
    __init = false
    _key = null
    _rootstore = null
    environment = null
    asset = null
    path = null
    children = null
    topindex = null
    windowsize = null
    totalsize = null
    scrollToTop = false

    constructor(key, rootstore, environment) {
        makeObservable(this, {
            __init: observable,
            _key: observable,
            _rootstore: observable,
            environment: observable,

            asset: observable,
            path: observable,
            children: observable,
            topindex: observable,
            windowsize: observable,
            totalsize: observable,
            scrollToTop: observable,

            language: computed,
            fetch: action.bound,
            refetch: action.bound,
            _fetch: action.bound,
            setAsset: action.bound,

            syncdataitem_callback: action.bound,
        })

        this._key = key
        this._rootstore = rootstore
        this.environment = environment

        this.asset = null
        this.path = []
        this.children = []
        this.topindex = 0
        this.windowsize = 10
        this.totalsize = 0
        this.scrollToTop = false

        this._syncdebounce = null
        this._syncdelay = 100
    }

    loadOnce = () => {
        if (this.__init) return
        const assetgid = this._rootstore.view.loadPerProject(this._key + '/rec')
        this.topindex = this._rootstore.view.loadPerProject(this._key + '/topindex', 0)
        this.windowsize = this._rootstore.view.loadPerProject(
            this._key + '/windowsize',
            10
        )
        this.fetch(assetgid, this.topindex, this.windowsize).then(() => {
            if (this.asset) {
                this._rootstore.view._damtree.setAsset(this.asset)
            }
            reaction(
                () => this.asset,
                asset => {
                    if (asset) {
                        this._rootstore.view._damtree.setAsset(asset)
                    }
                }
            )
        })
        this._rootstore.api.register_syncdataitem_callback(this.syncdataitem_callback)
        this.__init = true
    }

    fetch(assetgid, topindex, windowsize) {
        if (
            this.asset &&
            assetgid === this.asset.gid &&
            this.topindex === topindex &&
            this.windowsize === windowsize
        ) {
            return Promise.resolve(true) // nothing to fetch
        }
        if (topindex !== undefined) this.topindex = topindex
        if (windowsize !== undefined) this.windowsize = windowsize
        this._rootstore.view.savePerProject(this._key + '/topindex', this.topindex)
        this._rootstore.view.savePerProject(this._key + '/windowsize', this.windowsize)
        return this._fetch(assetgid, this.topindex, this.windowsize)
    }

    refetch() {
        if (!this.asset) {
            return Promise.resolve(true) // nothing to fetch
        }
        return this._fetch(this.asset.gid, this.topindex, this.windowsize)
    }

    _fetch(assetgid, topindex, windowsize) {
        if (!assetgid) {
            this._rootstore.view.savePerProject(this._key + '/rec', null)
            return Promise.resolve(true) // nothing to fetch
        }
        return this._rootstore
            ._fetch('/assets/worksheet', {
                asset: assetgid,
                'page.top': topindex,
                'page.size': windowsize,
            })
            .then(result => {
                runInAction(() => {
                    // and use it to populate worksheet items?
                    this.asset = this._rootstore.data.assets.get(result['asset'])
                    this.path.replace(
                        result['path'].map(gid => this._rootstore.data.assets.get(gid))
                    )
                    this.children.replace(
                        result['children'].map(gid =>
                            this._rootstore.data.assets.get(gid)
                        )
                    )
                    const thisassetgid = this.asset ? this.asset.gid : null
                    this._rootstore.view.savePerProject(
                        this._key + '/rec',
                        thisassetgid
                    )
                    this.totalsize = result['children.size']
                })
                return result
            })
            .catch(error => {})
    }

    setAsset(asset) {
        // this is external; when we select an item, we need to make sure it appears in
        // the worksheet, and reset the selection
        this.fetch(asset ? asset.gid : null, 0, this.windowsize).then(() => {})
        this.scrollToTop = true
    }

    get language() {
        if (!this.environment) return null
        return this.environment.get('language')
    }

    schedule_refetch = () => {
        if (this._syncdebounce) {
            clearTimeout(this._syncdebounce)
            this._syncdebounce = null
        }
        this._syncdebounce = setTimeout(() => {
            this.refetch()
            this._syncdebounce = null
        }, this._syncdelay)
    }

    syncdataitem_callback = (syncdataitem, data_before, data_after) => {
        // console.log('DamWorksheetStore sync', syncdataitem, data_before, data_after)
        // we're only interested in data_type "assets"
        // then, we're only interested in updates (of childcount) and deletions
        // for updated assets, only for self, when childcount has changed
        // for deletes, only for self -> select non-deleted asset from parents
        if (syncdataitem['data_type'] !== 'assets') return
        if (!this.asset) return
        if (syncdataitem['action'] === 'UPDATE') {
            if (syncdataitem['data_key'] !== this.asset.gid) return
            if (data_before.childcount !== data_after.childcount) {
                this.schedule_refetch()
                return
            }
        } else if (syncdataitem['action'] === 'DELETE') {
            // file deleted from current folder
            if (data_before && data_before.path === this.asset.pathname) {
                this.schedule_refetch()
                return
            }
            // this file deleted
            if (syncdataitem['data_key'] !== this.asset.gid) return
            const path = [...this.path]
            path.reverse()
            for (const crumb of path) {
                if (this._rootstore.data.assets.has(crumb.gid)) {
                    this.setAsset(crumb)
                    return
                }
            }
        } else if (syncdataitem['action'] === 'INSERT') {
            // file inserted into current folder
            if (data_after.path === this.asset.pathname) {
                this.schedule_refetch()
                return
            }
        }
    }
}
