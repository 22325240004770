//
// UnicatApi
//
// Connect to the backend. Handles auth with JWT.
//
// API is JSON based, where we send all parameters as a POST body in JSON, and all
// responses (except http status ones) are in JSON too. If we make a call without
// parameters, we'll do a GET.
//
// Response JSON should always be in the form
//  { "success": true | false,
//    "result": info | error,
//    "data": {…} | null,
//  }
// If success is false, data should be null, and result is of the form
//  { "code": 0,                    // any number
//    "message": "Error message",   // a single string
//    "info": {},                   // associated error info, object form defined by
//                                     error code (not really used yet)
//  }
// If success is true, result is dependent on the call, and data contains, well, data.

import { makeObservable, observable, action } from 'mobx'
import { io } from 'socket.io-client'
import { objectFrom } from '../../utils/utils'

const SyncIO = ccapi => {
    const handle_sync_data = async (syncdata = undefined) => {
        // let ccapi handle the actual syncdata if there is any
        if (syncdata === undefined) {
            console.log('-- sync error - auth?')
            return
        }
        if (!syncdata) {
            // console.log('-- nothing to sync')
            return
        }
        await ccapi.handle_sync_data(syncdata)
    }

    let sio = io(undefined, {
        path: '/sync/io/',
        auth: { JWT: null },
        autoConnect: false,
    })

    sio.on('connect', () => {
        sio.emit('initsync', ccapi.initsync_params(), handle_sync_data)
    })
    sio.on('disconnect', () => {})
    sio.on('authorization', data => {
        sio.auth.JWT = data['JWT']
        ccapi._jwt.setToken(data['JWT'])
    })
    sio.on('sync', syncdata => {
        handle_sync_data(syncdata)
    })

    return sio
}

function ResponseException(message, response) {
    this.message = message
    this.response = response
    this.name = 'ResponseException'
}

class UnicatApi {
    _baseurl
    _projectgid
    _jwt
    _datastore
    _sio
    _cc_cursor
    _cursor
    _syncdataitem_callbacks
    open_requests = 0

    constructor(baseurl, projectgid, jwt, rootstore) {
        makeObservable(this, {
            open_requests: observable,

            updateDatastore: action.bound,
            globalfetch: action.bound,
            fetch: action.bound,
            upload: action.bound,
            syncdataitem_callbacks: action.bound,
            _fetch_syncdataitem: action.bound,
            handle_sync_data: action.bound,

            start_sync: action.bound,
        })

        this._baseurl = baseurl
        this._cc_cursor = 0
        this._projectgid = projectgid
        this._cursor = 0
        this._jwt = jwt
        this._rootstore = rootstore
        this._datastore = rootstore.data
        this._syncdataitem_callbacks = []
        this._sio = SyncIO(this)
        this.map_data = {
            'cc.users': this._datastore.users,
            'cc.projects': this._datastore.projects,
            'cc.projects_members': this._datastore.project_members,
            assets: this._datastore.assets,
            classes: this._datastore.classes,
            definitions: this._datastore.definitions,
            fields: this._datastore.fields,
            layouts: this._datastore.layouts,
            queries: this._datastore.queries,
            records: this._datastore.records,
        }
        this.open_requests = 0
    }

    updateDatastore = data => {
        const updaters = {
            'cc.users': this._datastore.addUsers,
            'cc.projects': this._datastore.addProjects,
            'cc.projects_members': this._datastore.addProjectMembers,
            'cc.languages': this._datastore.addLanguages,
            records: this._datastore.addRecords,
            definitions: this._datastore.addDefinitions,
            classes: this._datastore.addClasses,
            fields: this._datastore.addFields,
            layouts: this._datastore.addLayouts,
            assets: this._datastore.addAssets,
            queries: this._datastore.addQueries,
        }
        for (const datatype of [
            // order is important - fields before layouts, classes, definitions, records
            'cc.users',
            'cc.projects',
            'cc.projects_members',
            'cc.languages',
            'fields',
            'layouts',
            'classes',
            'definitions',
            'records',
            'assets',
            'queries',
        ]) {
            if (datatype in data) {
                updaters[datatype](data[datatype])
            }
        }
    }

    async globalfetch(endpoint, data, additional_fetch_options) {
        const url = this._baseurl + endpoint
        let options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }
        if (data !== undefined) {
            options.method = 'POST'
            options.body = JSON.stringify(objectFrom(data))
        }
        if (this._jwt.hasToken) {
            options.headers['Authorization'] = 'Bearer ' + this._jwt.token
        }
        if (additional_fetch_options) {
            options = { ...options, ...additional_fetch_options }
        }
        // console.log('api.fetch', url, {
        //     method: options.method,
        //     data: options.body && JSON.parse(options.body),
        //     headers: options.headers,
        // })
        this.open_requests += 1
        return window
            .fetch(url, options)
            .catch(error => {
                this.open_requests -= 1
                throw error
            })
            .then(response => {
                this.open_requests -= 1
                if (!response.ok) {
                    if (response.status === 401) {
                        this._jwt.invalidateToken()
                    }
                    throw new ResponseException(
                        response.status + ' ' + response.statusText,
                        response.json()
                    )
                }
                if (response.headers.has('Authorization')) {
                    this._jwt.setToken(response.headers.get('Authorization')) // also refreshes
                    this._sio.auth.JWT = this._jwt.token
                }
                return response.json()
            })
            .then(response => {
                this.updateDatastore(response['data'])
                if (!response['success']) {
                    throw new ResponseException(
                        response['result']['code'] +
                            ' ' +
                            response['result']['message'],
                        response
                    )
                }
                if ('files' in response['data']) {
                    response['result']['data/files'] = response['data']['files']
                }
                return response['result']
            })
            .catch(error => {
                if (error === 'Unmounted' || error.name === 'AbortError') {
                    // do nothing, this is working as intended
                } else {
                    throw error
                }
            })
    }

    async fetch(endpoint, data, additional_fetch_options) {
        if (!this._projectgid) {
            return Promise.resolve(false)
        }
        const project_endpoint = '/p/' + this._projectgid + endpoint
        return this.globalfetch(project_endpoint, data, additional_fetch_options)
    }

    async globalupload(endpoint, file, data, additional_fetch_options) {
        const url = this._baseurl + endpoint
        let options = {
            method: 'POST',
            headers: {},
        }
        const formData = new FormData()
        formData.append('upload_file', file)
        for (const name in data) {
            formData.append(name, data[name])
        }
        options.body = formData
        if (this._jwt.hasToken) {
            options.headers['Authorization'] = 'Bearer ' + this._jwt.token
        }
        if (additional_fetch_options) {
            options = { ...options, ...additional_fetch_options }
        }
        // console.log('api.upload', url, {
        //     method: options.method,
        //     file: file.name,
        //     data: data,
        //     headers: options.headers,
        // })
        this.open_requests += 1
        return window
            .fetch(url, options)
            .catch(error => {
                this.open_requests -= 1
                throw error
            })
            .then(response => {
                this.open_requests -= 1
                if (!response.ok) {
                    throw new ResponseException(
                        response.status + ' ' + response.statusText,
                        response
                    )
                }
                if (response.headers.has('Authorization')) {
                    this._jwt.setToken(response.headers.get('Authorization')) // also refreshes
                    this._sio.auth.JWT = this._jwt.token
                }
                return response.json()
            })
            .then(response => {
                this.updateDatastore(response['data'])
                if (!response['success']) {
                    throw new ResponseException(
                        response['result']['code'] +
                            ' ' +
                            response['result']['message'],
                        response
                    )
                }
                if ('files' in response['data']) {
                    response['result']['data/files'] = response['data']['files']
                }
                return response['result']
            })
            .catch(error => {
                if (error === 'Unmounted' || error.name === 'AbortError') {
                    // do nothing, this is working as intended
                } else {
                    throw error
                }
            })
    }

    async upload(endpoint, file, data, additional_fetch_options) {
        if (!this._projectgid) {
            return Promise.resolve(false)
        }
        const project_endpoint = '/p/' + this._projectgid + endpoint
        return this.globalupload(project_endpoint, file, data, additional_fetch_options)
    }

    start_sync() {
        this._sio.connect()
    }

    initsync_params() {
        return {
            'cc.cursor': this._cc_cursor,
            project: this._projectgid,
            cursor: this._cursor,
        }
    }

    unregister_syncdataitem_callbacks() {
        this._syncdataitem_callbacks = []
    }

    register_syncdataitem_callback(callback) {
        this._syncdataitem_callbacks.push(callback)
    }

    syncdataitem_callbacks(syncdataitem, data_before, data_after) {
        for (const callback of this._syncdataitem_callbacks) {
            callback(syncdataitem, data_before, data_after)
        }
    }

    async _fetch_syncdataitem(syncdataitem, data_before) {
        const type_ = syncdataitem['data_type']
        const key = syncdataitem['data_key']
        if (type_ === 'cc.projects_members') {
            const [project, member] = key.split('/')
            let hasError = false
            const returned = await this.globalfetch('/members/get', {
                project: project,
                member: member,
            }).catch(error => {
                hasError = true
            })
            if (hasError) {
                return null
            }
            if (returned) {
                if (!this._datastore.projects.has(project)) {
                    await this.globalfetch('/projects/get', { project })
                }
                const data_after = // shallow copy
                    this.map_data.hasOwnProperty(syncdataitem['data_type']) &&
                    this.map_data[syncdataitem['data_type']].has(
                        syncdataitem['data_key']
                    )
                        ? {
                              ...this.map_data[syncdataitem['data_type']].get(
                                  syncdataitem['data_key']
                              ),
                          }
                        : null
                this.syncdataitem_callbacks(syncdataitem, data_before, data_after)
            }
            return returned[0]
        }
        const map_calls = {
            'cc.users': [this.globalfetch, '/users/get', 'user'],
            'cc.projects': [this.globalfetch, '/projects/get', 'project'],
            assets: [this.fetch, '/assets/get', 'asset'],
            classes: [this.fetch, '/classes/get', 'class'],
            definitions: [this.fetch, '/definitions/get', 'definition'],
            fields: [this.fetch, '/fields/get', 'field'],
            layouts: [this.fetch, '/layouts/get', 'layout'],
            queries: [this.fetch, '/queries/get', 'query'],
            records: [this.fetch, '/records/get', 'record'],
        }
        const map_call = map_calls[type_]
        let data = {}
        data[map_call[2]] = key
        return map_call[0](map_call[1], data)
            .then(() => {
                const data_after = // shallow copy
                    this.map_data.hasOwnProperty(syncdataitem['data_type']) &&
                    this.map_data[syncdataitem['data_type']].has(
                        syncdataitem['data_key']
                    )
                        ? {
                              ...this.map_data[syncdataitem['data_type']].get(
                                  syncdataitem['data_key']
                              ),
                          }
                        : null
                this.syncdataitem_callbacks(syncdataitem, data_before, data_after)
                // update environment for current project
                if (
                    syncdataitem['data_type'] === 'cc.projects' &&
                    this._rootstore.project &&
                    syncdataitem['data_key'] === this._rootstore.project.gid
                ) {
                    this._rootstore.view.updateProjectEnvironment()
                }
            })
            .catch(error => {
                // silently pass, could be deleted or out of sync
                // no problem, usually (no known problems, at least :-))
            })
    }

    async handle_sync_data(syncdatalist) {
        // result contains a list of cursor/action/data_type/data_key
        // handle each one, updating our cursors as we go
        for (const item of syncdatalist) {
            // console.log(
            //     'sync',
            //     [this._cc_cursor, this._cursor],
            //     item['type'],
            //     item['cursor'],
            //     item['action'],
            //     item['data_type'],
            //     item['data_key'],
            //     item['data']
            // )

            // skip lagging syncs (older than our latest cursors)
            if (item['data_type'] !== 'jobs') {
                if (item['type'] === 'cc') {
                    if (this._cc_cursor >= item['cursor']) continue
                } else {
                    if (this._cursor >= item['cursor']) continue
                }
            }

            const data_before = // shallow copy
                this.map_data.hasOwnProperty(item['data_type']) &&
                this.map_data[item['data_type']].has(item['data_key'])
                    ? { ...this.map_data[item['data_type']].get(item['data_key']) }
                    : null
            // check handling
            if (item['data_type'] === 'cc.version') {
                if (item['data_key'] !== this._rootstore.version) {
                    // alert! version-change mid-program!
                    console.log('Server version changed!')
                    window.location.reload()
                    this._rootstore.version = item['data_key']
                }
            } else if (item['data_type'] === 'jobs') {
                const job = item['data']
                if (
                    job['job'] === 'backup_project' ||
                    job['job'] === 'restore_project'
                ) {
                    this._rootstore.is_busy = true
                }
                if (job['job'] === 'backup_project' && job['status'] === 'queued') {
                    console.log('Server database backup started')
                } else if (
                    job['job'] === 'backup_project' &&
                    job['status'] === 'done'
                ) {
                    console.log('Server database backup done')
                    this._rootstore.is_busy = false
                } else if (
                    job['job'] === 'restore_project' &&
                    job['status'] === 'queued'
                ) {
                    console.log('Server database restore started')
                } else if (
                    job['job'] === 'restore_project' &&
                    job['status'] === 'done'
                ) {
                    console.log('Server database restore done')
                    this._rootstore.is_busy = false
                    window.location.reload()
                }
            } else if (!this.map_data.hasOwnProperty(item['data_type'])) {
                // unknown, skip
            } else if (item['action'] === 'DELETE') {
                if (
                    item['data_type'] === 'cc.projects' &&
                    this._rootstore.project &&
                    item['data_key'] === this._rootstore.project.gid
                ) {
                    this._rootstore.setLocation(this._rootstore.LOCATIONS.ACCOUNT)
                }
                // the fetch will fail because the item is deleted
                // but it is done anyway to avoid race conditions when an item
                // is inserted and deleted soon after (e.g. a modify/commit follow
                // each other closely, like from a script using the API)
                await this._fetch_syncdataitem(item, null)
                // delete also works for items that aren't in our store
                if (this.map_data[item['data_type']].delete(item['data_key'])) {
                    this.syncdataitem_callbacks(item, data_before, undefined)
                }
            } else if (item['action'] === 'INSERT') {
                // we're only interested in inserts that affect our data
                // so project-members for our project should fetch the new
                // membership, but also the new members
                // we're also interested in any base-data for definitions,
                // classes, fields, layouts, and queries
                // NOTE: fetching data auto-updates our local data-store
                if (item['data_type'] === 'cc.projects_members') {
                    const [project_gid, member_gid] = item['data_key'].split('/')
                    const projects = this._datastore.projectsForUser(
                        this._rootstore.user.gid
                    )
                    const project_gids = projects.map(project => project.project_gid)
                    if (
                        project_gids.includes(project_gid) ||
                        member_gid === this._rootstore.user.gid
                    ) {
                        await this._fetch_syncdataitem(item, null)
                    }
                } else if (
                    ['definitions', 'classes', 'fields', 'layouts', 'queries'].includes(
                        item['data_type']
                    )
                ) {
                    await this._fetch_syncdataitem(item, null)
                }
            } else if (item['action'] === 'UPDATE') {
                // we're only interested in data we already have locally
                if (this.map_data[item['data_type']].has(item['data_key'])) {
                    await this._fetch_syncdataitem(item, data_before)
                }
            }
            // always update local cursors
            if (item['type'] === 'cc') {
                this._cc_cursor = item['cursor']
            } else {
                this._cursor = item['cursor']
            }
        }
    }
}

export default UnicatApi
