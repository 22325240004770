//
// TypedField
//
// Return a Field for a field based on type

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { TilesView, VView } from '../../../../appview'
import { Icon, Text } from '../../../../components'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { RecursionMessage } from '.'
import { LayoutBuilder } from '..'

const PreviewCard = observer(function PreviewCard({ icon, format }) {
    let classes = 'ws-preview-field ws-preview-card'
    if (format) classes += ' ws-preview-format-' + format
    return (
        <div className={classes}>
            <div className="ws-preview-item-icon">
                <Icon name={icon} size="fill" />
            </div>
            <div className="ws-preview-item-properties">
                <div className="ws-preview-item-labels">
                    <div
                        className="ws-preview-text"
                        style={{ width: '80%', marginLeft: '20%' }}
                    >
                        &nbsp;
                    </div>
                    <div
                        className="ws-preview-text"
                        style={{ width: '60%', marginLeft: '40%' }}
                    >
                        &nbsp;
                    </div>
                    <div
                        className="ws-preview-text"
                        style={{ width: '70%', marginLeft: '30%' }}
                    >
                        &nbsp;
                    </div>
                </div>
                <div className="ws-preview-item-values">
                    <div className="ws-preview-text" style={{ width: '80%' }}>
                        &nbsp;
                    </div>
                    <div className="ws-preview-text" style={{ width: '90%' }}>
                        &nbsp;
                    </div>
                    <div className="ws-preview-text" style={{ width: '70%' }}>
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    )
})

const PreviewItem = observer(function PreviewItem({ icon, format }) {
    let classes = 'ws-preview-field ws-preview-item'
    if (format) classes += ' ws-preview-format-' + format
    return (
        <div className={classes}>
            <div className="ws-preview-item-icon">
                <Icon name={icon} size="fill" />
            </div>
            <div className="ws-preview-item-text">
                <div className="ws-preview-text" style={{ width: '80%' }}>
                    &nbsp;
                </div>
                <div className="ws-preview-text" style={{ width: '90%' }}>
                    &nbsp;
                </div>
            </div>
        </div>
    )
})

const PreviewThumb = observer(function PreviewThumb({ icon, format }) {
    let classes = 'ws-preview-field ws-preview-thumb'
    if (format) classes += ' ws-preview-format-' + format
    return (
        <div className={classes}>
            <div className="ws-preview-item-icon">
                <Icon name={icon} size="fill" />
            </div>
        </div>
    )
})

const PreviewList = observer(function PreviewList({ tileMinWidth, columns, children }) {
    const repeatedChildren = Array.from({ length: columns }).map(_ => children)
    return (
        <TilesView tileMinWidth={tileMinWidth} columns={columns} gap={4}>
            {repeatedChildren}
        </TilesView>
    )
})

const PreviewRows = observer(function PreviewRows({ count, children }) {
    const repeatedChildren = Array.from({ length: count }).map(_ => children)
    return <VView gap={1}>{repeatedChildren}</VView>
})

const PreviewField = observer(function PreviewField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]
    const icon = ['record', 'recordlist'].includes(field.type)
        ? 'product'
        : ['image', 'imagelist'].includes(field.type)
        ? 'image'
        : 'doc'
    let Preview
    let tileMinWidth = 32
    const componentstyle = component
        ? component.style || config.style.default
        : config.style.default
    const componentformat = config.thumbformat
        ? component
            ? component.thumbformat || config.thumbformat.default
            : config.thumbformat.default
        : undefined
    switch (componentstyle) {
        case 'card':
            Preview = <PreviewCard icon={icon} format={componentformat} />
            tileMinWidth = 80
            break
        case 'item':
            Preview = <PreviewItem icon={icon} format={componentformat} />
            tileMinWidth = 80
            break
        case 'thumb':
        default:
            Preview = <PreviewThumb icon={icon} format={componentformat} />
            break
    }
    const is_list = ['recordlist', 'imagelist', 'filelist'].includes(field.type)
    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    if (is_list) {
        const columns = component
            ? parseInt(component.columns, 10) || config.columns.default
            : config.columns.default
        Preview = (
            <PreviewList tileMinWidth={tileMinWidth} columns={columns}>
                {Preview}
            </PreviewList>
        )
    }
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    return (
        <div className={classes.join(' ')}>
            {title}
            {readonlyIcon}
            {Preview}
        </div>
    )
})

const PreviewBarcodeField = observer(function PreviewBarcodeField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const previewIcon =
        !component || !component.style || component.style === 'preview' ? (
            <Icon className="cc-inline" name="barcode" size={'text'} />
        ) : undefined
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    return (
        <div className={classes.join(' ')}>
            {title}
            {previewIcon}
            {readonlyIcon}
        </div>
    )
})

const PreviewBooleanField = observer(function PreviewBooleanField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    return (
        <div className={classes.join(' ')}>
            {title}
            <Icon className="cc-inline" name={'toggle'} size={'text'} />
            {readonlyIcon}
        </div>
    )
})

const PreviewFieldpickerField = observer(function PreviewFieldpickerField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    return (
        <div className={classes.join(' ')}>
            {title}
            <Icon className="cc-inline" name={'field'} size={'text'} />
            {readonlyIcon}
        </div>
    )
})

const PreviewClassField = observer(function PreviewClassField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
    visited_gids,
}) {
    const { data, view, app } = useStore()
    const language = view.environment.get('language')

    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    if (!class_ || !layout) {
        return <Text>{app.text('No class or layout assigned.')}</Text>
    }

    if (
        visited_gids.includes(class_.gid) ||
        visited_gids.includes(layout.gid) ||
        visited_gids.includes(class_.original) ||
        visited_gids.includes(layout.original)
    ) {
        return <RecursionMessage component={field} />
    }

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        layout.gid,
        layout.original,
        class_.gid,
        class_.original,
    ]

    const ClassFieldLayoutPreview = (
        <LayoutBuilder
            component={layout.components.get(layout.root)}
            components={layout.components}
            layout={layout}
            record={null}
            worksheet={worksheet}
            active_class={class_}
            visited_gids={[
                ...visited_gids,
                ...update_visited_gids.filter(gid => !!gid),
            ]}
        />
    )

    return (
        <div className={classes.join(' ')}>
            {title}
            <Icon className="cc-inline" name={'class'} size={'text'} />
            {readonlyIcon}
            <div className="ws-sublayoutpreview">{ClassFieldLayoutPreview}</div>
        </div>
    )
})

const PreviewClasslistField = observer(function PreviewClasslistField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
    visited_gids,
}) {
    const { data, view, app } = useStore()
    const language = view.environment.get('language')

    const classes = ['ws-layoutpreview', 'ws-preview-field', 'ws-field-' + field.type]
    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    if (!class_ || !layout) {
        return <Text>{app.text('No class or layout assigned.')}</Text>
    }
    let style = component && component.style ? component.style : config.style.default
    if (!['table', 'tiles'].includes(style)) {
        style = config.style.default
    }

    if (
        visited_gids.includes(class_.gid) ||
        visited_gids.includes(layout.gid) ||
        visited_gids.includes(class_.original) ||
        visited_gids.includes(layout.original)
    ) {
        return <RecursionMessage component={field} />
    }
    // return null

    // table style: don't worry about class layout, use each property as a column
    // rows style: use layout, every entry is a new row
    // tiles style: use layout, tiled. # of columns isn't specified for now, auto-
    // determine?

    // for previews, don't propagate not-editable to prevent many lock icons

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        layout.gid,
        layout.original,
        class_.gid,
        class_.original,
    ]

    let ClasslistPreview

    if (style === 'table') {
        ClasslistPreview = (
            <PreviewClasslistTable
                field={field}
                editable={true}
                component={component}
                record={record}
                worksheet={worksheet}
                visited_gids={[...visited_gids]}
            />
        )
    } else {
        const ClasslistFieldLayoutPreview = (
            <LayoutBuilder
                component={layout.components.get(layout.root)}
                components={layout.components}
                layout={layout}
                record={null}
                worksheet={worksheet}
                active_class={class_}
                visited_gids={[
                    ...visited_gids,
                    ...update_visited_gids.filter(gid => !!gid),
                ]}
            />
        )
        if (style === 'rows') {
            ClasslistPreview = (
                <PreviewRows count={1}>{ClasslistFieldLayoutPreview}</PreviewRows>
            )
        } else {
            const columns =
                1 ||
                (component
                    ? parseInt(component.columns, 10) || config.columns.default
                    : config.columns.default)

            ClasslistPreview = (
                <PreviewList minTileWidth={80} columns={columns}>
                    {ClasslistFieldLayoutPreview}
                </PreviewList>
            )
        }
    }

    return (
        <div className={classes.join(' ')}>
            {title}
            <Icon className="cc-inline" name={'class'} size={'text'} />
            {readonlyIcon}
            <div className="ws-sublayoutpreview">{ClasslistPreview}</div>
        </div>
    )
})

const PreviewClasslistTable = observer(function PreviewClasslistTable({
    field,
    editable,
    component,
    record,
    worksheet,
    visited_gids,
}) {
    const { data, view, app } = useStore()
    const language = view.environment.get('language')

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    if (!class_ || !layout) {
        return <Text>{app.text('No class or layout assigned.')}</Text>
    }

    if (
        visited_gids.includes(class_.gid) ||
        visited_gids.includes(layout.gid) ||
        visited_gids.includes(class_.original) ||
        visited_gids.includes(layout.original)
    ) {
        return <RecursionMessage component={field} />
    }
    // return null

    const classfields = class_.fields.map(field_gid => data.fields.get(field_gid))
    const listitems = [{ gid: 1 }] //{ gid: 2 }, { gid: 3 }]

    const Headercells = classfields.map(classfield => {
        let classes = 'ws-table-cell ws-table-cell-' + classfield.name
        const label =
            (classfield.label && classfield.label.get(language)) || classfield.name
        return (
            <td key={'field' + classfield.gid} className={classes}>
                <Text>{label}</Text>
            </td>
        )
    })
    const Header = <tr className="ws-table-header">{Headercells}</tr>

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        layout.gid,
        layout.original,
        class_.gid,
        class_.original,
    ]

    const Rows = listitems.map(listitem => {
        const Cells = classfields.map(classfield => {
            const value = 'x'
            let classes =
                'ws-table-cell ws-table-cell-' + classfield.name + ' layout-component'
            const Field =
                value === undefined ? (
                    <Text>
                        {app.text("Undefined field '{fieldname}'", {
                            fieldname: classfield.name,
                        })}
                    </Text>
                ) : (
                    <TypedField
                        field={classfield}
                        fromClass={null}
                        component={null}
                        editable={editable}
                        record={listitem}
                        worksheet={worksheet}
                        visited_gids={[
                            ...visited_gids,
                            ...update_visited_gids.filter(gid => !!gid),
                        ]}
                    />
                )
            return (
                <td key={'field' + classfield.gid} className={classes}>
                    {Field}
                </td>
            )
        })
        return (
            <tr className="ws-table-row" key={listitem.gid}>
                {Cells}
            </tr>
        )
    })

    return (
        <table className="ws-table">
            <tbody>
                {Header}
                {Rows}
            </tbody>
        </table>
    )
})

export const TypedField = observer(function TypedField({
    field,
    fromClass,
    editable,
    component,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const { view } = useStore()
    const language = view.environment.get('language')

    if (!field) {
        return null
    }
    // let renderkey = field.gid + "." + field.type
    // if (field.options) {
    //     renderkey = renderkey + "." + hashData(field.options)
    // }

    let classes = ['ws-layoutpreview', 'ws-text', 'ws-field-' + field.type]

    const title = worksheet.layoutstore.getItemTitle(field, language)
    const readonlyIcon = editable ? undefined : (
        <Icon className="cc-inline" name="lock" size={'text'} />
    )

    switch (field.type) {
        case 'record':
        case 'image':
        case 'file':
        case 'recordlist':
        case 'imagelist':
        case 'filelist':
            return (
                <PreviewField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'barcode':
            return (
                <PreviewBarcodeField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'fieldpicker':
            return (
                <PreviewFieldpickerField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'class':
            return (
                <PreviewClassField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'classlist':
            return (
                <PreviewClasslistField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'boolean':
            return (
                <PreviewBooleanField
                    editable={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    worksheet={worksheet}
                    visited_gids={visited_gids}
                />
            )
        case 'text':
        case 'textline':
        case 'textlist':
        case 'number':
        case 'decimal':
        case 'code':
        default:
            return (
                <div className={classes.join(' ')}>
                    {title}
                    {readonlyIcon}
                </div>
            )
    }
})
