//
// Icons lab
//
// Show all available icons in all 5 sizes, and a fullsized one.

import React from 'react'

import { VView, HView, TilesView, Spacer } from '../../appview'
import { Icon } from '../../components'

export const Icons = () => {
    const icons = [
        ['unicat', 'Unicat logo icon'],
        ['settings', 'Settings workspace'],
        ['schema', 'Schema workspace'],
        ['product', 'Catalog workspace'],
        ['book', 'Book or catalog'],
        ['image', 'Assets workspace or Layout: Image'],
        ['importexport', 'Import/Export workspace'],
        ['backup', 'Backup and restore'],
        ['lab', 'Experimental workspace'],
        ['user', 'A user without an avatar'],
        ['badge', 'Project membership badge'],
        ['key', 'API keys'],
        ['selection', 'Selection'],
        ['link', 'Linked record'],
        ['refresh', 'Refresh'],
        ['reorder', 'Re-order'],
        ['show', 'Show password'],
        ['hide', 'Hide password'],
        ['channels', 'Channels'],
        ['barcode', 'Barcode'],
        ['toggle', 'Toggle'],
        ['lock', 'Read-only lock'],
        ['tree', 'Tree view'],
        ['search', 'Search'],
        ['bookmark', 'Bookmark'],
        ['bookmarkshare', 'Share bookmark'],
        ['filter', 'Filter'],
        ['layout', 'Layout'],
        ['vertical', 'Layout: Vertical'],
        ['horizontal', 'Layout: Horizontal'],
        ['spacer', 'Layout: Spacer'],
        ['text', 'Layout: Text'],
        ['divider', 'Layout: Divider'],
        ['table', 'Layout: Table'],
        ['rows', 'Layout: Rows'],
        ['tiles', 'Layout: Tiles'],
        ['definition', 'Definition'],
        ['class', 'Class'],
        ['field', 'Field or Layout: Field'],
        ['fieldlist', 'Layout: Fieldlist'],
        ['ellipsis', 'More actions or items available'],
        ['folder', 'Folder'],
        ['doc', 'Document'],
        ['zip', 'Zipped asset'],
        ['movie', 'Movie asset'],
        ['pin', 'Pinboard'],
        ['plus', 'Create'],
        ['plusbutton', 'Add filter'],
        ['remove', 'Remove'],
        ['check', 'Checkmark'],
        ['checkrow', 'Item found'],
        ['xrow', 'Item not found'],
        ['delete', 'Delete'],
        ['navigate', 'Follow navigation'],
        ['empty', 'empty icon used for alignment'],
        ['expanded', 'Tree view, expanded item. Also doubles as select dropdown arrow'],
        ['collapsed', 'Tree view, collapsed item'],
        ['bullet', 'Tree view, childless item'],
        ['focus', 'Bullet with focus ring, eg. working copy of a field'],
        ['sandbox', 'Sandbox'],
        ['oops', 'Bananana of Oops'],
        ['none', 'placeholder used when no name or no known name is given'],
        ['', 'no name given'],
        ['adshaasdsrtkjgdas', "there's no icon by this name"],
    ]
    const sizes = ['text', 1, 2, 3, 4, 5, 80]
    const AllIcons = icons.map(([icon, comment]) => (
        <div key={icon} style={{ verticalAlign: 'middle', marginBottom: 20 }}>
            {sizes.map(size => (
                <div
                    key={size}
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        marginRight: 4,
                    }}
                >
                    <Icon name={icon} size={size} />
                    <br />
                    {size}
                </div>
            ))}
            <div
                style={{
                    display: 'inline-block',
                    width: 200,
                    verticalAlign: 'middle',
                    marginLeft: 10,
                }}
            >
                <span style={{ fontWeight: '600' }} className="user-select">
                    {icon}
                </span>
                <br />
                {comment}
            </div>
        </div>
    ))

    const TiledIcons = icons.map(([icon, comment]) => {
        if (['none', '', 'adshaasdsrtkjgdas'].includes(icon)) return null
        return (
            <div
                key={icon}
                title={icon}
                style={{
                    textAlign: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Icon name={icon} size={2} />
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    className="user-select"
                >
                    {icon}
                </div>
            </div>
        )
    })

    const TiledActiveIcons = icons.map(([icon, comment]) => {
        if (['none', '', 'adshaasdsrtkjgdas'].includes(icon)) return null
        return (
            <div
                key={icon}
                title={icon}
                style={{
                    textAlign: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <div className="cc-Button cc-active">
                    <Icon name={icon} size={2} />
                </div>
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    className="user-select"
                >
                    {icon}
                </div>
            </div>
        )
    })

    const TiledIconsSize5 = icons.map(([icon, comment]) => {
        if (['none', '', 'adshaasdsrtkjgdas'].includes(icon)) return null
        return (
            <div
                key={icon}
                title={icon}
                style={{
                    textAlign: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <Icon name={icon} size={5} />
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    className="user-select"
                >
                    {icon}
                </div>
            </div>
        )
    })

    const TiledActiveIconsSize5 = icons.map(([icon, comment]) => {
        if (['none', '', 'adshaasdsrtkjgdas'].includes(icon)) return null
        return (
            <div
                key={icon}
                title={icon}
                style={{
                    textAlign: 'center',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >
                <div className="cc-Button cc-active">
                    <Icon name={icon} size={5} />
                </div>
                <div
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                    className="user-select"
                >
                    {icon}
                </div>
            </div>
        )
    })

    return (
        <HView grow>
            <VView style={{ padding: 20 }}>
                <h3>All icons, all sizes</h3>
                <Spacer size={5} />
                {AllIcons}
            </VView>
            <VView grow style={{ padding: 20 }}>
                <h3>All icons, size 2</h3>
                <Spacer size={5} />
                <TilesView tileMinWidth={80}>{TiledIcons}</TilesView>
                <Spacer size={20} />
                <h3>All icons, size 2, active</h3>
                <Spacer size={5} />
                <TilesView tileMinWidth={80}>{TiledActiveIcons}</TilesView>
                <Spacer size={20} />
                <h3>All icons, size 5</h3>
                <Spacer size={5} />
                <TilesView tileMinWidth={80}>{TiledIconsSize5}</TilesView>
                <Spacer size={20} />
                <h3>All icons, size 5, active</h3>
                <Spacer size={5} />
                <TilesView tileMinWidth={80}>{TiledActiveIconsSize5}</TilesView>
            </VView>
        </HView>
    )
}
