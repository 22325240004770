//
// AssetListField
//
// An asset-input for multiple assets (files and/or images).

import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useTooltipPopover } from '../../../../hooks/useTooltipPopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { Transform, mergeTransforms } from '../../../../stores/imageservers/utils'
import { DragDropLinkedAsset } from '../../../../dragdrop/DragDropLinkedAsset'

import { TilesView } from '../../../../appview'
import {
    AssetItem,
    AssetCard,
    AssetThumb,
    IconTile,
    UploadErrorMessage,
} from '../../../../panels'
import { AssetFieldMenu, UploadedAssetMenu } from '../../../../menus'
import {
    addListValue,
    replaceListValue,
    moveListValue,
    removeListValue,
    deduplicateList,
} from '../../../../utils/list'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { NavigationTileContext } from '../layoutbuilders/TilesLayoutBuilder'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

const MAGIC_WIDTH = { card: 200, item: 160, thumb: 40 }
const MAGIC_GAP = 15

export const AssetListField = observer(function AssetListField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    const inNavigationTile = useContext(NavigationTileContext)
    const store = useStore()
    const { data, view } = store
    const [containerRef, containerWidth] = useElementWidth(250)
    const AssetFieldContextMenu = useMousePopover(AssetFieldMenu)
    const UploadedAssetPopoverMenu = useTooltipPopover(UploadedAssetMenu)
    const [droppedAssetFile, setDroppedAssetFile] = useState(null) // only drop on existing asset

    let classes = 'cc-Field cc-AssetListField ws-tiles'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)
    const config = LAYOUTCOMPONENTTYPECONFIG['field']['typeconfig'][field.type]

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const componentstyle = component
        ? component.style || config.style.default
        : config.style.default

    const maxtilesperrow = Math.max(
        1,
        Math.floor(
            (containerWidth + MAGIC_GAP) / (MAGIC_WIDTH[componentstyle] + MAGIC_GAP)
        )
    )
    const tilesperrow = Math.min(
        maxtilesperrow,
        component
            ? parseInt(component.columns, 10) || config.columns.default
            : config.columns.default
    )

    const tileWidth = Math.max(
        20,
        Math.floor((containerWidth - MAGIC_GAP * (tilesperrow - 1)) / tilesperrow)
    )

    const [linkedassetgids, setLinkedAssetgids] = useState([])
    useEffect(() => {
        if (Array.isArray(fieldvalue)) {
            setLinkedAssetgids(fieldvalue)
        } else {
            setLinkedAssetgids([])
        }
    }, [fieldvalue])

    const [assets, setAssets] = useState([])
    const [selectedAsset, setSelectedAsset] = useState(null)
    useClickOutside(containerRef, () => setSelectedAsset(null))
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            if (!linkedassetgids || !linkedassetgids.length) {
                setAssets([])
                return
            }
            setAssets(new Array(linkedassetgids.length).fill(null))
            const missingGids = linkedassetgids.filter(gid => !data.assets.has(gid))
            if (missingGids.length) {
                await data.fetchAssetsByGid(missingGids, { signal: controller.signal })
            }
            const asyncassets = linkedassetgids
                .map(gid => data.assets.get(gid))
                .filter(asset => asset !== null)
            if (isMounted) {
                setAssets(asyncassets)
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [linkedassetgids, data])

    const setAndCommitField = updatedgids => {
        const uniquegids = deduplicateList(updatedgids)
        record.setField(field, uniquegids)
        record.commitIfModified()
    }

    const onAddAsset = async (before_asset, assetgid) => {
        if (linkedassetgids.includes(assetgid)) return
        const before_assetgid = before_asset ? before_asset.gid : null
        const updatedgids = addListValue(linkedassetgids, assetgid, before_assetgid)
        setAndCommitField(updatedgids)
    }

    const onAddAssets = async (before_asset, assetgids) => {
        if (!assetgids) return
        const before_assetgid = before_asset ? before_asset.gid : null
        let updatedgids = [...linkedassetgids]
        for (const assetgid of assetgids) {
            if (updatedgids.includes(assetgid)) continue
            updatedgids = addListValue(updatedgids, assetgid, before_assetgid)
        }
        setAndCommitField(updatedgids)
    }

    const onReplaceAsset = async (asset_to_replace, assetgid) => {
        if (linkedassetgids.includes(assetgid)) return
        const assetgid_to_replace = asset_to_replace ? asset_to_replace.gid : null
        const updatedgids = replaceListValue(
            linkedassetgids,
            assetgid,
            assetgid_to_replace
        )
        setAndCommitField(updatedgids)
    }

    const onUploadNewAsset = async (asset_to_replace, file) => {
        setDroppedAssetFile(null)
        const folder_gid = field.options.get('folder')
        if (!file.type) {
            console.log(file.name + ' - folder upload not supported')
            return false
        }
        if (folder_gid) {
            return data.actions.assets
                .upload(file, folder_gid)
                .then(result => {
                    asset_to_replace
                        ? onReplaceAsset(asset_to_replace, result['asset'])
                        : onAddAsset(null, result['asset'])
                    return result['asset']
                })
                .catch(error => {
                    store.setMessage(<UploadErrorMessage />)
                })
        } else {
            return data.actions.assets
                .uploadToFolder(file, '/')
                .then(result => {
                    asset_to_replace
                        ? onReplaceAsset(asset_to_replace, result['asset'])
                        : onAddAsset(null, result['asset'])
                    return result['asset']
                })
                .catch(error => {
                    store.setMessage(<UploadErrorMessage />)
                })
        }
    }

    const onUploadNewAssets = async (before_asset, files) => {
        setDroppedAssetFile(null)
        const folder_gid = field.options.get('folder')
        const filesarray = []
        for (const file of files) {
            filesarray.push(file)
        }
        const newassetgids = await Promise.all(
            filesarray
                .filter(file => file.type)
                .map(file =>
                    folder_gid
                        ? data.actions.assets
                              .upload(file, folder_gid)
                              .then(result => result['asset'])
                              .catch(error => null)
                        : data.actions.assets
                              .uploadToFolder(file, '/')
                              .then(result => result['asset'])
                              .catch(error => null)
                )
        )
        const successful_newassetgids = newassetgids.filter(gid => gid !== null)
        if (successful_newassetgids.length !== newassetgids.length) {
            store.setMessage(<UploadErrorMessage />)
        }
        onAddAssets(before_asset, successful_newassetgids)
    }

    const onUploadUpdateAsset = async (asset_to_replace, file) => {
        setDroppedAssetFile(null)
        if (!file.type) {
            console.log(file.name + ' - folder upload not supported')
            return false
        }
        return data.actions.assets
            .uploadUpdate(file, asset_to_replace.gid)
            .then(result => {
                return result['asset']
            })
            .catch(error => {
                store.setMessage(<UploadErrorMessage />)
            })
    }

    const onCancel = async () => {
        setDroppedAssetFile(null)
    }

    const onRemoveAsset = async asset_to_remove => {
        const assetgid_to_remove = asset_to_remove ? asset_to_remove.gid : null
        const updatedgids = removeListValue(linkedassetgids, assetgid_to_remove)
        setAndCommitField(updatedgids)
    }

    const onRemoveAllAssets = async () => {
        setAndCommitField([])
    }

    const onRevealInDam = async asset => {
        view.damworksheet.setAsset(asset)
        view.damtree.datamanager.setAsset(asset)
        // this is a hack to get out of a not-that-well-designed auto-updating
        // system - when lazyvscrolltiles does two refetches when it gets into focus,
        // it fetches the old asset, triggering a new setAsset call... if we wait a tiny
        // bit, it can refetch the newly set asset
        window.setTimeout(() => view.setWorkspace('dam'), 200)
    }

    const onAssetFieldAction = async (action, asset, otherassetgid) => {
        const actions = {
            add_asset: onAddAsset,
            replace_asset: onReplaceAsset,
            upload_new: onUploadNewAsset,
            upload_multiple: onUploadNewAssets,
            upload_update: onUploadUpdateAsset,
            cancel: onCancel,
            remove_asset: onRemoveAsset,
            remove_all: onRemoveAllAssets,
            reveal_in_dam: onRevealInDam,
        }
        AssetFieldContextMenu.hide()
        UploadedAssetPopoverMenu.hide()
        if (!(action in actions)) {
            console.log(`onAssetFieldAction: unhandled action '${action}'`)
            return
        }
        await actions[action](asset, otherassetgid)
    }

    const onDrop = async (dragitem, linkedasset, isoverzone) => {
        if (dragitem.hasOwnProperty('files')) {
            if (!linkedasset) {
                await onUploadNewAssets(linkedasset, dragitem.files)
            } else {
                // store dropped file, show UploadedAssetMenu first
                const file = dragitem.files[0]
                setDroppedAssetFile({ linkedasset, file })
                UploadedAssetPopoverMenu.show()
            }
        } else {
            const otherassetgid = dragitem.gid
            const otherfield = dragitem.fieldgid
                ? data.fields.get(dragitem.fieldgid)
                : null
            const linkedassetgid = linkedasset ? linkedasset.gid : null
            let before_assetgid = null
            if (['top', 'left', 'inside'].includes(isoverzone)) {
                before_assetgid = linkedassetgid
            } else {
                // before_assetgid is record -after- linkedassetgid
                let next = false
                for (let gid of linkedassetgids) {
                    if (gid === otherassetgid) {
                        continue
                    }
                    if (next) {
                        before_assetgid = gid
                        break
                    }
                    if (gid === linkedassetgid) next = true
                }
            }
            const before_asset_proxy = before_assetgid ? { gid: before_assetgid } : null

            if (!otherfield) {
                // dragged from -somewhere-, not a field, so just add
                onAddAsset(before_asset_proxy, otherassetgid)
            } else if (otherfield.gid !== field.gid) {
                // moved from other field, copy here, don't remove from other field
                onAddAsset(before_asset_proxy, otherassetgid)
            } else {
                // moved inside field
                const updatedids = moveListValue(
                    linkedassetgids,
                    otherassetgid,
                    before_assetgid
                )
                setAndCommitField(updatedids)
            }
        }
    }

    const onUploadAction = (action, p1) => {
        const actions = {
            upload_new: onUploadNewAsset,
            upload_update: onUploadUpdateAsset,
            cancel: onCancel,
        }
        AssetFieldContextMenu.hide()
        UploadedAssetPopoverMenu.hide()
        if (!(action in actions)) {
            console.log(`onUploadAction: unhandled action '${action}'`)
            return
        }
        actions[action](droppedAssetFile['linkedasset'], p1)
    }

    const onShowAssetFieldMenu = (e, asset) => {
        setSelectedAsset(asset)
        UploadedAssetPopoverMenu.hide()
        AssetFieldContextMenu.onShow(e)
    }

    const onClickShowAssetFieldMenu = (e, asset) => {
        if (enabled || !inNavigationTile) {
            onShowAssetFieldMenu(e, asset)
        }
    }

    const icontileanchor = componentstyle === 'item' ? 'start' : 'center'
    const iconplaceholder = enabled ? 'plus' : 'image'

    const pad = componentstyle === 'card' ? 16 : 6
    const transformResize = new Transform('fit', tileWidth - pad, tileWidth - pad)

    let Tiles
    const dragdropdirection = tilesperrow === 1 ? 'vertical' : 'horizontal'
    if (assets.length && linkedassetgids.length) {
        Tiles = linkedassetgids
            .filter(gid => gid.trim().length > 0)
            .map((gid, index) => {
                const asset = assets[index]
                if (!asset) return null
                const selected = selectedAsset && selectedAsset.gid === asset.gid
                const classes = selected ? ' cc-selected' : ''
                const transform = mergeTransforms(asset.mainTransform, transformResize)
                const AssetView =
                    componentstyle === 'card' ? (
                        <AssetCard
                            key={index}
                            asset={asset}
                            className={classes}
                            transform={transform}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowAssetFieldMenu(e, asset)}
                            onContextMenu={e => onShowAssetFieldMenu(e, asset)}
                        />
                    ) : componentstyle === 'thumb' ? (
                        <AssetThumb
                            key={index}
                            asset={asset}
                            transform={transform}
                            className={classes}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowAssetFieldMenu(e, asset)}
                            onContextMenu={e => onShowAssetFieldMenu(e, asset)}
                        />
                    ) : (
                        <AssetItem
                            key={index}
                            asset={asset}
                            className={classes}
                            iconplaceholder={iconplaceholder}
                            onClick={e => onClickShowAssetFieldMenu(e, asset)}
                            onContextMenu={e => onShowAssetFieldMenu(e, asset)}
                        />
                    )
                const tileclasses = 'ws-tile status-' + asset.status
                const Tile = (
                    <DragDropLinkedAsset
                        key={index}
                        field={field}
                        linkedassetgids={linkedassetgids}
                        linkedasset={asset}
                        direction={dragdropdirection}
                        disabled={!enabled}
                        onDrop={onDrop}
                    >
                        <div className={tileclasses} style={{ width: tileWidth }}>
                            {AssetView}
                        </div>
                    </DragDropLinkedAsset>
                )
                if (
                    droppedAssetFile &&
                    droppedAssetFile['linkedasset'].gid === asset.gid
                ) {
                    return (
                        <div key={index} ref={UploadedAssetPopoverMenu.anchorRef}>
                            {Tile}
                        </div>
                    )
                } else {
                    return Tile
                }
            })
    }

    if (enabled) {
        if (!Tiles) {
            Tiles = (
                <DragDropLinkedAsset
                    field={field}
                    linkedassetgids={linkedassetgids}
                    onDrop={onDrop}
                >
                    <div className="ws-tile" style={{ width: tileWidth }}>
                        <IconTile
                            width={32}
                            className="cc-dimmed"
                            anchor={icontileanchor}
                            icon={iconplaceholder}
                            onClick={e => onShowAssetFieldMenu(e, null)}
                        />
                    </div>
                </DragDropLinkedAsset>
            )
        } else {
            Tiles.push(
                <DragDropLinkedAsset
                    key="add"
                    field={field}
                    linkedassetgids={linkedassetgids}
                    onDrop={onDrop}
                >
                    <div className="ws-tile" style={{ width: tileWidth }}>
                        <IconTile
                            width={32}
                            className="cc-dimmed"
                            anchor={icontileanchor}
                            icon={iconplaceholder}
                            onClick={e => onShowAssetFieldMenu(e, null)}
                        />
                    </div>
                </DragDropLinkedAsset>
            )
        }
    }

    return (
        <div>
            <AssetFieldContextMenu.Panel
                asset={selectedAsset}
                enabled={enabled}
                list={true}
                onAction={onAssetFieldAction}
            />
            <UploadedAssetPopoverMenu.Panel
                file={droppedAssetFile && droppedAssetFile['file']}
                onAction={onUploadAction}
            />
            <TilesView
                ref={containerRef}
                columns={tilesperrow}
                className={classes}
                {...other}
            >
                {Tiles}
            </TilesView>
            <ValidationMessage validation={validation} />
        </div>
    )
})
