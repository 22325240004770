//
// ClassFieldRecord
//
// Class fields can have nested data etc, to work nicely with the LayoutBuilders and
// Fields we need a record-like class so we can use setField and commitIfModified etc.
// ClassFieldRecord and ClasslistFieldRecord are used as an intermediate backing store
// for nested class/classlist field constructions.

import { makeObservable, observable, computed, action } from 'mobx'
import { hashData } from '../../utils/utils'
import { hasValuesOption } from './utils'

export class ClassFieldRecord {
    _rootstore = null
    _record = null
    _field = null
    _class = null
    _data = null
    fields = new Map()

    constructor(record, field, fielddata) {
        makeObservable(this, {
            _rootstore: observable,
            _record: observable,
            _field: observable,
            _data: observable,
            fields: observable,

            gid: computed,
            version_gid: computed,
            language: computed,
            definition: computed,
            data_hash: computed,
            localized_fields: computed,

            updateFields: action.bound,

            resetField: action.bound,
            setField: action.bound,
            commitIfModified: action.bound,

            bump_version_gid: action.bound,
        })

        this._rootstore = record._rootstore
        this._record = record
        this._field = field
        this._data = fielddata

        const class_gid = this._field ? this._field.options.get('class') : undefined
        this._class = class_gid
            ? this._rootstore.data.classes.get(class_gid)
            : undefined

        this.updateFields()
    }

    get gid() {
        if (this._record._record) {
            // nested, no need to include version_gid twice
            return this._record.gid + '.' + this._field.name
        }
        return (
            this._record.gid + '.' + this._record.version_gid + '.' + this._field.name
        )
    }

    get version_gid() {
        return this._record.version_gid
    }

    get language() {
        return this._record.language
    }

    get definition() {
        return this._record.definition
    }

    get data_hash() {
        return hashData(this.fields)
    }

    get localized_fields() {
        return this.fields
    }

    bump_version_gid = () => {
        this._record.bump_version_gid()
    }

    updateFields = () => {
        let fields = new Map()
        if (this._class) {
            for (const field_gid of this._class.fields) {
                const field = this._rootstore.data.fields.get(field_gid)
                if (field) {
                    fields.set(
                        field.name,
                        this._data && this._data.get(field.name) !== undefined
                            ? this._data.get(field.name)
                            : null
                    )
                    if (hasValuesOption(field)) {
                        fields.set(
                            field.name + '/key',
                            this._data &&
                                this._data.get(field.name + '/key') !== undefined
                                ? this._data.get(field.name + '/key')
                                : null
                        )
                    }
                } else {
                    console.log('ClassFieldRecord field ERROR', field_gid)
                }
            }
        }
        this.fields.replace(fields)
    }

    setClassField = (key, value, recordfield) => {
        return this._record.setClassField(key, value, this._field)
    }

    setField = (field, value) => {
        this._data.set(field.name, value)
        this.updateFields()
        return this._record.setClassField(
            this.gid + '.' + field.name,
            value,
            this._field
        )
    }

    setFieldKey = (field, key) => {
        this._data.set(field.name + '/key', key)
        this._data.set(field.name, key)
        var value = key
        if (field.type === 'textline') {
            for (const value_option of field['options'].get('values')) {
                if (key === value_option['key']) {
                    value = value_option[this.language]
                    break
                }
            }
            this._data.set(field.name, value)
        } else if (field.type === 'textlist') {
            value = []
            for (var keyline of key) {
                for (const value_option of field['options'].get('values')) {
                    if (keyline === value_option['key']) {
                        value.push(value_option[this.language])
                        break
                    }
                }
            }
            this._data.get(field.name).replace(value)
        }
        this.updateFields()
        this._record.setClassField(
            this.gid + '.' + field.name + '/key',
            key,
            this._field
        )
        this._record.setClassField(this.gid + '.' + field.name, value, this._field)
    }

    resetField = field => {
        return this._record.resetField(this._field)
    }

    commitIfModified = () => {
        return this._record.commitIfModified()
    }
}
