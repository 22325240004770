//
// KeyValueListManager
//
// A table of text-inputs with keys, where you manage KeyValue data.
//
// KeyValue data looks like this, with keys and localized labels
//
// [
//     { key: 'left', en: 'Left' nl: 'Links' },
//     { key: 'center', en: 'Centered' nl: 'Gecentreerd' },
//     { key: 'right', en: 'Right' nl: 'Rechts' },
// ]

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useMousePopover } from '../hooks/useMousePopover'
import { useClickOutside } from '../hooks/useClickOutside'
import { DragDropListRow } from '../dragdrop/DragDropListRow'

import {
    addIndexedListValue,
    replaceIndexedListValue,
    moveIndexedListValue,
    removeIndexedListValue,
} from '../utils/list'
import { keyboard } from '../utils/keyboard'
import { test_empty, objectFrom } from '../utils/utils'

import { Text, Icon, TextInput, Menu, MenuItem } from '../components'
import { TextListMenu } from '../menus'

const ConfirmSubmenu = observer(function ConfirmSubmenu({ onClick }) {
    const { app } = useStore()

    return (
        <Menu>
            <MenuItem className="cc-danger" onClick={onClick}>
                {app.text('Yes, delete all the labels')}
            </MenuItem>
        </Menu>
    )
})

const KeyTranslationMenu = observer(function KeyTranslationMenu({
    hasLabels,
    onChangeHasLabels,
}) {
    const { app } = useStore()

    return (
        <Menu>
            <MenuItem
                className={hasLabels ? 'cc-danger' : 'cc-active'}
                submenu={
                    hasLabels ? (
                        <ConfirmSubmenu onClick={() => onChangeHasLabels(false)} />
                    ) : undefined
                }
            >
                {app.text('Key only')}
            </MenuItem>
            <MenuItem
                className={hasLabels ? 'cc-active' : ''}
                onClick={() => onChangeHasLabels(true)}
            >
                {app.text('Key with label')}
            </MenuItem>
        </Menu>
    )
})

const TableHeaderKeys = observer(function TableHeaderKeys({ onShowContextMenu }) {
    const { app } = useStore()

    return (
        <tr>
            <td key={'action'} className="ws-table-cell ws-table-cell-_action_">
                <div onClick={onShowContextMenu}>
                    <Icon name="key" size={1} />
                </div>
            </td>
            <td key={'key'} className="ws-table-cell ws-table-cell-key">
                <Text>{app.text('key')}</Text>
            </td>
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_"></td>
        </tr>
    )
})

const TableHeaderKeysLabels = observer(function TableHeaderKeysLabels({
    language,
    onShowContextMenu,
}) {
    const { app } = useStore()

    return (
        <tr>
            <td key={'action'} className="ws-table-cell ws-table-cell-_action_">
                <div onClick={onShowContextMenu}>
                    <Icon name="key" size={1} />
                </div>
            </td>
            <td key={'key'} className="ws-table-cell ws-table-cell-key">
                <Text>{app.text('key')}</Text>
            </td>
            <td key={'translation'} className="ws-table-cell ws-table-cell-translation">
                <Text>
                    {app.text('label')}
                    <span className="language">{language}</span>
                </Text>
            </td>
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_"></td>
        </tr>
    )
})

const TableHeader = observer(function TableHeader({
    language,
    hasLabels,
    onShowContextMenu,
}) {
    return hasLabels ? (
        <TableHeaderKeysLabels
            language={language}
            onShowContextMenu={onShowContextMenu}
        />
    ) : (
        <TableHeaderKeys onShowContextMenu={onShowContextMenu} />
    )
})

const TableRow = observer(function TableRow({
    keylabel,
    index,
    language,
    hasLabels,
    enabled,
    focusItem,
    onChangeKey,
    onBlurKey,
    onFocusKey,
    onKeyDownKey,
    onChangeLabel,
    onBlurLabel,
    onFocusLabel,
    onKeyDownLabel,
    onDrop,
    onShowTextListMenu,
    renderkey,
    ...other
}) {
    const { app } = useStore()
    let classes = ''
    let cellclasses = 'ws-table-cell ws-table-cell-text'

    const _onChangeKey = event => {
        onChangeKey && onChangeKey(index, event.target.value)
    }
    const _onBlurKey = event => {
        onBlurKey && onBlurKey(index, event.target.value.trim())
    }
    const _onKeyDownKey = event => {
        onKeyDownKey && onKeyDownKey(event, index, event.target.value.trim())
    }
    const _onChangeLabel = event => {
        onChangeLabel && onChangeLabel(index, event.target.value, language)
    }
    const _onBlurLabel = event => {
        onBlurLabel && onBlurLabel(index, event.target.value.trim(), language)
    }
    const _onKeyDownLabel = event => {
        onKeyDownLabel &&
            onKeyDownLabel(event, index, event.target.value.trim(), language)
    }

    const _onFocusKey = event => {
        onFocusKey && onFocusKey(index)
    }
    const _onFocusLabel = event => {
        onFocusLabel && onFocusLabel(index)
    }

    const inputrenderkey = renderkey + index.toString()

    let KeyInput = (
        <TextInput
            multiline={false}
            enabled={enabled}
            setFocus={
                index === focusItem[0]
                    ? focusItem[1] === 'start'
                        ? 'start'
                        : focusItem[1] === 'key_end'
                        ? 'end'
                        : false
                    : false
            }
            value={keylabel['key']}
            language={language}
            onChange={_onChangeKey}
            onBlur={_onBlurKey}
            onFocus={_onFocusKey}
            onKeyDown={_onKeyDownKey}
            renderkey={inputrenderkey + '.key'}
            placeholder={app.text('key')}
            {...other}
        />
    )
    let LabelInput = (
        <TextInput
            multiline={false}
            enabled={enabled}
            setFocus={
                index === focusItem[0]
                    ? focusItem[1] === 'end'
                        ? 'end'
                        : focusItem[1] === 'label_start'
                        ? 'start'
                        : false
                    : false
            }
            value={keylabel[language]}
            language={language}
            onChange={_onChangeLabel}
            onBlur={_onBlurLabel}
            onFocus={_onFocusLabel}
            onKeyDown={_onKeyDownLabel}
            renderkey={inputrenderkey + '.' + language}
            placeholder={app.text('label')}
            {...other}
        />
    )

    return (
        <DragDropListRow
            className={classes}
            list_key={renderkey}
            item_index={index}
            direction="vertical"
            disabled={!enabled}
            onDrop={onDrop}
        >
            <td
                key={'action'}
                className="ws-table-cell ws-table-cell-_action_"
                onClick={e => onShowTextListMenu(e, index)}
                onContextMenu={e => onShowTextListMenu(e, index)}
            >
                <Icon size="text" name="ellipsis" />
            </td>
            <td className={cellclasses}>{KeyInput}</td>
            {hasLabels ? <td className={cellclasses}>{LabelInput}</td> : undefined}
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_">
                <Icon size={1} name="reorder" />
            </td>
        </DragDropListRow>
    )
})

const newKeyLabel = languages => {
    var keylabel = { key: '' }
    for (const language of languages) {
        keylabel[language] = ''
    }
    return keylabel
}
const updateKeyLabel = (keylabel, key_or_language, key_or_label) => {
    let new_keylabel = { ...objectFrom(keylabel) }
    new_keylabel[key_or_language] = key_or_label
    return new_keylabel
}
const updateKeyAndLabel = (keylabel, key, label, language) => {
    let new_keylabel = { ...objectFrom(keylabel) }
    new_keylabel['key'] = key
    new_keylabel[language] = label
    return new_keylabel
}
const nonemptyLines = text => {
    return text
        .trim()
        .split('\n')
        .map(line => line.trim())
        .filter(line => line.length > 0)
}
const trimmedSplit = (text, split = '\t') => {
    return text
        .trim()
        .split(split)
        .map(part => part.trim())
}

export const KeyValueListManager = observer(function KeyValueListManager({
    className,
    enabled,
    hasLabels,
    keyvaluelist,
    language,
    languages,
    renderkey,
    onChange,
    onBlur,
    onChangeHasLabels,
    ...other
}) {
    const { app } = useStore()
    const containerRef = useRef()

    if (enabled === undefined) enabled = true

    const keyvaluelistRef = useRef()
    keyvaluelistRef.current = keyvaluelist

    const languageRef = useRef()
    languageRef.current = language // used in setKeyFromValue via onChange and onBlur

    const hasLabelsRef = useRef()
    hasLabelsRef.current = hasLabels // used in _onKeyDownKey

    const TextListContextMenu = useMousePopover(TextListMenu)
    const [focusItem, setFocusItem] = useState([null, 'end'])

    const KeyTranslationContextMenu = useMousePopover(KeyTranslationMenu)

    let classes = 'cc-Field cc-TextListInput cc-KeyValueListManager ws-table'
    if (className) classes += ' ' + className

    const [selectedItemIndex, setSelectedItemIndex] = useState(null) // an index
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    const _onAddItemBefore = before_index => {
        const newValue = newKeyLabel(languages)
        const newValues = addIndexedListValue(
            keyvaluelistRef.current,
            newValue,
            before_index
        )
        onBlur && onBlur(newValues)
        if (before_index === null) {
            setFocusItem([newValues.length - 1, 'start'])
        } else {
            setFocusItem([before_index, 'start'])
        }
    }

    const _onRemoveItem = index => {
        const newValues = removeIndexedListValue(keyvaluelistRef.current, index)
        onBlur && onBlur(newValues)
        setFocusItem([index - 1, 'end'])
    }

    const _onRemoveAllItems = () => {
        const newValues = []
        onBlur && onBlur(newValues)
        setFocusItem([null, 'end'])
    }

    const onTextListAction = action => {
        const actions = {
            add_item: _onAddItemBefore,
            remove_item: _onRemoveItem,
            remove_all: _onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onTextListAction: unhandled action '${action}'`)
            return
        }
        TextListContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }
        const newValues = moveIndexedListValue(
            keyvaluelistRef.current,
            other_index,
            before_index
        )
        onBlur && onBlur(newValues)
        setFocusItem([null, 'end'])
    }

    // const _onUpdateItemAddAfter = (index, updatedItem) => {
    //     const newValue = newKeyLabel(languages)
    //     const newValues = addIndexedListValue(
    //         replaceIndexedListValue(keyvaluelistRef.current, updatedItem, index),
    //         newValue,
    //         index + 1
    //     )
    //     onBlur && onBlur(newValues)
    //     setFocusItem([index + 1, 'start'])
    // }

    const _onHandleChangedItem = (index, updatedItem, key_or_language) => {
        let shouldBlur = false
        let newListValue = keyvaluelistRef.current
        let updatedKeyLabelItem
        const lines = nonemptyLines(updatedItem[key_or_language])
        for (let line of lines) {
            const keyLabelItem =
                index < newListValue.length
                    ? newListValue[index]
                    : newKeyLabel(languages)
            const key_label = trimmedSplit(line, '\t')
            if (key_label.length > 1) {
                updatedKeyLabelItem = updateKeyAndLabel(
                    keyLabelItem,
                    key_label[0],
                    key_label[1],
                    languageRef.current
                )
                shouldBlur = true
            } else if (key_or_language !== 'key' && test_empty(keyLabelItem['key'])) {
                updatedKeyLabelItem = updateKeyAndLabel(
                    keyLabelItem,
                    line,
                    line,
                    languageRef.current
                )
                shouldBlur = true
            } else {
                updatedKeyLabelItem = updateKeyLabel(
                    keyLabelItem,
                    key_or_language,
                    line
                )
            }
            if (index < newListValue.length) {
                newListValue = replaceIndexedListValue(
                    newListValue,
                    updatedKeyLabelItem,
                    index
                )
                shouldBlur = true
            } else {
                newListValue = addIndexedListValue(
                    newListValue,
                    updatedKeyLabelItem,
                    index
                )
                shouldBlur = true
            }
            index += 1
        }
        return [shouldBlur, newListValue]
    }

    const onChangeItem = (index, updatedItem, key_or_language) => {
        const lines = updatedItem[key_or_language]
            ? nonemptyLines(updatedItem[key_or_language])
            : ['']
        const [shouldBlur, newListValue] =
            lines.length > 1
                ? _onHandleChangedItem(index, updatedItem, key_or_language)
                : [
                      false,
                      replaceIndexedListValue(
                          keyvaluelistRef.current,
                          updatedItem,
                          index
                      ),
                  ]
        if (lines.length > 1) {
            setFocusItem([index + lines.length - 1, 'end'])
        }
        shouldBlur ? onBlur && onBlur(newListValue) : onChange && onChange(newListValue)
    }

    const onBlurItem = (index, updatedItem, key_or_language) => {
        if (!onBlur) {
            return
        }
        const [, newListValue] = _onHandleChangedItem(
            index,
            updatedItem,
            key_or_language
        )
        onBlur(newListValue)
    }

    const onKeyDownItem = (event, index, updatedItem, key_or_language) => {
        if (key_or_language === 'key') {
            if (
                keyboard.test(event, keyboard.ENTER) ||
                keyboard.test(event, keyboard.TAB)
            ) {
                if (keyboard.test(event, keyboard.ENTER) && !hasLabelsRef.current) {
                    const itemcount = keyvaluelistRef.current.length
                    let [, newListValue] = _onHandleChangedItem(
                        index,
                        updatedItem,
                        key_or_language
                    )
                    if (newListValue.length > itemcount || index + 1 === itemcount) {
                        const newValue = newKeyLabel(languages)
                        newListValue = addIndexedListValue(newListValue, newValue)
                        onBlur && onBlur(newListValue)
                        if (newListValue[index + 1]['key'].length) {
                            setFocusItem([newListValue.length - 1, 'start'])
                        } else {
                            setFocusItem([index + 1, 'start'])
                        }
                    } else {
                        onBlur && onBlur(newListValue)
                        setFocusItem([index + 1, 'key_end'])
                    }
                } else {
                    if (keyboard.test(event, keyboard.TAB)) {
                        setFocusItem([
                            hasLabelsRef.current ? index : index + 1,
                            hasLabelsRef.current ? 'label_start' : 'start',
                        ])
                        event.preventDefault()
                        event.stopPropagation()
                    } else {
                        setFocusItem([index + 1, 'start'])
                    }
                    // onBlurItem(index, updatedItem, 'key')
                    event.target.element.blur()
                }
            } else if (
                (!event.target.value.length || event.target.value === '\n') &&
                keyboard.test(event, keyboard.BACKSPACE)
            ) {
                _onRemoveItem(index)
            }
        } else {
            if (keyboard.test(event, keyboard.ENTER)) {
                const itemcount = keyvaluelistRef.current.length
                let [, newListValue] = _onHandleChangedItem(
                    index,
                    updatedItem,
                    key_or_language
                )
                if (newListValue.length > itemcount || index + 1 === itemcount) {
                    const newValue = newKeyLabel(languages)
                    newListValue = addIndexedListValue(newListValue, newValue)
                    onBlur && onBlur(newListValue)
                    if (newListValue[index + 1][language].length) {
                        setFocusItem([newListValue.length - 1, 'start'])
                    } else {
                        setFocusItem([index + 1, 'start'])
                    }
                } else {
                    // onBlur && onBlur(newListValue)
                    setFocusItem([index + 1, 'label_start'])
                    event.target.element.blur()
                }
            } else if (
                (!event.target.value.length || event.target.value === '\n') &&
                keyboard.test(event, keyboard.BACKSPACE)
            ) {
                _onRemoveItem(index)
            }
        }
    }

    const onChangeKey = (index, updatedKey) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, 'key', updatedKey)
        onChangeItem && onChangeItem(index, updatedItem, 'key')
    }
    const onBlurKey = (index, updatedKey) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, 'key', updatedKey)
        onBlurItem && onBlurItem(index, updatedItem, 'key')
    }
    const onKeyDownKey = (event, index, updatedKey) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, 'key', updatedKey)
        onKeyDownItem && onKeyDownItem(event, index, updatedItem, 'key')
    }
    const onChangeLabel = (index, updatedLabel, language) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, language, updatedLabel)
        if (!updatedItem['key'].length) {
            updatedItem['key'] = nonemptyLines(updatedItem[language])[0]
        }
        onChangeItem && onChangeItem(index, updatedItem, language)
    }
    const onBlurLabel = (index, updatedLabel, language) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, language, updatedLabel)
        if (!updatedItem['key'].length) {
            updatedItem['key'] = nonemptyLines(updatedItem[language])[0]
        }
        onBlurItem && onBlurItem(index, updatedItem, language)
    }
    const onKeyDownLabel = (event, index, updatedLabel, language) => {
        const keylabel = keyvaluelistRef.current[index]
        const updatedItem = updateKeyLabel(keylabel, language, updatedLabel)
        if (!updatedItem['key'].length) {
            updatedItem['key'] = nonemptyLines(updatedItem[language])[0]
        }
        onKeyDownItem && onKeyDownItem(event, index, updatedItem, language)
    }

    const onFocusKey = index => {
        setFocusItem([null, 'end'])
    }
    const onFocusLabel = index => {
        setFocusItem([null, 'end'])
    }

    const onShowTextListMenu = (e, index) => {
        if (index === null) {
            _onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        TextListContextMenu.onShow(e)
    }

    const onShowKeyTranslationContextMenu = e => {
        KeyTranslationContextMenu.onShow(e)
    }

    let TableRows
    if (keyvaluelistRef.current && keyvaluelistRef.current.length) {
        TableRows = keyvaluelistRef.current.map((item, index) => {
            return (
                <TableRow
                    key={
                        'textlist.' +
                        keyvaluelistRef.current.length +
                        '.' +
                        index.toString()
                    }
                    keylabel={item}
                    index={index}
                    language={language}
                    hasLabels={hasLabels}
                    enabled={enabled}
                    focusItem={focusItem}
                    onChangeKey={onChangeKey}
                    onBlurKey={onBlurKey}
                    onFocusKey={onFocusKey}
                    onKeyDownKey={onKeyDownKey}
                    onChangeLabel={onChangeLabel}
                    onBlurLabel={onBlurLabel}
                    onFocusLabel={onFocusLabel}
                    onKeyDownLabel={onKeyDownLabel}
                    onDrop={onDrop}
                    onShowTextListMenu={onShowTextListMenu}
                    renderkey={renderkey}
                />
            )
        })
    }

    if (enabled) {
        let classes = 'ws-table-cell cc-dimmed'
        const AddTableRow = (
            <tr key={'textlist.add'}>
                <td className={classes + ' ws-table-cell-_action_'}>
                    <Icon size={1} name="plus" onClick={e => _onAddItemBefore(null)} />
                </td>
                <td
                    colSpan={2}
                    className={classes + ' ws-table-cell-_add-text_'}
                    style={{ textAlign: 'left' }}
                >
                    <div onClick={e => _onAddItemBefore(null)}>
                        {app.text('Add new item')}
                    </div>
                </td>
            </tr>
        )

        if (!TableRows) {
            TableRows = AddTableRow
        } else {
            TableRows.push(AddTableRow)
        }
    }

    const innerOnChangeHasLabels = event => {
        onChangeHasLabels && onChangeHasLabels(event)
        KeyTranslationContextMenu.hide()
    }

    return (
        <div>
            <TextListContextMenu.Panel onAction={onTextListAction} />
            <KeyTranslationContextMenu.Panel
                hasLabels={hasLabels}
                onChangeHasLabels={innerOnChangeHasLabels}
            />
            <div className={classes} {...other}>
                <table className="ws-table">
                    <thead>
                        <TableHeader
                            language={language}
                            hasLabels={hasLabels}
                            onShowContextMenu={onShowKeyTranslationContextMenu}
                        />
                    </thead>
                    <tbody ref={containerRef}>{TableRows}</tbody>
                </table>
            </div>
        </div>
    )
})
