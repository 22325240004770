//
// SublayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'

import { RecursionMessage } from '.'
import { LayoutBuilder } from '..'

export const SublayoutBuilder = observer(function SublayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const sublayout = worksheet.layouts.get(component.layout)
    if (!sublayout) return null

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (sublayout.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (
        sublayout &&
        (visited_gids.includes(sublayout.gid) ||
            visited_gids.includes(sublayout.original))
    ) {
        return <RecursionMessage component={sublayout} />
    }

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        sublayout ? sublayout.gid : undefined,
        sublayout ? sublayout.original : undefined,
    ]

    const SubLayout = sublayout ? (
        <LayoutBuilder
            component={sublayout.components.get(sublayout.root)}
            components={sublayout.components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={active_class}
            visited_gids={[
                ...visited_gids,
                ...update_visited_gids.filter(gid => !!gid),
            ]}
            force_readonly={force_readonly}
        />
    ) : undefined

    return <div {...attributes}>{SubLayout}</div>
})
