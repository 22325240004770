//
// ClassField
//
// A class input - according to its own layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

import { Text } from '../../../../components'
import { LayoutBuilder } from '..'
import { PropertyTable } from '.'
import { ClassFieldRecord } from '../../../../stores/data/ClassFieldRecord'
import { RecursionMessage } from '../layoutbuilders'

export const ClassField = observer(function ClassField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        propertytable,
        visited_gids,
        ...other
    } = props

    const { data, app } = useStore()

    let classes = 'cc-Field cc-ClassField'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        (record.localized_fields && record.localized_fields.get(field.name)) ||
        new Map()
    const classfieldrecord = new ClassFieldRecord(record, field, fieldvalue)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    if (!class_ || !layout) {
        return <Text>{app.text('No class or layout assigned.')}</Text>
    }

    if (
        visited_gids.includes(class_.gid) ||
        visited_gids.includes(layout.gid) ||
        visited_gids.includes(class_.original) ||
        visited_gids.includes(layout.original)
    ) {
        return <RecursionMessage component={field} />
    }

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        layout.gid,
        layout.original,
        class_.gid,
        class_.original,
    ]

    const ClassFieldLayout =
        class_ && propertytable ? (
            <PropertyTable
                className="cc-block"
                record={classfieldrecord}
                fields={class_.fields}
                fromClass={class_.gid}
                worksheet={worksheet}
                visited_gids={[
                    ...visited_gids,
                    ...update_visited_gids.filter(gid => !!gid),
                ]}
            />
        ) : class_ && layout ? (
            <LayoutBuilder
                component={layout.components.get(layout.root)}
                components={layout.components}
                layout={layout}
                record={classfieldrecord}
                worksheet={worksheet}
                active_class={class_}
                force_readonly={force_readonly || !enabled}
                visited_gids={[
                    ...visited_gids,
                    ...update_visited_gids.filter(gid => !!gid),
                ]}
            />
        ) : (
            <Text>{app.text('No class or layout assigned.')}</Text>
        )

    return (
        <div className={classes} {...other}>
            {ClassFieldLayout}
            <ValidationMessage validation={validation} />
        </div>
    )
})
