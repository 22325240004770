//
// ClassLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { RecursionMessage } from '.'
import { LayoutBuilder } from '..'

export const ClassLayoutBuilder = observer(function ClassLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    const { data } = useStore()

    const class_ = data.classes.get(component['class'])
    if (!class_) return null
    const classlayout = data.layouts.get(class_.layout)
    if (!classlayout) return null

    let attributes = { className: 'layout-component' }
    if (className) attributes['className'] += ' ' + className
    if (component['gid'] === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)

    if (
        classlayout &&
        (visited_gids.includes(classlayout.gid) ||
            visited_gids.includes(classlayout.original))
    ) {
        return <RecursionMessage component={classlayout} />
    }

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        classlayout ? classlayout.gid : undefined,
        classlayout ? classlayout.original : undefined,
        class_ ? class_.gid : undefined,
        class_ ? class_.original : undefined,
    ]

    const ClassLayout = classlayout ? (
        <LayoutBuilder
            component={classlayout.components.get(classlayout.root)}
            components={classlayout.components}
            layout={layout}
            record={record}
            worksheet={worksheet}
            active_class={class_}
            visited_gids={[
                ...visited_gids,
                ...update_visited_gids.filter(gid => !!gid),
            ]}
        />
    ) : undefined

    return <div {...attributes}>{ClassLayout}</div>
})
