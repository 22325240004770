//
// SelectFieldOptions
//
// Field options 'values' (to choose from) and 'restrict_to_values' (boolean)

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { KeyValueListManager, Toggle } from '../../../../components'
import { VALIDATION } from '../../../../utils/validation'
import { validateField, duckField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'
import { listFromValues } from '../../../../stores/data/utils'
import { LabeledField } from '../../../components'

export const SelectFieldOptions = observer(function SelectFieldOptions({
    field,
    selectModified,
    worksheet,
    onAction,
    setSelected,
    renderkeyprefix,
}) {
    const { app, view } = useStore()

    const values = field ? field.options.get('values') : undefined
    let value_labels = field ? field.options.get('value_labels') : undefined
    if (value_labels === undefined) {
        value_labels = true // default value if not given
    }

    const restrict_to_values = field
        ? field.options.get('restrict_to_values')
        : undefined

    const keyvalues = listFromValues(values, 'key')

    if (!field) return null

    const onToggleRestrict = newValue => {
        field.setOption('restrict_to_values', newValue)
        field.commitIfModified().then(result => selectModified(result))
    }

    let classes = 'cc-TextListField'
    const duckListField = duckField('textlist', {
        allow_duplicates: false,
        allow_empties: false,
    })
    const validation = validateField(keyvalues, duckListField)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const onChange = newValues => {
        field.setValuesOption(newValues)
    }
    const onBlur = newValues => {
        field.setValuesOption(newValues)
        field
            .commitIfModified()
            .then(result => selectModified(result))
            .catch(error => {}) // duplicate keys gives an error, but is already displayed
    }
    const onChangeHasLabels = newValue => {
        field.setOption('value_labels', newValue)
        field
            .commitIfModified()
            .then(result => selectModified(result))
            .catch(error => {}) // duplicate keys gives an error, but is already displayed
    }

    return (
        <>
            <LabeledField
                className={classes}
                is_localized={false}
                label={app.text('values')}
            >
                <KeyValueListManager
                    enabled={true}
                    keyvaluelist={values}
                    hasLabels={value_labels}
                    language={worksheet.language}
                    languages={view.project.languages}
                    onChange={onChange}
                    onBlur={onBlur}
                    onChangeHasLabels={onChangeHasLabels}
                    renderkey={renderkeyprefix + '.values'}
                />
                <ValidationMessage validation={validation} />
            </LabeledField>
            <LabeledField label={app.text('restrict to values')}>
                <Toggle onChange={onToggleRestrict} value={restrict_to_values} />
            </LabeledField>
        </>
    )
})
