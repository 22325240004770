//
// KeyValueInput
//
// Input field for text field values, where you can pick from the options.
//
// KeyValue data looks like this, with keys and localized labels
//
// [
//     { key: 'left', en: 'Left' nl: 'Links' },
//     { key: 'center', en: 'Centered' nl: 'Gecentreerd' },
//     { key: 'right', en: 'Right' nl: 'Rechts' },
// ]

import React, { useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { LayerView, Layer } from '../appview'
import { TextInput, Icon } from '.'
import { autoCompleter } from '../stores/data/autocompleters'
import { test_empty } from '../utils/utils'

export const KeyValueInput = observer(function KeyValueInput({
    value,
    values,
    language,
    hasLabels,
    restrictToValues,
    enabled,
    placeholder,
    validate,
    setFocus,
    onChangeKey,
    onFocus,
    onBlurKey,
    onKeyDown,
    className,
    style,
    ...other
}) {
    const { app } = useStore()

    if (enabled === undefined) enabled = true

    var valuesByKey = {}
    var valuesByLabel = {}
    for (const entry of values) {
        if (test_empty(entry['key'])) continue
        valuesByKey[entry['key']] = entry[language]
        if (test_empty(entry[language])) {
            valuesByLabel[entry['key']] = entry['key']
        } else {
            valuesByLabel[entry[language]] = entry['key']
        }
    }

    const valuesBy = hasLabels ? valuesByLabel : valuesByKey
    const input = hasLabels
        ? value in valuesByKey && valuesByKey[value].length
            ? valuesByKey[value]
            : value
        : value

    var iconname = 'checkrow'
    var tooltip = value
    var statusclass = 'cc-info'
    if (!input) {
        iconname = 'empty'
    } else if (!(input in valuesBy)) {
        iconname = 'xrow'
        tooltip = app.text('Unlisted value')
        if (restrictToValues) {
            tooltip = app.text('Undefined value')
            statusclass = 'cc-notice'
        }
    }

    // used in setKeyFromValue via onChange and onBlur
    const hasLabelsRef = useRef()
    hasLabelsRef.current = hasLabels
    const valuesByLabelRef = useRef()
    valuesByLabelRef.current = valuesByLabel

    const getKeyFromValue = value => {
        return hasLabelsRef.current
            ? value in valuesByLabelRef.current
                ? valuesByLabelRef.current[value]
                : value
            : value
    }

    const innerOnChange = event => {
        const key = getKeyFromValue(event.target.value)
        onChangeKey && onChangeKey(key)
    }
    const innerOnBlur = event => {
        const key = getKeyFromValue(event.target.value.trim())
        onBlurKey && onBlurKey(key)
    }
    const innerOnKeyDown = event => {
        const key = getKeyFromValue(event.target.value.trim())
        onKeyDown && onKeyDown(event, key)
    }

    const lang = hasLabels ? { language: language } : {}

    const field = { type: 'textline', options: { get: () => values } }
    const fieldAutoCompleter = autoCompleter(field, hasLabels ? language : undefined)
    const autoComplete = useMemo(() => {
        return fieldAutoCompleter
            ? async q => {
                  return fieldAutoCompleter(q)
              }
            : null
    }, [fieldAutoCompleter])

    let classes = 'cc-KeyValueInput'
    if (!enabled) {
        classes += ' cc-disabled'
    }
    if (className) {
        classes += ' ' + className
    }

    return (
        <LayerView className={classes}>
            <TextInput
                className="ws-text"
                multiline={false}
                enabled={enabled}
                value={input}
                {...lang}
                style={style}
                placeholder={placeholder}
                onChange={innerOnChange}
                setFocus={setFocus}
                onFocus={onFocus}
                onBlur={innerOnBlur}
                onKeyDown={innerOnKeyDown}
                autoComplete={autoComplete}
                validate={validate}
                {...other}
            />
            <Layer anchor="end">
                <Icon
                    style={{
                        position: 'relative',
                        top: 2,
                        right: 4,
                    }}
                    name={iconname}
                    size={1}
                    title={tooltip}
                    className={statusclass}
                />
            </Layer>
        </LayerView>
    )
})
