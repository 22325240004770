//
// RecordInspector
//
// An inspector for the properties of a PIM record (definition manager) and the channels

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../stores'

import { VView, Spacer } from '../../../appview'
import { Header, Text, TreeItem } from '../../../components'
import { PropertyTable } from '../worksheets/fields'

const ClassRow = observer(function ClassRow({
    class_,
    worksheet,
    treeState,
    selectedclassgid,
    selectedclassfieldgid,
    onClickClass,
    onExpandTreeState,
    onCollapseTreeState,
    onClickClassField,
    visited_gids,
}) {
    const { app } = useStore()
    const language = worksheet.environment.get('language')

    if (!class_) return null
    let Fieldslabel
    let Fields
    const state = treeState[class_.gid]

    let classname = class_.label.get(language)
    if (!classname || !classname.length) classname = class_.name

    if (state === 'expanded') {
        Fieldslabel =
            class_.fields && class_.fields.length ? undefined : (
                <div className="ws-propertytable-row">
                    <div className="ws-propertytable-cell ws-propertytable-cell-fullwidth">
                        {app.text('No fields')}
                    </div>
                </div>
            )
        Fields = (
            <PropertyTable
                className="cc-block"
                record={worksheet.record}
                fields={class_.fields}
                fromClass={class_.gid}
                selectedfieldgid={selectedclassfieldgid}
                onClickField={fieldgid => {
                    onClickClassField && onClickClassField(class_.gid, fieldgid)
                }}
                worksheet={worksheet}
                visited_gids={visited_gids}
            />
        )
    }

    return (
        <div>
            <TreeItem
                key={class_.gid}
                state={state}
                selected={class_.gid === selectedclassgid && !selectedclassfieldgid}
                indent={0}
                onClick={e => onClickClass(e, class_.gid)}
                onExpand={() => {
                    onExpandTreeState(class_.gid)
                }}
                onCollapse={() => {
                    onCollapseTreeState(class_.gid)
                }}
            >
                <span className="cc-inline">
                    <Text>
                        <span>{classname}</span>
                        {state === 'expanded' ? undefined : (
                            <span
                                className="cc-dimmed cc-note"
                                style={{ paddingLeft: 10 }}
                            >
                                {app.pluralized_text(
                                    {
                                        0: 'No fields',
                                        1: '{count} field',
                                        many: '{count} fields',
                                    },
                                    class_.fields ? class_.fields.length : 0
                                )}
                            </span>
                        )}
                    </Text>
                </span>
            </TreeItem>
            <div style={{ paddingLeft: 18 }}>
                {Fieldslabel}
                {Fields}
            </div>
        </div>
    )
})

export const FieldsInspector = observer(function FieldsInspector({
    worksheet,
    viewkey,
}) {
    const { app } = useStore()
    const visited_gids = []

    const definition = worksheet.definitions.get(worksheet.record.definition)

    const selectedclassgid = worksheet.selected.property.classgid
    const selectedclassfieldgid = worksheet.selected.property.classgid
        ? worksheet.selected.property.fieldgid
        : null
    const selectedfieldgid = worksheet.selected.property.classgid
        ? null
        : worksheet.selected.property.fieldgid

    const setSelected = (classgid, classfieldgid, fieldgid) => {
        worksheet.layoutstore.selectComponent(null)
        worksheet.setSelected(
            worksheet.record,
            classgid,
            fieldgid ? fieldgid : classfieldgid,
            classgid ? classgid : null,
            'Field'
        )
    }

    let initialtreestate = {}
    const classes = definition && definition.classes ? definition.classes.slice() : []
    const fields = definition && definition.fields ? definition.fields.slice() : []
    initialtreestate['fields'] = fields.length ? 'expanded' : 'empty'
    if (classes.length) {
        classes.forEach(
            gid =>
                (initialtreestate[gid] =
                    gid === selectedclassgid ? 'expanded' : 'collapsed')
        )
    }
    const [keepTreeState, setTreeState] = useState(initialtreestate)

    let treeState = { ...keepTreeState }
    for (const [key, value] of Object.entries(initialtreestate)) {
        if (value === 'empty') {
            treeState[key] = value
            continue
        }
        if (key in treeState && treeState[key] === 'empty') {
            treeState[key] = value
            continue
        }
        if (!(key in treeState)) {
            treeState[key] = value
        }
    }

    const onExpandTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'expanded'
        setTreeState(newtreestate)
    }

    const onCollapseTreeState = key => {
        let newtreestate = { ...treeState }
        newtreestate[key] = 'collapsed'
        setTreeState(newtreestate)
    }

    const onClickClass = (e, classgid) => {
        setSelected(
            selectedclassgid === classgid && !selectedclassfieldgid ? null : classgid,
            null,
            null
        )
    }
    const onClickClassField = (classgid, classfieldgid) => {
        if (selectedclassfieldgid === classfieldgid) {
            setSelected(null, null, null)
        } else {
            setSelected(classgid, classfieldgid, null)
        }
    }

    const onClickField = fieldgid => {
        setSelected(null, null, selectedfieldgid === fieldgid ? null : fieldgid)
    }

    // add classes
    const ClassesTree = classes.length ? (
        classes.map(classgid => {
            let class_ = worksheet.classes.get(classgid)
            if (!class_) return null
            return (
                <ClassRow
                    key={class_.gid}
                    class_={class_}
                    worksheet={worksheet}
                    treeState={treeState}
                    selectedclassgid={selectedclassgid}
                    selectedclassfieldgid={selectedclassfieldgid}
                    onClickClass={onClickClass}
                    onExpandTreeState={onExpandTreeState}
                    onCollapseTreeState={onCollapseTreeState}
                    onClickClassField={onClickClassField}
                    visited_gids={visited_gids}
                />
            )
        })
    ) : (
        <div key="classes">
            <TreeItem
                state={'empty'}
                emptyicon={'empty'}
                selected={false}
                indent={0}
                onClick={() => {}}
            >
                {app.text('No classes')}
            </TreeItem>
        </div>
    )

    let FieldsTree = []
    const fieldskey = 'fields'
    let deffields
    const state = treeState[fieldskey]
    if (state === 'expanded') {
        deffields = fields.length ? (
            <PropertyTable
                className="cc-block"
                record={worksheet.record}
                fields={fields}
                fromClass={null}
                selectedfieldgid={selectedfieldgid}
                onClickField={onClickField}
                worksheet={worksheet}
                visited_gids={visited_gids}
            />
        ) : undefined
    }
    FieldsTree.push(
        <div key={fieldskey}>
            {!fields.length ? (
                <TreeItem
                    key={fieldskey}
                    state={state}
                    emptyicon={'empty'}
                    selected={false}
                    indent={0}
                    onClick={() => {}}
                    onExpand={() => {
                        onExpandTreeState(fieldskey)
                    }}
                    onCollapse={() => {
                        onCollapseTreeState(fieldskey)
                    }}
                >
                    {app.text('No fields')}
                </TreeItem>
            ) : undefined}
            <div style={{ paddingLeft: 18 }}>{deffields}</div>
        </div>
    )
    return (
        <VView grow className="panel properties">
            <Header>{app.text('Classes')}</Header>
            {ClassesTree}
            <Spacer size={5} />
            <Header>{app.text('Fields')}</Header>
            {FieldsTree}
        </VView>
    )
})
