//
// TableClassListField
//
// An implementation for ClassListField.

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { ClasslistFieldRecords } from '../../../../stores/data/ClasslistFieldRecords'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { DragDropClasslistFieldRow } from '../../../../dragdrop/DragDropClasslistFieldRow'

import { ClassListFieldMenu } from '../../../../menus'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

import { Text, Icon } from '../../../../components'
import { FieldLabel } from '.'
import { TypedField, RecursionMessage } from '../layoutbuilders'

export const TableClassListField = observer(function TableClassListField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        propertytable,
        visited_gids,
        ...other
    } = props

    const { data, app } = useStore()
    const containerRef = useRef()

    const ClassListFieldContextMenu = useMousePopover(ClassListFieldMenu)

    let classes = 'cc-Field cc-ClassListField ws-table'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        (record.localized_fields && record.localized_fields.get(field.name)) || []
    const classlistfieldvalue = new ClasslistFieldRecords(record, field, fieldvalue)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const class_gid = field ? field.options.get('class') : undefined
    const class_ = class_gid ? data.classes.get(class_gid) : undefined
    const layout = class_ ? data.layouts.get(class_.layout) : undefined
    const subfields = class_
        ? class_.fields.map(field_gid => data.fields.get(field_gid))
        : undefined

    const [selectedItemIndex, setSelectedItemIndex] = useState(null) // an index
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    if (!class_ || !layout) {
        return <Text>{app.text('No class or layout assigned.')}</Text>
    }

    if (
        visited_gids.includes(class_.gid) ||
        visited_gids.includes(layout.gid) ||
        visited_gids.includes(class_.original) ||
        visited_gids.includes(layout.original)
    ) {
        return <RecursionMessage component={field} />
    }
    // return null

    const onAddItemBefore = before_index => {
        classlistfieldvalue.newItemBefore(before_index)
        classlistfieldvalue.commitIfModified()
    }

    const onRemoveItem = index => {
        classlistfieldvalue.removeItem(index)
        classlistfieldvalue.commitIfModified()
    }

    const onRemoveAllItems = () => {
        classlistfieldvalue.removeAllItems()
        classlistfieldvalue.commitIfModified()
    }

    const onClassListFieldAction = action => {
        const actions = {
            add_item: onAddItemBefore,
            remove_item: onRemoveItem,
            remove_all: onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onClassListFieldAction: unhandled action '${action}'`)
            return
        }
        ClassListFieldContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }

        classlistfieldvalue.moveItem(other_index, before_index)
        classlistfieldvalue.commitIfModified()
    }

    const onShowClassListFieldMenu = (e, index) => {
        if (index === null) {
            onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        ClassListFieldContextMenu.onShow(e)
    }

    let TableHeader = subfields
        ? subfields.map(subfield => {
              let headerclasses = 'ws-table-cell ws-table-cell-' + subfield.name
              return (
                  <td key={'field' + subfield.gid} className={headerclasses}>
                      <Text>
                          <FieldLabel inline field={subfield} worksheet={worksheet} />
                      </Text>
                  </td>
              )
          })
        : []
    if (enabled) {
        TableHeader.unshift(
            <td key={'action'} className="ws-table-cell ws-table-cell-_action_"></td>
        )
        TableHeader.push(
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_"></td>
        )
    }
    TableHeader = <tr>{TableHeader}</tr>

    const update_visited_gids = [
        component ? component.gid : undefined,
        component ? component.original : undefined,
        layout.gid,
        layout.original,
        class_.gid,
        class_.original,
    ]

    let TableRows
    if (classlistfieldvalue.records.length) {
        TableRows = classlistfieldvalue.records.map((classlistfieldrecord, index) => {
            if (!classlistfieldrecord) return null

            if (!class_) {
                let cellclasses = 'ws-table-cell ws-table-cell-_noclass_'
                return (
                    <tr key={'field' + field.gid + '.' + index}>
                        <td colSpan={subfields.length + 1} className={cellclasses}>
                            <Text>{app.text('No class or layout assigned.')}</Text>
                        </td>
                    </tr>
                )
            }

            let TableCells = subfields.map(subfield => {
                let cellclasses = 'ws-table-cell ws-table-cell-' + subfield.name
                let component = {}
                if (subfield.type === 'barcode') {
                    component['style'] = 'plaintext'
                }
                const FieldView = (
                    <TypedField
                        field={subfield}
                        record={classlistfieldrecord}
                        editable={enabled}
                        force_readonly={force_readonly}
                        worksheet={worksheet}
                        active_class={class_}
                        component={component}
                        visited_gids={[
                            ...visited_gids,
                            ...update_visited_gids.filter(gid => !!gid),
                        ]}
                    />
                )
                return (
                    <td key={'field' + subfield.gid} className={cellclasses}>
                        {FieldView}
                    </td>
                )
            })

            if (enabled) {
                TableCells.unshift(
                    <td
                        key={'action'}
                        className="ws-table-cell ws-table-cell-_action_"
                        onClick={e => onShowClassListFieldMenu(e, index)}
                        onContextMenu={e => onShowClassListFieldMenu(e, index)}
                    >
                        <Icon size="text" name="ellipsis" />
                    </td>
                )
                TableCells.push(
                    <td key={'handle'} className="ws-table-cell ws-table-cell-_action_">
                        <Icon size={1} name="reorder" />
                    </td>
                )
            }

            // return <tr key={'field' + field.gid + '.' + index}>{TableCells}</tr>
            return (
                <DragDropClasslistFieldRow
                    key={'field' + field.gid + '.' + index}
                    className={classes}
                    field={field}
                    item_index={index}
                    direction="vertical"
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    {TableCells}
                </DragDropClasslistFieldRow>
            )
            // return (
            //     <DragDropClasslistFieldItem
            //         className={classes}
            //         key={index}
            //         field={field}
            //         item_index_list={item_index_list}
            //         item_index={index}
            //         direction={dragdropdirection}
            //         disabled={!enabled}
            //         onDrop={onDrop}
            //     >
            //         <div className="ws-row">{ClassView}</div>
            //     </DragDropClasslistFieldItem>
            // )
        })
    }

    if (enabled) {
        let classes = 'ws-table-cell cc-dimmed'
        const AddTableRow = (
            <tr key={'field' + field.gid + '.add'}>
                <td className={classes + ' ws-table-cell-_action_'}>
                    <Icon
                        size={1}
                        name="plus"
                        onClick={e => onShowClassListFieldMenu(e, null)}
                    />
                </td>
                <td
                    colSpan={subfields.length}
                    className={classes + ' ws-table-cell-_add-text_'}
                    style={{ textAlign: 'left' }}
                >
                    <div onClick={e => onShowClassListFieldMenu(e, null)}>
                        {app.text('Add new item')}
                    </div>
                </td>
            </tr>
        )

        if (!TableRows) {
            TableRows = AddTableRow
        } else {
            TableRows.push(AddTableRow)
        }
    }

    return (
        <div>
            <ClassListFieldContextMenu.Panel onAction={onClassListFieldAction} />
            <div className={classes} {...other}>
                <table className="ws-table">
                    <thead>{TableHeader}</thead>
                    <tbody ref={containerRef}>{TableRows}</tbody>
                </table>
            </div>
            <ValidationMessage validation={validation} />
        </div>
    )
})
