//
// TextValues lab
//
// For TextValues dev (values options for textline/list fields)

import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { HView, VView } from '../../appview'
import {
    KeyValueListManager,
    Toggle,
    KeyValueInput,
    KeyValueListInput,
} from '../../components'
import { prettyprint } from '../../utils/utils'

const textinputstyle = {
    border: '1px solid #eaeaeb',
    fontSize: '14px',
}

export const TextValues = observer(function TextValues() {
    const renderkeyprefix = 'hoi'
    const languages = ['en', 'nl']

    const [language, setLanguage] = useState('nl')
    const onChangeLanguage = newLanguage => {
        setLanguage(newLanguage)
    }

    const [key, setKey] = useState('left')
    const onChangeKey = newKey => {
        setKey(newKey)
    }
    const onBlurKey = newKey => {
        setKey(newKey)
    }

    const [keys, setKeys] = useState(['left'])
    const onChangeKeys = newKeys => {
        setKeys(newKeys)
    }
    const onBlurKeys = newKeys => {
        setKeys(newKeys)
    }

    const [hasLabels, setHasLabels] = useState(true)
    const onChangeHasLabels = newValue => {
        setHasLabels(newValue)
    }

    const [restrictToValues, setRestrictToValues] = useState(true)
    const onToggleRestrictToValues = newValue => {
        setRestrictToValues(!restrictToValues)
    }

    const [values, setValues] = useState([
        { key: 'left', en: 'Left', nl: 'Links' },
        { key: 'center', en: 'Centered', nl: 'Gecentreerd' },
        { key: 'right', en: 'Right', nl: 'Rechts' },
    ])
    const onChangeValues = newValues => {
        setValues(newValues)
    }
    const onBlurValues = newValues => {
        setValues(newValues)
    }

    const copypastevalues = [
        { key: 'left', en: 'Left', nl: 'Links' },
        { key: 'center', en: 'Centered', nl: 'Gecentreerd' },
        { key: 'right', en: 'Right', nl: 'Rechts' },
    ]

    return (
        <HView style={{ padding: 20 }} gap={20}>
            <VView gap={20}>
                <h3>
                    Click the key icon to switch between <br />
                    key-only and key-with-label
                </h3>
                <KeyValueListManager
                    hasLabels={hasLabels}
                    keyvaluelist={values}
                    language={language}
                    languages={languages}
                    renderkey={renderkeyprefix + '.values'}
                    onChangeHasLabels={onChangeHasLabels}
                    onChange={onChangeValues}
                    onBlur={onBlurValues}
                />
                <div>
                    restrict to values&nbsp;&nbsp;
                    <Toggle
                        onChange={onToggleRestrictToValues}
                        value={restrictToValues}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                        onClick={e => onChangeLanguage(e, 'en')}
                        style={{
                            textDecoration: language === 'en' ? 'underline' : 'none',
                        }}
                    >
                        en
                    </span>
                    &nbsp;&nbsp;
                    <span
                        onClick={e => onChangeLanguage(e, 'nl')}
                        style={{
                            textDecoration: language === 'nl' ? 'underline' : 'none',
                        }}
                    >
                        nl
                    </span>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold' }}>textline</div>
                    <KeyValueInput
                        value={key}
                        values={values}
                        hasLabels={hasLabels}
                        restrictToValues={restrictToValues}
                        language={language}
                        onChangeKey={onChangeKey}
                        onBlurKey={onBlurKey}
                        style={textinputstyle}
                    />
                    <div style={{ textAlign: 'right' }}>{key}</div>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold' }}>textlist</div>
                    <KeyValueListInput
                        listvalue={keys}
                        values={values}
                        hasLabels={hasLabels}
                        restrictToValues={restrictToValues}
                        language={language}
                        onChangeKeys={onChangeKeys}
                        onBlurKeys={onBlurKeys}
                    />
                    <div style={{ textAlign: 'right' }}>{keys.join(', ')}</div>
                </div>
            </VView>
            <VView>
                <pre>{prettyprint(values)}</pre>
            </VView>
            <VView>
                <div style={{ fontWeight: 'bold' }}>keys</div>
                <pre className="user-select">
                    {copypastevalues.map(value => value['key']).join('\n')}
                </pre>
            </VView>
            <VView>
                <div style={{ fontWeight: 'bold' }}>en values</div>
                <pre className="user-select">
                    {copypastevalues.map(value => value['en']).join('\n')}
                </pre>
            </VView>
            <VView>
                <div style={{ fontWeight: 'bold' }}>nl values</div>
                <pre className="user-select">
                    {copypastevalues.map(value => value['nl']).join('\n')}
                </pre>
            </VView>
            <VView>
                <div style={{ fontWeight: 'bold' }}>key\tvalue en</div>
                <pre className="user-select">
                    {copypastevalues
                        .map(value => value['key'] + '\t' + value['en'])
                        .join('\n')}
                </pre>
            </VView>
            <VView>
                <div style={{ fontWeight: 'bold' }}>key\tvalue nl</div>
                <pre className="user-select">
                    {copypastevalues
                        .map(value => value['key'] + '\t' + value['nl'])
                        .join('\n')}
                </pre>
            </VView>
        </HView>
    )
})
