//
// Icon
//
// The Icon shows an svg icon.
// We have a couple hand-made ones ready, specify them by name.
// For drawing, icons are square, and include padding. Draw them inside a 80x80 box,
// centered, and aim to fill a 60x60 area.
// By default, we use a thicker stroke-width with rounded caps and joins.
// Use cc-thin to specify thin lines, and cc-fill to add a background.
//
// rect x,y,w,h
// circle cx,cy,r
// ellipse cx,cy,rx,ry
// line x1,y1,x2,y2
// polyline points
// polygon points
// path d
//      Mm = move x,y
//      Ll = line x,y
//      Hh = horizontal line x
//      Vv = vertical line y
//      Cc Ss = bezier curve (s version mirrors first handle with last of previous point)
//      Qq = quadratice bezier curve
//      Aa = elliptical arc curve
//      Zz = close
//      (uppercase: absolute coord, lowercase: relative distance)

import React from 'react'

// helper function when drawing icons
// absolute coordinates, 0 degrees means right edge of circle
//
//  console.log(svgarc(40, 50, 15, 180, 60))
//  console.log(svgarc(40, 50, 15, 300, 60))
function svgarc(centerX, centerY, radius, startAngleInDegrees, sweepAngleInDegrees) {
    const decimals = 2
    const startAngleInRadians = (startAngleInDegrees * Math.PI) / 180.0
    const x1 = (centerX + radius * Math.cos(startAngleInRadians)).toFixed(decimals)
    const y1 = (centerY + radius * Math.sin(startAngleInRadians)).toFixed(decimals)
    const endAngleInRadians =
        ((startAngleInDegrees + sweepAngleInDegrees) * Math.PI) / 180.0
    const x2 = (centerX + radius * Math.cos(endAngleInRadians)).toFixed(decimals)
    const y2 = (centerY + radius * Math.sin(endAngleInRadians)).toFixed(decimals)
    const sweep = sweepAngleInDegrees > 0 ? 1 : 0
    return `M${x1},${y1} A${radius},${radius} 0 0,${sweep} ${x2},${y2}`
}
svgarc(0, 0, 1, 0, 360) // just silence the warning

const unicatIcon = (
    <>
        <g transform="translate(1,-1)">
            <g transform="rotate(-18 40,40)">
                <path
                    className="back-l"
                    vectorEffect="non-scaling-stroke"
                    d="M40,32 L28,22 C22,17 17,15 17,25
                       L17,47 a23,23 0 0,0 23,23 z"
                    style={{ fill: '#fff', stroke: 'none' }}
                />
                <path
                    className="back-r cc-fill"
                    vectorEffect="non-scaling-stroke"
                    d="M40,47 m0,23 a23,23 0 0,0 23,-23
                       V25 C63,15 58,17 52,22 L40,32z"
                    style={{ fill: '#e2e7e8', stroke: 'none' }}
                />
                <path
                    className="outercat"
                    vectorEffect="non-scaling-stroke"
                    d="M40,47 m-23,0 a23,23 0 1,0 46,0
                       V25 C63,15 58,17 52,22 L40,32
                       L28,22 C22,17 17,15 17,25 z"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="innercat"
                    vectorEffect="non-scaling-stroke"
                    d="M40,47 m-13,0 a13,13 0 1,0 26,0
                       V35 C53,33 52,33 46,39 L40,44
                       L34,39 C28,33 27,33 27,35 z"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="whisker-lt"
                    vectorEffect="non-scaling-stroke"
                    d="M17.2,41 l9.6,3"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="whisker-lb"
                    vectorEffect="non-scaling-stroke"
                    d="M17.2,49 h9.6"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="whisker-rt"
                    vectorEffect="non-scaling-stroke"
                    d="M62.8,41 l-9.6,3"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="whisker-rb"
                    vectorEffect="non-scaling-stroke"
                    d="M62.8,49 h-9.6"
                    style={{ stroke: '#1a8b99' }}
                />
                <path
                    className="spine cc-thin"
                    vectorEffect="non-scaling-stroke"
                    d="M40,32 V70"
                    style={{ stroke: '#1a8b99' }}
                />
            </g>
        </g>
    </>
)

const noIcon = (
    <>
        <g className="cc-grid">
            <path
                vectorEffect="non-scaling-stroke"
                d="M1.5,1.5 L1.5,78.5 L78.5,78.5 L78.5,1.5 z"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M10,10 L10,70 L70,70 L70,10 z"
                className="cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M0,10 h80 M0,20 h80 M0,30 h80 M0,40 h80 M0,50 h80 M0,60 h80 M0,70 h80 M10,0 v80 M20,0 v80 M30,0 v80 M40,0 v80 M50,0 v80 M60,0 v80 M70,0 v80"
                className="cc-hair"
            />
        </g>
    </>
)

const productIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,30 L40,10 L70,20 L70,50 L40,70 L10,60 z"
        />
        <path vectorEffect="non-scaling-stroke" d="M40,40 L10,30" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M40,40 L70,20" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M40,40 L40,70" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M50,42 L60,35.333" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M20,33.333 L20,43.333 L30,46.666 L30,36.666 L60,16.666 L50,13.333 z"
            className="cc-fill cc-thin"
        />
    </>
)

const schemaIcon = (
    <>
        <g transform="translate(3 -3)">
            <g>
                <path
                    vectorEffect="non-scaling-stroke"
                    d="
                   M24,20 H66
                   M34,30 H66
                   M44,40 H66
                   M54,50 H66
                   M64,60 H66
                   M20,14 V16
                   M30,14 V26
                   M40,14 V36
                   M50,14 V46
                   M60,14 V56
                   "
                    className="cc-hair"
                />
            </g>
            <g
                style={{
                    transform: 'scale(0.9)',
                    transformOrigin: '10px 70px',
                }}
            >
                <path vectorEffect="non-scaling-stroke" d="M10,10 L70,70 L10,70 z" />
                <path vectorEffect="non-scaling-stroke" d="M20,34 L46,60 L20,60 z" />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M10,20 h4 M10,30 h4 M10,40 h4 M10,50 h4 M10,60 h4
                   M10,25 h2 M10,35 h2 M10,45 h2 M10,55 h2 M10,65 h2
                   M20,70 v-4 M30,70 v-4 M40,70 v-4 M50,70 v-4 M60,70 v-4
                   M15,70 v-2 M25,70 v-2 M35,70 v-2 M45,70 v-2 M55,70 v-2
                  "
                    className="cc-thin cc-min-4"
                />
            </g>
        </g>
    </>
)

const imageIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,15 L10,65 L70,65 L70,15 z" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M15,20 L15,60 L65,60 L65,20 z"
            className="cc-thin cc-min-2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M15,50 L15,60 L65,60 L65,55 L50,45 L43,49 L30,40 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M52,32 m-4,0 a4,4 0 1,0 8,0 a4,4 0 1,0 -8,0 z"
            className="cc-fill cc-thin"
        />
    </>
)

const importexportIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,35 h60 v35 h-60 z"
            className="cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M32,10 v45" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M32,60 l8,-15 l-16,0 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M52,15 v45" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M52,10 l8,15 l-16,0 z"
            className="cc-fill cc-thin"
        />
    </>
)

const settingsIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M41.05,10.02 A30,30 0 0,1 49.27,11.47
               L48.62,18.67 A23,23 0 0,1 55.69,23.18
               L61.94,19.54 A30,30 0 0,1 66.73,26.38
               L61.17,31.01 A23,23 0 0,1 62.99,39.20
               L69.98,41.05 A30,30 0 0,1 68.53,49.27
               L61.33,48.62 A23,23 0 0,1 56.82,55.69
               L60.46,61.94 A30,30 0 0,1 53.62,66.73
               L48.99,61.17 A23,23 0 0,1 40.80,62.99
               L38.95,69.98 A30,30 0 0,1 30.73,68.53
               L31.38,61.33 A23,23 0 0,1 24.31,56.82
               L18.06,60.46 A30,30 0 0,1 13.27,53.62
               L18.83,48.99 A23,23 0 0,1 17.01,40.80
               L10.02,38.95 A30,30 0 0,1 11.47,30.73
               L18.67,31.38 A23,23 0 0,1 23.18,24.31
               L19.54,18.06 A30,30 0 0,1 26.38,13.27
               L31.01,18.83 A23,23 0 0,1 39.20,17.01
               z
              "
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,40 m-20,0 a20,20 0 1,0 40,0 a20,20 0 1,0 -40,0 z"
            className="cc-thin cc-min-1"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,40 m-12,0 a12,12 0 1,0 24,0 a12,12 0 1,0 -24,0 z"
        />
    </>
)
// console.log(svgarc(40, 40, 30, 272, 16))
// console.log(svgarc(40, 40, 23, 292, 21))
// console.log(svgarc(40, 40, 30, 317, 16))
// console.log(svgarc(40, 40, 23, 337, 21))
// console.log(svgarc(40, 40, 30, 2, 16))
// console.log(svgarc(40, 40, 23, 22, 21))
// console.log(svgarc(40, 40, 30, 47, 16))
// console.log(svgarc(40, 40, 23, 67, 21))
// console.log(svgarc(40, 40, 30, 92, 16))
// console.log(svgarc(40, 40, 23, 112, 21))
// console.log(svgarc(40, 40, 30, 137, 16))
// console.log(svgarc(40, 40, 23, 157, 21))
// console.log(svgarc(40, 40, 30, 182, 16))
// console.log(svgarc(40, 40, 23, 202, 21))
// console.log(svgarc(40, 40, 30, 227, 16))
// console.log(svgarc(40, 40, 23, 247, 21))

const searchIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M33,33 m-23,0 a23,23 0 1,0 46,0 a23,23 0 1,0 -46,0 z"
            className="cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M50,50 l20,20" />
    </>
)

// const filterIconAppleStyle = (
//     <>
//         <path
//             vectorEffect="non-scaling-stroke"
//             d="M40,40 m-25,0 a25,25 0 1,0 50,0 a25,25 0 1,0 -50,0 z"
//             className="cc-thin"
//         />
//         <path
//             vectorEffect="non-scaling-stroke"
//             d="M40,33 m-17,0 h34
//                M40,42 m-12,0 h24
//                M40,51 m-7,0 h14
//                "
//         />
//     </>
// )

const filterIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M29,33 h22 l-7.5,12.55 v17.5 l-7,-2.5 v-15z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M20,18 h40 l-16.5,27.5 v17.5 l-7,-2.5 v-15z"
        />
    </>
)

const treeIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M15,20 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M35,40 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M35,60 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M15,30 v30 h10"
            className="cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M15,40 h10" className="cc-thin" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M30,17 v6 h20 v-6 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M50,37 v6 h20 v-6 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M50,57 v6 h20 v-6 z"
            className="cc-fill cc-thin"
        />
    </>
)

const bookmarkIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,20 a4,4 0 0,1 4,-4 h22 a4,4 0 0,1 4,4 v50 l-15,-8 l-15,8 z"
        />
    </>
)

const bookmarkshareIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,20 a4,4 0 0,1 4,-4 h22 a4,4 0 0,1 4,4 v50 l-15,-8 l-15,8 z"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,28 v20 M40,28 l-8,8 M40,28 l8,8"
        />
    </>
)

const emptyIcon = <></>

const expandedIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M20,35 L40,65 L60,35 z"
            className="cc-fill cc-thin"
        />
    </>
)

const collapsedIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,30 L55,50 L25,70 z"
            className="cc-fill cc-thin"
        />
    </>
)

const linkIcon = (
    <>
        <g transform="translate(0,8)">
            <g transform="rotate(-90 40,40)">
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M20,40 m-10,0 a10,10 0 1,0 20,0 a10,10 0 1,0 -20,0 z"
                    className="cc-fill"
                />
                <path vectorEffect="non-scaling-stroke" d="M30,40 h20" />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M60,40 m-10,0 a10,10 0 1,0 20,0 a10,10 0 1,0 -20,0 z"
                />
            </g>
        </g>
    </>
)

const bulletIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,53 m-10,0 a10,10 0 1,0 20,0 a10,10 0 1,0 -20,0 z"
            className="cc-fill cc-thin"
        />
    </>
)

const focusIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,53 m-10,0 a10,10 0 1,0 20,0 a10,10 0 1,0 -20,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,53 m-16,0 a16,16 0 1,0 32,0 a16,16 0 1,0 -32,0 z"
            className="cc-thin"
        />
    </>
)

const ellipsisIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M20,40 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,40 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M60,40 m-5,0 a5,5 0 1,0 10,0 a5,5 0 1,0 -10,0 z"
            className="cc-fill cc-thin"
        />
    </>
)

const labIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M30,10 c3,0 5,2 5,5 l0,15 c0,5 -15,20 -15,30 c0,5 5,10 10,10 l20,0 c5,0 10,-5 10,-10 c0,-10 -15,-25 -15,-30 l0,-15 c0,-3 2,-5 5,-5"
        />
        <path vectorEffect="non-scaling-stroke" d="M30,10 l20,0" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M30,40 l20,0" className="cc-thin" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M46,53 m-4,0 a4,4 0 1,0 8,0 a4,4 0 1,0 -8,0 z"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M31,55 m-2,0 a2,2 0 1,0 4,0 a2,2 0 1,0 -4,0 z"
            className="cc-thin cc-min-2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M41,62 m-2,0 a2,2 0 1,0 4,0 a2,2 0 1,0 -4,0 z"
            className="cc-thin cc-min-2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M37,60 m-1,0 a1,1 0 1,0 2,0 a1,1 0 1,0 -2,0 z"
            className="cc-thin cc-min-1"
        />
    </>
)

const plusIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M40,20 v40 M20,40 h40" />
        <path vectorEffect="non-scaling-stroke" d="M10,15 " />
    </>
)

const plusButtonIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M40,25 v30 M25,40 h30" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,18 a8,8 0 0,1 8,-8 h44 a8,8 0 0,1 8,8 v44 a8,8 0 0,1 -8,8 h-44 a8,8 0 0,1 -8,-8 z"
        />
    </>
)

const removeIcon = (
    <>
        <g transform="rotate(45 40,40)">
            <path vectorEffect="non-scaling-stroke" d="M40,20 v40 M20,40 h40" />
        </g>
    </>
)

const checkmarkIcon = (
    <>
        <g transform="rotate(35 36,50)">
            <path
                vectorEffect="non-scaling-stroke"
                d="M36,50 v-40 h10 v50 h-30 v-10z"
            />
        </g>
    </>
)

const checkrowIcon = (
    <>
        <g transform="rotate(35 20,44)">
            <path vectorEffect="non-scaling-stroke" d="M12,44 h8 v-14" />
        </g>
        <path
            vectorEffect="non-scaling-stroke"
            d="M38,38 v4 h35 v-4 z"
            className="cc-fill cc-thin"
        />
    </>
)

const xrowIcon = (
    <>
        <g transform="rotate(45 20,40)">
            <path vectorEffect="non-scaling-stroke" d="M11,40 h18 M20,31 v18" />
        </g>
        <path
            vectorEffect="non-scaling-stroke"
            d="M38,38 v4 h35 v-4 z"
            className="cc-fill cc-thin"
        />
    </>
)

const navigateIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,30 l30,20 l-30,20 v-15 h-15 v-10 h15 z"
        />
    </>
)

const pinIcon = (
    <>
        <g transform="rotate(15 40 40)">
            <path vectorEffect="non-scaling-stroke" d="M40,50 v20" />
            <path
                vectorEffect="non-scaling-stroke"
                d="M25,50 A15,15 0 0,1 32.5,37 L31,20 h18 L47.5,37 A15,15 0 0,1 55,50 z"
            />
        </g>
    </>
)

const folderIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,25 v40 h60 v-40 h-30 l-5,-10 h-20 z"
        />
        <path className="cc-thin" vectorEffect="non-scaling-stroke" d="M10,25 h30" />
    </>
)

const docIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M20,15 v50 h40 v-35 l-15,-15 z" />
        <path
            className="cc-thin"
            vectorEffect="non-scaling-stroke"
            d="M45,15 v15 h15"
        />
    </>
)

const zipIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M44,65 h16 v-35 l-15,-15 h-25 v50 h16"
        />
        <path
            className="cc-thin"
            vectorEffect="non-scaling-stroke"
            d="M45,15 v15 h15"
        />
        <path className="cc-thin" vectorEffect="non-scaling-stroke" d="M40,15 V53" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M38,18 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4 m-4,4 h4"
        />
        <path
            className="cc-thin cc-fill"
            vectorEffect="non-scaling-stroke"
            d="M36,53 h8 l2,10 a-6,6 0 0,1 -12,0z
               M40,63 m-3,0 a3,3 0 1,0 6,0 a3,3 0 1,0 -6,0 z"
        />
    </>
)

const movieIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M15,20 v40 h50 v-40 z" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M18,25 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M18,55 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2 m5,0 h2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M34,30 l15,10 l-15,10 z"
            className="cc-fill cc-thin"
        />
    </>
)

const layoutIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,10 h60 v60 h-60 z" />
        <path
            className="cc-thin"
            vectorEffect="non-scaling-stroke"
            d="M10,26 h60 M40,26 v28 M10,54 h60"
        />
    </>
)

const verticalIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,10 h60 v12 h-60 z M10,34 h60 v12 h-60 z M10,58 h60 v12 h-60 z"
        />
    </>
)

const horizontalIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,10 v60 h12 v-60 z M34,10 v60 h12 v-60 z M58,10 v60 h12 v-60 z"
        />
    </>
)

const tilesIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,10 v24 h24 v-24 z M46,10 v24 h24 v-24 z M10,46 v24 h24 v-24 z M46,46 v24 h24 v-24 z"
        />
    </>
)

const spacerIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,40 h60 M20,34 L10,40 L20,46 M60,34 L70,40 L60,46"
        />
    </>
)

const dividerIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,40 h60" />
    </>
)

const textIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,15 L10,65 L70,65 L70,15 z" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,25 v30 M32,55 h16 M25,30 v-5 h30 v5"
        />
    </>
)

const tableIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,15 v50 h60 v-50 z M10,29 h60" />
        <path
            vectorEffect="non-scaling-stroke"
            className="cc-thin"
            d="M30,15 v50 M50,15 v50"
        />
        <path
            vectorEffect="non-scaling-stroke"
            className="cc-thin"
            d="M10,41 h60 M10,53 h60"
        />
    </>
)

const rowsIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,10 v16 h16 v-16 z M10,32 v16 h16 v-16 z M10,54 v16 h16 v-16 z"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M38,16 v4 h35 v-4 z M38,38 v4 h35 v-4 z M38,60 v4 h35 v-4 z"
            className="cc-fill cc-thin"
        />
    </>
)

const definitionIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,19v6 h17 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M10,31 h27 v12 h-27 z" />
        <path vectorEffect="non-scaling-stroke" d="M10,49 h27 v12 h-27 z" />

        <path
            vectorEffect="non-scaling-stroke"
            d="M43,19v6 h17 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M43,31 h27 v12 h-27 z" />
        <path vectorEffect="non-scaling-stroke" d="M43,49 h27 v12 h-27 z" />
    </>
)

const classIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,19v6 h35 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M10,31 h60 v12 h-60 z" />
        <path vectorEffect="non-scaling-stroke" d="M10,49 h60 v12 h-60 z" />
    </>
)

const fieldIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,32 v6 h35 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M10,44 h60 v12 h-60 z" />
    </>
)

const fieldlistIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,10v6 h35 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M10,22 h60 v12 h-60 z" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,46 v6 h35 v-6 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M10,58 h60 v12 h-60 z" />
    </>
)

const backupIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M15.00,40.00 A25,25 0 1,1 22.32,57.68"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M15,50 l5,-10 l-10,0 z"
            className="cc-fill cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M40,22 V40 L50,50" />
    </>
)

const refreshIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M65.00,40.00 A25,25 0 1,0 57.68,57.68"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M65,50 l5,-10 l-10,0 z"
            className="cc-fill cc-thin"
        />
    </>
)

const barcodeIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M10,20 v43" className="cc-thin" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M12,20 v43"
            className="cc-thin cc-min-2"
        />

        <path vectorEffect="non-scaling-stroke" d="M15,20 v35" className="cc-thin" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M18,20 v35 h2 v-35 z"
            className="cc-thin cc-fill"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,20 v35"
            className="cc-thin cc-min-1"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M29,20 v35 h2 v-35 z"
            className="cc-thin cc-fill"
        />
        <path vectorEffect="non-scaling-stroke" d="M35,20 v35" className="cc-thin" />

        <path
            vectorEffect="non-scaling-stroke"
            d="M39,20 v43"
            className="cc-thin cc-min-2"
        />
        <path vectorEffect="non-scaling-stroke" d="M41,20 v43" className="cc-thin" />

        <path
            vectorEffect="non-scaling-stroke"
            d="M45,20 v35 h2 v-35 z"
            className="cc-thin cc-fill"
        />
        <path vectorEffect="non-scaling-stroke" d="M50,20 v35" className="cc-thin" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M55,20 v35 h2 v-35 z"
            className="cc-thin cc-fill"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M60,20 v35"
            className="cc-thin cc-min-2"
        />
        <path vectorEffect="non-scaling-stroke" d="M65,20 v35" className="cc-thin" />

        <path
            vectorEffect="non-scaling-stroke"
            d="M68,20 v43"
            className="cc-thin cc-min-2"
        />
        <path vectorEffect="non-scaling-stroke" d="M70,20 v43" className="cc-thin" />
    </>
)

const channelsIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M30,18 a10,10 0 1,0 0,20 h20 a10,10 0 1,0 0,-20 z"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M50,28 m0,-7 a7,7 0 1,0 0,14 a7,7 0 1,0 0,-14 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M30,45 a10,10 0 1,0 0,20 h20 a10,10 0 1,0 0,-20 z"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M50,55 m0,-7 a7,7 0 1,0 0,14 a7,7 0 1,0 0,-14 z"
            className="cc-fill cc-thin"
        />
    </>
)

const toggleIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,30 a15,15 0 1,0 0,30 h30 a15,15 0 1,0 0,-30 z"
            className="xcc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M54,45 m0,-11 a11,11 0 1,0 0,22 a11,11 0 1,0 0,-22 z"
            className="cc-fill cc-thin"
        />
    </>
)

const userIcon = (
    <>
        <g>
            <path
                vectorEffect="non-scaling-stroke"
                d="M40,14 a12,12 0 1,0 0,24 a12,12 0 1,0 0,-24"
                className="cc-fill cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M20,48 a8,8 0 0,1 8,-8 h24 a8,8 0 0,1 8,8 v24 h-40 z"
                className="cc-thin cc-min-1"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M28,52 a4,4 0 0,1 4,-4 h16 a4,4 0 0,1 4,4 v20 h-24 z"
                className="cc-fill cc-thin"
            />
        </g>
    </>
)

const badgeIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,33 a8,8 0 0,1 8,-8 h44 a8,8 0 0,1 8,8 v24 a8,8 0 0,1 -8,8 h-44 a8,8 0 0,1 -8,-8 z"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M34,27 v-10 h10 v10 z"
            className="cc-fill cc-thin"
        />
        <g>
            <path
                vectorEffect="non-scaling-stroke"
                d="M26,32 a6,6 0 1,0 0,12 a6,6 0 1,0 0,-12"
                className="cc-fill cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M16,50 a4,4 0 0,1 4,-4 h12 a4,4 0 0,1 4,4 v10 h-20 z"
                className="cc-thin cc-min-4"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M20,52 a2,2 0 0,1 2,-2 h8 a2,2 0 0,1 2,2 v8 h-12 z"
                className="cc-fill cc-thin"
            />
        </g>
        <path
            vectorEffect="non-scaling-stroke"
            d="M44,36 v4 h18 v-4 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M44,46 v4 h18 v-4 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M44,56 v4 h18 v-4 z"
            className="cc-fill cc-thin cc-min-3"
        />
    </>
)

const keyIcon = (
    <>
        <g transform="translate(10,5)">
            <g transform="rotate(30 40,17)">
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M40,10 a12,12 0 1,0 0,24 a12,12 0 1,0 0,-24"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M40,16 a3,3 0 1,0 0,6 a3,3 0 1,0 0,-6"
                    className="cc-thin cc-min-1"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M46,33 v30 l-6,6 l-6,-6 v-2 l4,-4 l-4,-4 l4,-4 l-4,-4 l4,-4 l-4,-4 v-3"
                />
                <path
                    vectorEffect="non-scaling-stroke"
                    d="M42,68 v-30"
                    className="cc-thin cc-min-4"
                />
            </g>
        </g>
    </>
)

const showIcon = (
    <>
        <g>
            <path
                vectorEffect="non-scaling-stroke"
                d="M40,40 m0,-15 a15,15 0 1,0 0,30 a15,15 0 1,0 0,-30"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M40,40 m0,-5 a5,5 0 1,0 0,10 a5,5 0 1,0 0,-10"
                className="cc-fill cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M10,40 a36,36 0 0,1 60,0 a36,36 0 0,1 -60,0"
            />
        </g>
    </>
)

const hideIcon = (
    <>
        <g>
            <path
                vectorEffect="non-scaling-stroke"
                d="M40,40 m0,-15 a15,15 0 1,0 0,30 a15,15 0 1,0 0,-30"
                className="cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M40,40 m0,-5 a5,5 0 1,0 0,10 a5,5 0 1,0 0,-10"
                className="cc-fill cc-thin"
            />
            <path
                vectorEffect="non-scaling-stroke"
                d="M10,40 a36,36 0 0,1 60,0 a36,36 0 0,1 -60,0"
                className="cc-thin"
            />
            <path vectorEffect="non-scaling-stroke" d="M15,60 L65,20" />
        </g>
    </>
)

const reorderIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M34,25 l6,-10 l6,10 z"
            className="cc-fill cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,32 h30"
            className="cc-hi cc-min-2"
        />
        <path vectorEffect="non-scaling-stroke" d="M25,40 h30" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M25,48 h30"
            className="cc-hi cc-min-2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M34,55 l6,10 l6,-10 z"
            className="cc-fill cc-thin"
        />
    </>
)

const selectionIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,28 h46 v6 m-42,6 h-4 v-12"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M14,34 h48 v6 m-42,6 h-6 v-12"
            className="cc-thin"
        />
        <path vectorEffect="non-scaling-stroke" d="M20,40 h50 v12 h-50 z" />
    </>
)

const sandboxIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,49 h7 m46,0 h7"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,45 v25 h60 v-25"
            className="cc-min-2"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M18,30 l-2,25 h27 l-2,-25 h-5 v4 h-4 v-4 h-5 v4 h-4 v-4 z"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M26.5,55 v-10 a3.5,3.5 0 0,1 7,-0.00 v10 z"
            className="cc-thin cc-min-3"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M29.5,30 v-17 m0,2 l-6,3 l6,3 z"
            className="cc-thin cc-fill"
        />
        <g transform="rotate(10, 54,39)" className="cc-min-3">
            <path
                vectorEffect="non-scaling-stroke"
                d="M49,40 v-5 a7,7 0 0,1 10,-0.00 v5 z"
            />
            <path vectorEffect="non-scaling-stroke" d="M54,32 v-7 m-4,0 h8" />
        </g>
        <path
            vectorEffect="non-scaling-stroke"
            d="M62,64 l2,-25 h-25 l2,25 z"
            className="cc-fill"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M39,39 h25 v7 h-25 z"
            className="cc-fill"
        />
    </>
)

const oopsIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M39,50 C43,70 53,70 57,70
                            S70,67 70,64
                            S50,65 50,44
                            S48,23 45,22
                    C45,15 44.5,14 44,13
                            S44,11 42,11
                            S40,12 40,14
                            S42,16 42,22
                      C37,27 36,28 36,38
                            S32,54 26,54
                            S13,50 11,52
                            S16,65 26,65
                            S37,61 41,57"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M50,44 C51,48 63,36 63,40
                          S58,55 53,54"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,14 C40.1,14.2 41,14 42,14
                              S44,13.2 44,13"
            className="cc-thin cc-min-5"
        />
    </>
)

const deleteIcon = (
    <>
        <path vectorEffect="non-scaling-stroke" d="M25,20 v45 h30 v-45" />
        <path vectorEffect="non-scaling-stroke" d="M32,28 v29" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M40,28 v29" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M48,28 v29" className="cc-thin" />
        <path vectorEffect="non-scaling-stroke" d="M22,20 h36" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M35,20 v-5 h10 v5"
            className="cc-thin"
        />
    </>
)

const bookIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M40,50 a15,15 0 0,0 0,15 M70,30 a15,15 0 0,0 0,15 M10,40 L40,50 L70,30 L55,25"
            className="cc-thin"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,40 L40,20 L68,20 L38,40 z"
            className="cc-fill"
        />
        <path
            vectorEffect="non-scaling-stroke"
            d="M10,40 a15,15 0 0,0 0,15 L40,65 L70,45"
        />
    </>
)

const lockIcon = (
    <>
        <path
            vectorEffect="non-scaling-stroke"
            d="M20,44 a4,4 0 0,1 4,-4 h32 a4,4 0 0,1 4,4 v18 a8,8 0 0,1 -8,8 h-24 a8,8 0 0,1 -8,-8 z"
        />
        <path vectorEffect="non-scaling-stroke" d="M32,40 v-10 a8,8 0 0,1 16,0 v10" />
        <path vectorEffect="non-scaling-stroke" d="M25,40 v-10 a15,15 0 0,1 30,0 v10" />
        <path
            vectorEffect="non-scaling-stroke"
            d="M38,55 a4,4 0 1,1 4,0 v4 a2,2 0 0,1 -4,0 z"
            className="cc-thin cc-fill"
        />
    </>
)

// console.log(svgarc(40, 50, 4, -45, 270))

export const icons = {
    unicat: unicatIcon,
    none: noIcon,
    product: productIcon,
    book: bookIcon,
    schema: schemaIcon,
    image: imageIcon,
    importexport: importexportIcon,
    settings: settingsIcon,
    badge: badgeIcon,
    key: keyIcon,
    tree: treeIcon,
    search: searchIcon,
    bookmark: bookmarkIcon,
    bookmarkshare: bookmarkshareIcon,
    filter: filterIcon,
    empty: emptyIcon,
    expanded: expandedIcon,
    collapsed: collapsedIcon,
    link: linkIcon,
    bullet: bulletIcon,
    focus: focusIcon,
    ellipsis: ellipsisIcon,
    lab: labIcon,
    user: userIcon,
    plus: plusIcon,
    plusbutton: plusButtonIcon,
    remove: removeIcon,
    check: checkmarkIcon,
    navigate: navigateIcon,
    pin: pinIcon,
    folder: folderIcon,
    doc: docIcon,
    zip: zipIcon,
    movie: movieIcon,
    layout: layoutIcon,
    vertical: verticalIcon,
    horizontal: horizontalIcon,
    spacer: spacerIcon,
    text: textIcon,
    divider: dividerIcon,
    table: tableIcon,
    rows: rowsIcon,
    checkrow: checkrowIcon,
    xrow: xrowIcon,
    tiles: tilesIcon,
    definition: definitionIcon,
    class: classIcon,
    field: fieldIcon,
    fieldlist: fieldlistIcon,
    backup: backupIcon,
    refresh: refreshIcon,
    barcode: barcodeIcon,
    channels: channelsIcon,
    toggle: toggleIcon,
    show: showIcon,
    hide: hideIcon,
    reorder: reorderIcon,
    selection: selectionIcon,
    sandbox: sandboxIcon,
    oops: oopsIcon,
    delete: deleteIcon,
    lock: lockIcon,
}

export const Icon = props => {
    let { name, label, size, spin, className, title, ...other } = props
    let classes = 'cc-Icon'
    if (className) classes += ' ' + className
    if (size) classes += ' cc-icon-size' + size
    if (spin) classes += ' cc-icon-spin'
    if (spin === 'quickly') classes += ' cc-icon-spin-quickly'
    if (spin === 'reverse') classes += ' cc-icon-spin-reverse'
    let icon = noIcon
    if (name in icons) {
        icon = icons[name]
    }
    if (!label) label = name
    return (
        <svg
            className={classes}
            aria-label={label}
            role="img"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 80 80"
            {...other}
        >
            {title ? <title>{title}</title> : undefined}
            {icon}
        </svg>
    )
}
