import {
    observable,
    isObservableMap,
    isObservableArray,
    isObservableObject,
} from 'mobx'
import { isPlainObject, mapFrom } from '../../utils/utils'
import { difference } from '../../utils/set'

export const updateInstanceWithData = (
    instance,
    data,
    propertynames,
    deeppropertynames
) => {
    // helper for updating an instance with data that is similarly shaped but comes
    // from e.g. a JSON parse, where the Map type is lost and is given as a plain object
    // for more deeply nested objects that should have deeper observability, like a
    // record's fields, we have deeppropertynames to do a deepUpdate
    propertynames.map(propertyname => {
        if (data.hasOwnProperty(propertyname)) {
            if (instance[propertyname] instanceof Map) {
                if (data[propertyname] === null) {
                    instance[propertyname] = new Map()
                } else {
                    instance[propertyname] = new Map(Object.entries(data[propertyname]))
                }
            } else if (isObservableMap(instance[propertyname])) {
                if (data[propertyname] === null) {
                    instance[propertyname].replace({})
                } else {
                    instance[propertyname].replace(data[propertyname])
                }
            } else if (isObservableArray(instance[propertyname])) {
                if (data[propertyname] === null) {
                    instance[propertyname].replace([])
                } else {
                    instance[propertyname].replace(data[propertyname])
                }
            } else {
                instance[propertyname] = data[propertyname]
            }
        }
        return undefined
    })
    if (deeppropertynames) {
        deeppropertynames.map(propertyname => {
            if (data.hasOwnProperty(propertyname)) {
                instance[propertyname] = _deepUpdate(
                    instance[propertyname],
                    data[propertyname]
                )
            }
            return undefined
        })
    }
}

const _deepUpdate = (instance, data_) => {
    let data = data_
    if (isPlainObject(data) || isObservableObject(data)) {
        data = new Map(Object.entries(data))
    }
    if (data instanceof Map || isObservableMap(data)) {
        return _deepUpdateMap(instance, data)
    }
    if (Array.isArray(data) || isObservableArray(data)) {
        return _deepUpdateArray(instance, data)
    }
    return _deepUpdateValue(instance, data)
}

const _deepUpdateValue = (instance, data) => {
    if (data === null && isObservableMap(instance)) {
        instance.clear()
        return instance
    }
    if (data === null && isObservableArray(instance)) {
        instance.clear()
        return instance
    }
    instance = data
    return instance
}

const _deepUpdateArray = (instance, data) => {
    if (!isObservableArray(instance)) {
        // data type changed
        instance = observable(mapFrom(data))
        return instance
    }
    // update elements
    data.forEach((element, index) => {
        instance[index] = _deepUpdate(instance[index], data[index])
    })
    // remove unmatched elements
    while (data.length < instance.length) {
        instance.pop()
    }
    return instance
}

const _deepUpdateMap = (instance, data) => {
    if (!isObservableMap(instance)) {
        // data type changed
        instance = observable(mapFrom(data))
        return instance
    }
    // update elements
    for (let [key, value] of data) {
        instance.set(key, _deepUpdate(instance.get(key), value))
    }
    // remove unmatched elements
    for (let [key] of instance) {
        if (!data.has(key)) {
            instance.delete(key)
        }
    }
    return instance
}

// Field options `values` looks like
//
// values: [
//     {"key": "r", "en": "Red", "nl": "Rood"},
//     {"key": "g", "en": "Green", "nl": "Groen"},
//     {"key": "b", "en": "Blue", "nl": "Blauw"},
// ]
export const listFromValues = (values, language) => {
    // from a values structure, get a list for a single language
    // or the key if value wasn't specified
    if (!values) return []
    return values.map(value => (language ? value[language] : value['key']))
}

export const listFromValuesWithKeyFallback = (values, language) => {
    // from a values structure, get a list for a single language
    // or the key if value wasn't specified
    if (!values) return []
    return values.map(value =>
        language && value[language].length ? value[language] : value['key']
    )
}

const getAllDefinitionFieldGids = (definition, datastore) => {
    if (!definition) return []
    let field_gids = new Set()
    definition.classes.forEach(classgid => {
        let class_ = datastore.classes.get(classgid)
        if (class_) {
            class_.fields.forEach(fieldgid => {
                field_gids.add(fieldgid)
            })
        }
    })
    definition.fields.forEach(fieldgid => {
        field_gids.add(fieldgid)
    })
    return field_gids
}

export const changeDefinitionLostDataFields = (
    new_definition,
    old_definition,
    datastore
) => {
    const new_fields_gids = getAllDefinitionFieldGids(new_definition, datastore)
    const old_fields_gids = getAllDefinitionFieldGids(old_definition, datastore)
    const lost_fields_gids = difference(old_fields_gids, new_fields_gids)
    const lost_fields = Array.from(lost_fields_gids).map(lost_field_gid => {
        return datastore.fields.get(lost_field_gid)
    })
    return lost_fields
}

export const fieldtitle = (field, language) => {
    let name = field.label.get(language)
    if (!name || !name.length) {
        name = field.name
    }
    if (field.unit) {
        name += ' [' + field.unit + ']'
    }
    return name
}

export const hasValuesOption = field => {
    return field['options'].get('values') && field['options'].get('values').length
}
