//
// AssetData
//
// A plain AssetData object to hold asset properties. An asset can be a file or a folder.

import { makeObservable, observable, computed, action } from 'mobx'

import { updateInstanceWithData } from './utils'
import { BaseTransform } from '../imageservers/utils'
import { download_url, test_empty } from '../../utils/utils'

export class AssetData {
    gid = null
    pathname = '/'
    is_file = false
    type = null
    childcount = 0
    status = ''
    info = new Map()
    transforms = new Map()
    path = ''
    name = ''
    version = 0
    title = new Map()
    description = new Map()
    updated_on = 0.0

    _modifications = new Set()

    constructor(fielddata, rootstore) {
        makeObservable(this, {
            pathname: observable,
            is_file: observable,
            type: observable,
            childcount: observable,
            status: observable,
            info: observable,
            transforms: observable,
            path: observable,
            name: observable,
            version: observable,
            title: observable,
            description: observable,
            updated_on: observable,

            publicname: computed,
            ext: computed,
            iconname: computed,
            mainTransform: computed,
            update: action.bound,
            updateMainTransform: action.bound,
            setProperty: action.bound,
            commitIfModified: action.bound,

            delete: action.bound,
            undelete: action.bound,
            permanentDelete: action.bound,
        })

        this._rootstore = rootstore
        this.update(fielddata)
    }

    update = fielddata => {
        updateInstanceWithData(this, fielddata, [
            'gid',
            'pathname',
            'is_file',
            'type',
            'childcount',
            'status',
            'info',
            'transforms',
            'path',
            'name',
            'version',
            'title',
            'description',
            'updated_on',
        ])
    }

    get ext() {
        return this.name.split('.').pop()
    }

    get publicname() {
        return this.name && this.name.length
            ? this.name
            : this.pathname === '/'
            ? this._rootstore.app.text('Assets')
            : null
    }

    get iconname() {
        if (!this.is_file) return 'folder'
        if (
            [
                'png',
                'jpg',
                'jpeg',
                'gif',
                'svg',
                'bmp',
                'eps',
                'tif',
                'tiff',
                'ico',
                'avif',
                'webp',
                'jxl',
            ].includes(this.ext)
        )
            return 'image'
        if (['xls', 'xlsx', 'xlsm', 'numbers', 'csv'].includes(this.ext)) return 'table'
        if (['zip', 'bz2', 'gz', 'xz', 'tar', '.7z', '.rar', '.tgz'].includes(this.ext))
            return 'zip'
        if (['mp4', 'avi', 'mov', 'mkv', 'wmv', 'webm', 'm4v'].includes(this.ext))
            return 'movie'
        return 'doc'
    }

    get mainTransform() {
        var result = new BaseTransform()
        const main = this.transforms.get('_main_')
        if (!main) return result
        if (main['resize']) result.setResize(main['resize'])
        if (main['width']) result.setWidth(main['width'])
        if (main['height']) result.setHeight(main['height'])
        if (main['type']) result.setType(main['type'])
        if (main['hotspot']) result.setHotspot(main['hotspot'][0], main['hotspot'][1])
        if (main['crop']) result.setCrop(main['crop'][0], main['crop'][1])
        if (main['padding'])
            result.setPadding(
                main['padding'][0],
                main['padding'][1],
                main['padding'][2],
                main['padding'][3]
            )
        if (main['quality']) result.setQuality(main['quality'])
        if (main['background']) result.setBackground(main['background'])
        return result
    }

    updateMainTransform(transform) {
        // value is supposet
        var main = this.transforms.get('_main_')
        if (
            main &&
            main['hotspot'] === transform.hotspot &&
            main['crop'] === transform.crop
        ) {
            return false
        }
        if (!main) main = {}
        main['hotspot'] = transform.hotspot
        main['crop'] = transform.crop
        var newtransforms = Object.fromEntries(this['transforms'])
        newtransforms = { ...newtransforms, ...{ _main_: main } }
        this.update({ transforms: newtransforms })
        this._modifications.add('transforms')
        return true
    }

    setProperty = (propertyname, value, optional_language) => {
        const previous_value = optional_language
            ? this[propertyname].get(optional_language)
            : this[propertyname]
        if (previous_value === value) return false
        if (test_empty(previous_value) && test_empty(value)) return false

        let fielddata = {}
        if (['name'].includes(propertyname)) {
            fielddata[propertyname] = value
        } else if (['info', 'transforms'].includes(propertyname)) {
            fielddata[propertyname] = Object.fromEntries(this[propertyname])
            fielddata[propertyname] = { ...fielddata[propertyname], ...value }
        } else {
            fielddata[propertyname] = Object.fromEntries(this[propertyname])
            fielddata[propertyname][optional_language] = value
        }
        this.update(fielddata)
        this._modifications.add(propertyname)
        return true
    }

    commitIfModified = () => {
        // send gid + modified fields to server backend
        if (!this._modifications.size) {
            return Promise.resolve(true)
        }

        let updateparams = { asset: this.gid }
        for (const fieldname of this._modifications.keys()) {
            updateparams[fieldname] = this[fieldname]
        }
        // basically fire & forget updates
        return this._rootstore._fetch('/assets/update', updateparams).then(result => {
            this._modifications = new Set()
            if ('name' in updateparams) {
                this._rootstore.view.damtree.refetch()
            }
            return result
        })
        // .catch(error => {})
    }

    download = () => {
        this._rootstore.imageserver.downloadAssetUrl(this).then(public_url => {
            download_url(public_url, this.name)
        })
    }

    delete = () => {
        return this._rootstore
            ._fetch('/assets/delete', {
                asset: this.gid,
            })
            .then(result => {
                this._rootstore.view.damworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    undelete = () => {
        return this._rootstore
            ._fetch('/assets/undelete', {
                asset: this.gid,
            })
            .then(result => {
                this._rootstore.view.damworksheet.refetch()
                return result
            })
        // .catch(error => {})
    }

    permanentDelete = () => {
        this._rootstore.view.dampinboard.remove(this.gid)
        return this._rootstore._fetch('/assets/permanent_delete', {
            asset: this.gid,
        }) // no refetch
        // .catch(error => {})
    }
}
