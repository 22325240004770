//
// PropertyTableClassListField
//
// An implementation for ClassListField.

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { ClasslistFieldRecords } from '../../../../stores/data/ClasslistFieldRecords'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { DragDropClasslistFieldItem } from '../../../../dragdrop/DragDropClasslistFieldItem'

import { VView, LayerView, Layer } from '../../../../appview'
import { IconTile } from '../../../../panels'
import { ClassListFieldMenu } from '../../../../menus'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

import { Text, Icon } from '../../../../components'
import { PropertyTable } from '.'

export const PropertyTableClassListField = observer(
    function PropertyTableClassListField(props) {
        const {
            className,
            record,
            field,
            enabled,
            force_readonly,
            fromClass,
            component,
            renderkey,
            worksheet,
            propertytable,
            visited_gids,
            ...other
        } = props

        const { data, app } = useStore()
        const containerRef = useRef()

        const ClassListFieldContextMenu = useMousePopover(ClassListFieldMenu)

        let classes = 'cc-Field cc-ClassListField ws-propertytable'
        if (!enabled) classes += ' cc-disabled'
        if (className) classes += ' ' + className

        const fieldvalue =
            (record.localized_fields && record.localized_fields.get(field.name)) || []
        const classlistfieldvalue = new ClasslistFieldRecords(record, field, fieldvalue)

        const validation = validateField(fieldvalue, field, record.language)
        if (validation.result === VALIDATION.ERROR) {
            classes += ' validation-error'
        } else if (validation.result === VALIDATION.REPORT) {
            classes += ' validation-report'
        }

        const class_gid = field ? field.options.get('class') : undefined
        const class_ = class_gid ? data.classes.get(class_gid) : undefined

        const [selectedItemIndex, setSelectedItemIndex] = useState(null)
        useClickOutside(containerRef, () => setSelectedItemIndex(null))

        const onAddItemBefore = before_index => {
            classlistfieldvalue.newItemBefore(before_index)
            classlistfieldvalue.commitIfModified()
        }

        const onRemoveItem = index => {
            classlistfieldvalue.removeItem(index)
            classlistfieldvalue.commitIfModified()
        }

        const onRemoveAllItems = () => {
            classlistfieldvalue.removeAllItems()
            classlistfieldvalue.commitIfModified()
        }

        const onClassListFieldAction = action => {
            const actions = {
                add_item: onAddItemBefore,
                remove_item: onRemoveItem,
                remove_all: onRemoveAllItems,
            }
            if (!(action in actions)) {
                console.log(`onClassListFieldAction: unhandled action '${action}'`)
                return
            }
            ClassListFieldContextMenu.hide()
            actions[action](selectedItemIndex)
        }

        const onDrop = (dragitem, item_index, isoverzone) => {
            const other_index = dragitem.id
            let before_index = null
            if (['top', 'left', 'inside'].includes(isoverzone)) {
                before_index = item_index
            } else {
                // before_index is record -after- item_index
                before_index = item_index + 1
            }

            classlistfieldvalue.moveItem(other_index, before_index)
            classlistfieldvalue.commitIfModified()
        }

        const onShowClassListFieldMenu = (e, index) => {
            if (index === null) {
                onAddItemBefore(null)
                return
            }
            setSelectedItemIndex(index)
            ClassListFieldContextMenu.onShow(e)
        }

        let PropertyTables
        if (classlistfieldvalue.records.length) {
            PropertyTables = classlistfieldvalue.records.map(
                (classlistfieldrecord, index) => {
                    if (!classlistfieldrecord) return null

                    const selected = selectedItemIndex === index
                    const classes = selected ? ' cc-selected' : ''
                    const ClassView = class_ ? (
                        <PropertyTable
                            className="cc-block"
                            record={classlistfieldrecord}
                            fields={class_.fields}
                            fromClass={class_.gid}
                            worksheet={worksheet}
                            visited_gids={visited_gids}
                        />
                    ) : (
                        <Text>{app.text('No class assigned.')}</Text>
                    )
                    return (
                        <DragDropClasslistFieldItem
                            className={classes}
                            key={index}
                            field={field}
                            item_index={index}
                            direction="vertical"
                            disabled={!enabled}
                            onDrop={onDrop}
                        >
                            <LayerView
                                className="ws-row"
                                onContextMenu={e => onShowClassListFieldMenu(e, index)}
                            >
                                {ClassView}
                                <Layer
                                    className="classlist-actions"
                                    anchor="top-end"
                                    onClick={e => onShowClassListFieldMenu(e, index)}
                                >
                                    <Icon size="text" name="ellipsis" />
                                </Layer>
                            </LayerView>
                        </DragDropClasslistFieldItem>
                    )
                }
            )
        }

        if (enabled) {
            const AddPropertyTable = (
                <div
                    key="add"
                    className="ws-row"
                    onClick={e => onShowClassListFieldMenu(e, null)}
                >
                    <IconTile
                        width={32}
                        className="ws-propertytable cc-dimmed"
                        anchor="left"
                        icon="plus"
                    />
                </div>
            )

            if (!PropertyTables) {
                PropertyTables = AddPropertyTable
            } else {
                PropertyTables.push(AddPropertyTable)
            }
        }

        return (
            <div>
                <ClassListFieldContextMenu.Panel onAction={onClassListFieldAction} />
                <VView ref={containerRef} className={classes} gap={2} {...other}>
                    {PropertyTables}
                </VView>
                <ValidationMessage validation={validation} />
            </div>
        )
    }
)
