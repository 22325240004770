//
// TilesLayoutBuilder
//
// Based on view info from a layout

import React from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'

import { VView, LazyVScrollTiles, LazyScrollItem } from '../../../../appview'
import { LabeledField } from '../fields/LabeledField'
import { LayoutBuilder } from '..'
import { LAYOUTCOMPONENTTYPECONFIG } from '../../../../stores/data/LAYOUTCOMPONENTTYPECONFIG'
import { useLazyScrollingRecordStore } from '../../../../hooks/useLazyScrollingRecordStore'

export const NavigationTileContext = React.createContext(false)

export const TilesLayoutBuilder = observer(function TilesLayoutBuilder({
    className,
    component,
    components,
    layout,
    record,
    worksheet,
    active_class,
    visited_gids,
    force_readonly,
}) {
    const store = useStore()

    const { children, totalsize, firstindex, onUpdate } = useLazyScrollingRecordStore(
        worksheet.record.gid,
        worksheet.children,
        worksheet.environment,
        0,
        10
    )

    const config = LAYOUTCOMPONENTTYPECONFIG['tiles']

    const name = component.name ? component.name : component.type
    const placeholder = component.placeholder
    const localizedPlaceholder = placeholder && placeholder[store.appLanguage]
    const editable = force_readonly
        ? false
        : 'editable' in component
        ? component.editable
        : config.editable.default
    const navigation =
        'navigation' in component ? component.navigation : config.navigation.default
    const columns = component.columns
        ? parseInt(component.columns)
        : config.columns.default
    const height = component.height ? parseInt(component.height) : config.height.default
    const itemheight = component.itemheight
        ? parseInt(component.itemheight)
        : config.itemheight.default

    let Tiles
    if (children && children.length) {
        const update_visited_gids = [
            component ? component.gid : undefined,
            component ? component.original : undefined,
            // class & layout will be added by LayoutBuilder
        ]
        Tiles = children.map((child, index) => {
            const tileclasses = 'ws-tile status-' + child.status
            const ChildLayout =
                component.content && component.content.length
                    ? component.content.map(childcomponent_gid => {
                          const childcomponent = components.get(childcomponent_gid)
                          if (!childcomponent) return null
                          const childconfig =
                              LAYOUTCOMPONENTTYPECONFIG[childcomponent.type]
                          const childeditable =
                              editable &&
                              ('editable' in childcomponent
                                  ? childcomponent.editable
                                  : childconfig.editable.default)
                          childcomponent.editable = childeditable
                          return (
                              <NavigationTileContext.Provider
                                  key={childcomponent_gid}
                                  value={
                                      (force_readonly || !childeditable) && navigation
                                  }
                              >
                                  <LayoutBuilder
                                      component={childcomponent}
                                      components={components}
                                      layout={layout}
                                      record={child}
                                      worksheet={worksheet}
                                      active_class={active_class}
                                      visited_gids={[
                                          ...visited_gids,
                                          ...update_visited_gids.filter(gid => !!gid),
                                      ]}
                                      force_readonly={force_readonly || !childeditable}
                                  />
                              </NavigationTileContext.Provider>
                          )
                      })
                    : undefined
            return (
                <LazyScrollItem
                    className={tileclasses}
                    key={child.gid}
                    index={index + firstindex}
                >
                    <VView onClick={() => navigation && worksheet.setRecord(child)}>
                        {ChildLayout}
                    </VView>
                </LazyScrollItem>
            )
        })
        Tiles = (
            <LazyVScrollTiles
                className="ws-record-navigator ws-tiles"
                columns={columns}
                firstindex={firstindex}
                itemheight={itemheight}
                calculateitemheight={true}
                gap={15}
                totalitems={totalsize}
                onUpdate={onUpdate}
                style={{ maxHeight: height }}
            >
                {Tiles}
            </LazyVScrollTiles>
        )
    }

    const Placeholder = localizedPlaceholder ? (
        <div className="ws-placeholder">{localizedPlaceholder}</div>
    ) : undefined

    let attributes = {
        className: 'layout-component ws-record-navigator ws-tiles',
    }
    if (className) attributes['className'] += ' ' + className
    if (component.gid === worksheet.layoutstore.selectedComponentGid) {
        attributes['className'] += ' cc-selected'
    }
    // attributes["onClick"] = e => onClickElement(e, component, layouttreestore)
    if (!Tiles) {
        attributes['className'] += ' ws-placeholder-visible'
        Tiles = (
            <div>
                <br />
            </div>
        )
    }

    return (
        <LabeledField
            fieldgid={name}
            editable={editable}
            hide_editable_icon={force_readonly}
            label={name}
            worksheet={worksheet}
            {...attributes}
        >
            {Tiles}
            {Placeholder}
        </LabeledField>
    )
})
