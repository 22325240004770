//
// LayerView, InlineBlockLayerView, Layer
//
// A LayerView (or InlineBlockLayerView) is the basis for layered views. Any direct
// descendant Layer is absolutely positioned with respect to an anchor, and z-order
// follows from element order (first one is at the bottom). The LayerView and
// InlineBlockLayerView take their dimensions from the non-Layer descendents.
//
// A quick example: you can make a button with a badge like so:
//    <InlineBlockLayerView className="button">
//      Show noticifations
//      <Layer className="badge" anchor="top-end" outside>19</Layer>
//    </InlineBlockLayerView>
//
// A Layer accepts an `anchor` property. The predefined values for this property are
// top-start, top, top-end, start, center, end, bottom-start, bottom, bottom-end. The
// start/end naming helps with RTL layout. You can also set any custom value, but
// you'll need to style it yourself, the class name is prepended with `av-anchor-`:
//      <Layer className="badge" anchor="button-badge">19<Layer>
//     .av-Layer.av-anchor-button-badge { top: -3px; right: 3px; bottom: auto; left: auto; }
// A Layer also accepts an `outside` property, which will push the layer outside the
// container, relative to the anchor.

import React from 'react'

export const LayerView = React.forwardRef(function LayerView(props, ref) {
    const { className, ...other } = props
    let classes = 'av-LayerView'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})

export const InlineBlockLayerView = React.forwardRef(function InlineBlockLayerView(
    props,
    ref
) {
    const { className, ...other } = props
    let classes = 'av-LayerView av-InlineBlockLayerView'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})

export const Layer = React.forwardRef(function Layer(props, ref) {
    const { className, anchor, outside, edge, ...other } = props
    let classes = 'av-Layer'
    if (anchor) classes += ' av-anchor-' + anchor
    if (outside) classes += ' av-outside'
    else if (edge) classes += ' av-edge'
    if (className) classes += ' ' + className
    return <div ref={ref} className={classes} {...other} />
})
