//
// Test lab
//
// For test dev

import React from 'react'
import { observer } from 'mobx-react-lite'

import { VView } from '../../appview'

export const Test = observer(function Test() {
    return (
        <VView style={{ padding: 20 }} gap={20}>
            1, 2, 3…
            <br />
        </VView>
    )
})
