//
// TypedField
//
// Return a Field for a field based on type

import React from 'react'
import { observer } from 'mobx-react-lite'

import {
    TextField,
    TextListField,
    KeyValueField,
    KeyValueListField,
    BarcodeField,
    BooleanField,
    AssetField,
    AssetListField,
    RecordField,
    RecordListField,
    FieldpickerField,
    ClassField,
    ClassListField,
} from '../fields'

import { hashData } from '../../../../utils/utils'
import { hasValuesOption } from '../../../../stores/data/utils'

export const TypedField = observer(function TypedField({
    field,
    fromClass,
    editable,
    force_readonly,
    component,
    record,
    worksheet,
    active_class,
    visited_gids,
}) {
    if (!field) {
        return null
    }
    let renderkey = record.gid + '.' + field.gid + '.' + field.type
    if (field.options) {
        renderkey = renderkey + '.' + hashData(field.options)
    }

    switch (field.type) {
        case 'record':
            return (
                <RecordField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'recordlist':
            return (
                <RecordListField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'file':
        case 'image':
            return (
                <AssetField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'filelist':
        case 'imagelist':
            return (
                <AssetListField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'fieldpicker':
            return (
                <FieldpickerField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'class':
            renderkey = renderkey + '.' + record.data_hash
            return (
                <ClassField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'classlist':
            renderkey = renderkey + '.' + record.data_hash
            return (
                <ClassListField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'boolean':
            return (
                <BooleanField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'barcode':
            return (
                <BarcodeField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'textlist':
            if (hasValuesOption(field)) {
                return (
                    <KeyValueListField
                        enabled={editable}
                        record={record}
                        field={field}
                        fromClass={fromClass}
                        component={component}
                        renderkey={renderkey}
                        worksheet={worksheet}
                        force_readonly={force_readonly}
                        visited_gids={visited_gids}
                    />
                )
            }
            return (
                <TextListField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
        case 'text':
        case 'textline':
        case 'number':
        case 'decimal':
        case 'code':
        default:
            if (field.type === 'textline' && hasValuesOption(field)) {
                return (
                    <KeyValueField
                        enabled={editable}
                        record={record}
                        field={field}
                        fromClass={fromClass}
                        component={component}
                        renderkey={renderkey}
                        worksheet={worksheet}
                        force_readonly={force_readonly}
                        visited_gids={visited_gids}
                    />
                )
            }
            return (
                <TextField
                    enabled={editable}
                    record={record}
                    field={field}
                    fromClass={fromClass}
                    component={component}
                    renderkey={renderkey}
                    worksheet={worksheet}
                    force_readonly={force_readonly}
                    visited_gids={visited_gids}
                />
            )
    }
})
