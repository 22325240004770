//
// RecordField
//
// A record-input.

import React, { useState, useEffect, useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../stores'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useMousePopover } from '../../../../hooks/useMousePopover'
import { Transform } from '../../../../stores/imageservers/utils'
import { DragDropLinkedRecord } from '../../../../dragdrop/DragDropLinkedRecord'

import { RecordItem, RecordCard, RecordThumb, IconTile } from '../../../../panels'
import { RecordFieldMenu } from '../../../../menus'
import { NavigationTileContext } from '../layoutbuilders/TilesLayoutBuilder'

import { VALIDATION } from '../../../../utils/validation'
import { validateField } from '../../../../stores/data/validators'
import { ValidationMessage } from '../../../../components'

export const RecordField = observer(function RecordField(props) {
    const {
        className,
        record,
        field,
        enabled,
        force_readonly,
        fromClass,
        component,
        renderkey,
        worksheet,
        visited_gids,
        ...other
    } = props

    const inNavigationTile = useContext(NavigationTileContext)
    const { data, view } = useStore()
    const [containerRef, containerWidth] = useElementWidth(20)

    const RecordFieldContextMenu = useMousePopover(RecordFieldMenu)

    let classes = 'cc-Field cc-RecordField'
    if (!enabled) classes += ' cc-disabled'
    if (className) classes += ' ' + className

    const fieldvalue =
        record.localized_fields && record.localized_fields.get(field.name)

    const validation = validateField(fieldvalue, field, record.language)
    if (validation.result === VALIDATION.ERROR) {
        classes += ' validation-error'
    } else if (validation.result === VALIDATION.REPORT) {
        classes += ' validation-report'
    }

    const componentstyle = component && component.style ? component.style : 'item'

    const [linkedrecord, setLinkedRecord] = useState(null)
    useEffect(() => {
        let isMounted = true
        let controller = new AbortController()
        async function asyncEffect() {
            setLinkedRecord(null)
            if (fieldvalue) {
                if (!data.records.has(fieldvalue)) {
                    await data.fetchRecordByGid(fieldvalue, {
                        signal: controller.signal,
                    })
                }
                const asyncrecord = data.records.get(fieldvalue)
                if (isMounted) {
                    setLinkedRecord(asyncrecord)
                }
            }
        }
        asyncEffect()
        return () => {
            controller.abort('Unmounted')
            isMounted = false
            return isMounted
        }
    }, [fieldvalue, data])

    if (linkedrecord) {
        classes += ' status-' + linkedrecord.status
    }

    const pad = componentstyle === 'card' ? 10 : 0
    const transform = new Transform('fit', containerWidth - pad, containerWidth - pad)

    const onAddRecord = (linkedrecord, otherrecordgid) => {
        record.setField(field, otherrecordgid)
        record.commitIfModified()
    }

    const onReplaceRecord = (linkedrecord, otherrecordgid) => {
        record.setField(field, otherrecordgid)
        record.commitIfModified()
    }

    const onRemoveRecord = linkedrecord => {
        record.setField(field, '')
        record.commitIfModified()
    }

    const onRevealInPim = linkedrecord => {
        view.pimworksheet.setRecord(linkedrecord)
        view.pimtree.datamanager.setRecord(linkedrecord)
        view.setWorkspace('pim')
    }

    const onRecordFieldAction = (action, record, otherrecordgid) => {
        const actions = {
            add_record: onAddRecord,
            replace_record: onReplaceRecord,
            remove_record: onRemoveRecord,
            reveal_in_pim: onRevealInPim,
        }
        if (!(action in actions)) {
            console.log(`onRecordFieldAction: unhandled action '${action}'`)
            return
        }
        RecordFieldContextMenu.hide()
        actions[action](record, otherrecordgid)
    }

    const onShowRecordFieldMenu = e => {
        RecordFieldContextMenu.onShow(e)
    }

    const onClickShowRecordFieldMenu = e => {
        if (enabled || !inNavigationTile) {
            onShowRecordFieldMenu(e)
        }
    }

    const icontileanchor = componentstyle === 'item' ? 'start' : 'center'
    const iconplaceholder = enabled ? 'plus' : 'product'

    const onDrop = (dragitem, linkedrecord, isoverzone) => {
        // onDrop only 'inside'
        record.setField(field, dragitem.id)
        record.commitIfModified()
    }

    return (
        <div ref={containerRef} className={classes} {...other}>
            <RecordFieldContextMenu.Panel
                record={linkedrecord}
                enabled={enabled}
                list={false}
                onAction={onRecordFieldAction}
            />
            <DragDropLinkedRecord
                field={field}
                linkedrecord={linkedrecord}
                disabled={!enabled}
                onDrop={onDrop}
            >
                {!linkedrecord ? (
                    <IconTile
                        width={32}
                        className="cc-dimmed"
                        icon={iconplaceholder}
                        anchor={enabled ? icontileanchor : undefined}
                        onClick={onClickShowRecordFieldMenu}
                    />
                ) : componentstyle === 'card' ? (
                    <RecordCard
                        record={linkedrecord}
                        transform={transform}
                        iconplaceholder={iconplaceholder}
                        onClick={onClickShowRecordFieldMenu}
                        onContextMenu={onShowRecordFieldMenu}
                    />
                ) : componentstyle === 'thumb' ? (
                    <RecordThumb
                        record={linkedrecord}
                        transform={transform}
                        iconplaceholder={iconplaceholder}
                        onClick={onClickShowRecordFieldMenu}
                        onContextMenu={onShowRecordFieldMenu}
                    />
                ) : (
                    <RecordItem
                        record={linkedrecord}
                        iconplaceholder={iconplaceholder}
                        onClick={onClickShowRecordFieldMenu}
                        onContextMenu={onShowRecordFieldMenu}
                    />
                )}
            </DragDropLinkedRecord>
            <ValidationMessage validation={validation} />
        </div>
    )
})
