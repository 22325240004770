//
// PropertyTable
//
// A table of text-inputs, in minimal form. 3 columns: field label, field value, field
// unit.
// Adds drag-and-drop for rows.

import React from 'react'
import { observer } from 'mobx-react-lite'

import {
    TextField,
    TextListField,
    KeyValueField,
    KeyValueListField,
    BarcodeField,
    BooleanField,
    AssetField,
    AssetListField,
    RecordField,
    RecordListField,
    FieldpickerField,
    ClassField,
    ClassListField,
} from '.'

import { hashData } from '../../../../utils/utils'
import { hasValuesOption } from '../../../../stores/data/utils'

const PropertyRow = observer(function PropertyRow({
    record,
    field,
    class_,
    worksheet,
    selectedfieldgid,
    onClickField,
    onContextMenuField,
    visited_gids,
}) {
    if (!field) {
        return null
    }

    const language = worksheet.environment.get('language')
    let rowclasses = 'ws-propertytable-row'
    if (selectedfieldgid === field.gid) {
        rowclasses += ' cc-selected'
    }
    let fieldname = field.label.get(language)
    if (!fieldname || !fieldname.length) fieldname = field.name

    let renderkey = record.gid + '.' + field.gid + '.' + field.type
    if (field.options) {
        renderkey = renderkey + '.' + hashData(field.options)
    }

    let PropertyField
    switch (field.type) {
        case 'image':
        case 'file':
            PropertyField = (
                <AssetField
                    className="ws-tile"
                    enabled={true}
                    field={field}
                    component={{ style: 'item' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'imagelist':
        case 'filelist':
            PropertyField = (
                <AssetListField
                    enabled={true}
                    field={field}
                    component={{ style: 'item' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'record':
            PropertyField = (
                <RecordField
                    className="ws-tile"
                    enabled={true}
                    field={field}
                    component={{ style: 'item' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'recordlist':
            PropertyField = (
                <RecordListField
                    enabled={true}
                    field={field}
                    component={{ style: 'item' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'boolean':
            PropertyField = (
                <BooleanField
                    enabled={true}
                    field={field}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'barcode':
            PropertyField = (
                <BarcodeField
                    enabled={true}
                    field={field}
                    component={{ style: 'plaintext' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'textlist':
            if (hasValuesOption(field)) {
                PropertyField = (
                    <KeyValueListField
                        enabled={true}
                        field={field}
                        record={record}
                        worksheet={worksheet}
                        renderkey={renderkey}
                        visited_gids={visited_gids}
                    />
                )
            } else {
                PropertyField = (
                    <TextListField
                        enabled={true}
                        field={field}
                        record={record}
                        worksheet={worksheet}
                        renderkey={renderkey}
                        visited_gids={visited_gids}
                    />
                )
            }
            break
        case 'fieldpicker':
            PropertyField = (
                <FieldpickerField
                    enabled={true}
                    field={field}
                    component={{ style: 'item' }}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'class':
            renderkey = renderkey + '.' + record.data_hash
            PropertyField = (
                <ClassField
                    enabled={true}
                    field={field}
                    record={record}
                    worksheet={worksheet}
                    propertytable
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'classlist':
            renderkey = renderkey + '.' + record.data_hash
            PropertyField = (
                <ClassListField
                    enabled={true}
                    field={field}
                    record={record}
                    worksheet={worksheet}
                    propertytable
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
            break
        case 'textline':
            if (hasValuesOption(field)) {
                PropertyField = (
                    <KeyValueField
                        enabled={true}
                        field={field}
                        record={record}
                        worksheet={worksheet}
                        renderkey={renderkey}
                        visited_gids={visited_gids}
                    />
                )
            } else {
                PropertyField = (
                    <TextField
                        enabled={true}
                        field={field}
                        record={record}
                        worksheet={worksheet}
                        renderkey={renderkey}
                        visited_gids={visited_gids}
                    />
                )
            }
            break
        default:
            PropertyField = (
                <TextField
                    enabled={true}
                    field={field}
                    record={record}
                    worksheet={worksheet}
                    renderkey={renderkey}
                    visited_gids={visited_gids}
                />
            )
    }

    const no_unit = field.unit === null || field.unit.length === 0
    const FieldRow = (
        <>
            <div className="ws-propertytable-cell ws-propertytable-cell-label">
                {fieldname}
                {no_unit ? undefined : <div>[{field.unit}]</div>}
            </div>
            <div
                className="ws-propertytable-cell ws-propertytable-cell-input"
                onContextMenu={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation()
                }}
            >
                {PropertyField}
            </div>
        </>
    )

    return (
        <div
            className={rowclasses}
            key={field.gid}
            onClick={() => onClickField && onClickField(field.gid)}
            onContextMenu={e => onContextMenuField && onContextMenuField(e, field.gid)}
        >
            {FieldRow}
        </div>
    )
})

export const PropertyTable = observer(function PropertyTable(props) {
    const {
        className,
        record,
        fields,
        fromClass,
        selectedfieldgid,
        onClickField,
        onContextMenuField,
        worksheet,
        visited_gids,
    } = props

    const class_ = fromClass ? worksheet.classes.get(fromClass) : null

    const _onClickField = fieldgid => {
        onClickField && onClickField(fieldgid)
    }

    const _onContextMenuField = (e, fieldgid) => {
        onContextMenuField && onContextMenuField(e, fieldgid)
    }

    let Table
    if (fields && fields.length) {
        const Rows = fields.map(fieldgid => {
            const field = worksheet.fields.get(fieldgid)
            return (
                <PropertyRow
                    key={fieldgid}
                    record={record}
                    field={field}
                    class_={class_}
                    worksheet={worksheet}
                    selectedfieldgid={selectedfieldgid}
                    onClickField={() => _onClickField(fieldgid)}
                    onContextMenuField={e => _onContextMenuField(e, fieldgid)}
                    visited_gids={visited_gids}
                />
            )
        })
        let classes = 'ws-propertytable'
        if (className) classes += ' ' + className
        Table = <div className={classes}>{Rows}</div>
    }

    return Table
})
