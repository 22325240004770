//
// KeyValueListInput
//
// A list of KeyValueInputs.
//
// KeyValue data looks like this, with keys and localized labels
//
// [
//     { key: 'left', en: 'Left' nl: 'Links' },
//     { key: 'center', en: 'Centered' nl: 'Gecentreerd' },
//     { key: 'right', en: 'Right' nl: 'Rechts' },
// ]

import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../stores'
import { useMousePopover } from '../hooks/useMousePopover'
import { useClickOutside } from '../hooks/useClickOutside'
import { DragDropListRow } from '../dragdrop/DragDropListRow'

import { TextListMenu } from '../menus'

import { VALIDATION } from '../utils/validation'
import { ValidationMessage } from './ValidationMessage'
import {
    addIndexedListValue,
    replaceIndexedListValue,
    moveIndexedListValue,
    removeIndexedListValue,
} from '../utils/list'
import { keyboard } from '../utils/keyboard'
import { test_empty } from '../utils/utils'

import { Icon, KeyValueInput } from '../components'

export const KeyValueListInput = observer(function KeyValueListInput({
    className,
    enabled,
    header,
    listvalue,
    values,
    language,
    hasLabels,
    restrictToValues,
    renderkey,
    validateLine,
    validation,
    onChangeKeys,
    onBlurKeys,
    ...other
}) {
    const { app } = useStore()
    const containerRef = useRef()

    if (enabled === undefined) enabled = true

    // used in setKeyFromValue via onChange and onBlur
    var valuesByLabel = {}
    for (const entry of values) {
        if (test_empty(entry[language])) {
            valuesByLabel[entry['key']] = entry['key']
        } else {
            valuesByLabel[entry[language]] = entry['key']
        }
    }
    const hasLabelsRef = useRef()
    hasLabelsRef.current = hasLabels
    const valuesByLabelRef = useRef()
    valuesByLabelRef.current = valuesByLabel

    const getKeyFromValue = value => {
        return hasLabelsRef.current
            ? value in valuesByLabelRef.current
                ? valuesByLabelRef.current[value]
                : value
            : value
    }

    const listvalueRef = useRef()
    listvalueRef.current = listvalue // used for changes to the listvalue

    const TextListContextMenu = useMousePopover(TextListMenu)
    const [focusItem, setFocusItem] = useState([null, 'end'])

    let classes = 'cc-Field cc-TextListInput cc-KeyValueListInput ws-table'
    if (className) classes += ' ' + className

    if (validation) {
        if (validation.result === VALIDATION.ERROR) {
            classes += ' validation-error'
        } else if (validation.result === VALIDATION.REPORT) {
            classes += ' validation-report'
        }
    }

    const [selectedItemIndex, setSelectedItemIndex] = useState(null) // an index
    useClickOutside(containerRef, () => setSelectedItemIndex(null))

    const _onAddItemBefore = before_index => {
        const newValue = addIndexedListValue(listvalueRef.current, '', before_index)
        onBlurKeys && onBlurKeys(newValue)
        if (before_index === null) {
            setFocusItem([newValue.length - 1, 'start'])
        } else {
            setFocusItem([before_index, 'start'])
        }
    }

    const _onRemoveItem = index => {
        const newValue = removeIndexedListValue(listvalueRef.current, index)
        onBlurKeys && onBlurKeys(newValue)
        setFocusItem([index - 1, 'end'])
    }

    const _onRemoveAllItems = () => {
        const newValue = []
        onBlurKeys && onBlurKeys(newValue)
        setFocusItem([null, 'end'])
    }

    const onTextListAction = action => {
        const actions = {
            add_item: _onAddItemBefore,
            remove_item: _onRemoveItem,
            remove_all: _onRemoveAllItems,
        }
        if (!(action in actions)) {
            console.log(`onTextListAction: unhandled action '${action}'`)
            return
        }
        TextListContextMenu.hide()
        actions[action](selectedItemIndex)
    }

    const onDrop = (dragitem, item_index, isoverzone) => {
        const other_index = dragitem.id
        let before_index = null
        if (['top', 'left', 'inside'].includes(isoverzone)) {
            before_index = item_index
        } else {
            // before_index is record -after- item_index
            before_index = item_index + 1
        }
        const newValue = moveIndexedListValue(
            listvalueRef.current,
            other_index,
            before_index
        )
        onBlurKeys && onBlurKeys(newValue)
        setFocusItem([null, 'end'])
    }

    const _onUpdateItemAddAfter = (index, newItemValue) => {
        const newValue = addIndexedListValue(
            replaceIndexedListValue(listvalueRef.current, newItemValue, index),
            '',
            index + 1
        )
        onBlurKeys && onBlurKeys(newValue)
        setFocusItem([index + 1, 'start'])
    }

    const _onChangeItem = (index, newItemValue) => {
        const newValue = replaceIndexedListValue(
            listvalueRef.current,
            newItemValue,
            index
        )
        onChangeKeys && onChangeKeys(newValue)
    }

    const _onBlurItem = (index, newItemValue) => {
        if (!onBlurKeys) {
            return
        }
        const lines = newItemValue.trim('\n').split('\n')
        const thisline = lines.shift()
        const thiskey = getKeyFromValue(thisline)
        let newListValue = replaceIndexedListValue(listvalueRef.current, thiskey, index)
        for (const line of lines) {
            index += 1
            const key = getKeyFromValue(line)
            newListValue = addIndexedListValue(newListValue, key, index)
        }
        if (lines.length > 1) {
            setFocusItem([index, 'end'])
        }
        onBlurKeys(newListValue)
    }

    const onShowTextListMenu = (e, index) => {
        if (index === null) {
            _onAddItemBefore(null)
            return
        }
        setSelectedItemIndex(index)
        TextListContextMenu.onShow(e)
    }

    const TableHeader = header ? (
        <tr>
            <td key={'action'} className="ws-table-cell ws-table-cell-_action_"></td>
            <td key={'translation'} className="ws-table-cell ws-table-cell-translation">
                {header}
            </td>
            <td key={'handle'} className="ws-table-cell ws-table-cell-_action_"></td>
        </tr>
    ) : undefined

    let TableRows
    if (listvalue && Array.isArray(listvalue) && listvalue.length) {
        TableRows = listvalue.map((line, index) => {
            let cellclasses = 'ws-table-cell ws-table-cell-text'
            const _onChangeKey = newKey => {
                _onChangeItem(index, newKey)
            }
            const _onBlurKey = newKey => {
                _onBlurItem(index, newKey)
            }
            const _onKeyDown = (event, newKey) => {
                if (keyboard.test(event, keyboard.ENTER)) {
                    _onUpdateItemAddAfter(index, newKey)
                } else if (
                    (!newKey.length || newKey === '\n') &&
                    keyboard.test(event, keyboard.BACKSPACE)
                ) {
                    _onRemoveItem(index)
                }
            }
            const inputrenderkey = renderkey + index.toString()

            let Input = (
                <KeyValueInput
                    enabled={enabled}
                    setFocus={index === focusItem[0] ? focusItem[1] : false}
                    value={line}
                    values={values}
                    language={language}
                    hasLabels={hasLabels}
                    restrictToValues={restrictToValues}
                    onChangeKey={_onChangeKey}
                    onBlurKey={_onBlurKey}
                    onKeyDown={_onKeyDown}
                    renderkey={inputrenderkey}
                    validate={validateLine}
                    {...other}
                />
            )

            return (
                <DragDropListRow
                    key={'textlist.' + listvalue.length + '.' + index.toString()}
                    className={classes}
                    list_key={renderkey}
                    item_index={index}
                    direction="vertical"
                    disabled={!enabled}
                    onDrop={onDrop}
                >
                    <td
                        key={'action'}
                        className="ws-table-cell ws-table-cell-_action_"
                        onClick={e => onShowTextListMenu(e, index)}
                        onContextMenu={e => onShowTextListMenu(e, index)}
                    >
                        <Icon size="text" name="ellipsis" />
                    </td>
                    <td className={cellclasses}>{Input}</td>
                    <td key={'handle'} className="ws-table-cell ws-table-cell-_action_">
                        <Icon size={1} name="reorder" />
                    </td>
                </DragDropListRow>
            )
        })
    }

    if (enabled) {
        let classes = 'ws-table-cell cc-dimmed'
        const AddTableRow = (
            <tr key={'textlist.add'}>
                <td className={classes + ' ws-table-cell-_action_'}>
                    <Icon size={1} name="plus" onClick={e => _onAddItemBefore(null)} />
                </td>
                <td
                    colSpan={2}
                    className={classes + ' ws-table-cell-_add-text_'}
                    style={{ textAlign: 'left' }}
                >
                    <div onClick={e => _onAddItemBefore(null)}>
                        {app.text('Add new item')}
                    </div>
                </td>
            </tr>
        )

        if (!TableRows) {
            TableRows = AddTableRow
        } else {
            TableRows.push(AddTableRow)
        }
    }

    return (
        <div>
            <TextListContextMenu.Panel onAction={onTextListAction} />
            <div className={classes} {...other}>
                <table className="ws-table">
                    <thead>{TableHeader}</thead>
                    <tbody ref={containerRef}>{TableRows}</tbody>
                </table>
            </div>
            <ValidationMessage validation={validation} />
        </div>
    )
})
